import _ from 'lodash'
import amplitude from 'amplitude-js'
import * as ENVIRONMENT from '../environment'
import { AMPLITUDE_PROXY } from '../constants'
import posthog from 'posthog-js'
import { EVENT_EXTRA, PostHogMappings } from '../eventTracking/amplitudeEvents'

export const simulateLinkClick = (url, targetBlank = false) => {
  if (!url) {
    return
  }
  // Check if it's a valid URL
  try {
    new URL(url)
  } catch {
    return
  }
  const a = window.document.createElement('a')
  a.href = url
  if (targetBlank) {
    window.open(url, '_blank', 'noopener,noreferrer')
  } else {
    window.location.href = url
  }
}

export function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const amplitudeLogger = amplitude.getInstance()

setTimeout(() => {
  //so that ENVIRONMENT and constants are ready
  const amplitudeKey = ENVIRONMENT.DEVELOPMENT ? '73c0dcd23a65569e0d5e6521e9608c4a' : 'adde16bd2ea923a7f87d614e00ee4741'
  posthog.init('phc_ATYsEdRZaYMwgaUD7RLNBq1dRUIGlC2fVQpCgv7hbLX', {
    api_host: '/m3trics2',
    person_profiles: 'identified_only',
    autocapture: false,
    capture_heatmaps: false,
    capture_pageview: false,
    capture_pageleave: false,
  })
  amplitudeLogger.init(amplitudeKey, void 0, {
    platform: ENVIRONMENT.NAME,
    apiEndpoint: ENVIRONMENT.USE_AMPLITUDE_PROXY ? AMPLITUDE_PROXY : undefined,
  })

  amplitudeLogger.setVersionName(ENVIRONMENT.VERSION)

  // Generate a random device ID because Teams is updating it for each reload
  try {
    let randomDeviceId = localStorage.getItem('randomDeviceId')
    if (!randomDeviceId) {
      randomDeviceId = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
      localStorage.setItem('randomDeviceId', randomDeviceId)
    }
    amplitudeLogger.setDeviceId(randomDeviceId)
  } catch (e) {
    console.log(e)
  }
})

export const trackEvent = (event, eventProperties = {}) => {
  amplitudeLogger.logEvent(event, eventProperties)
  const mappedEvent = PostHogMappings[event] ?? event
  if (!eventProperties[EVENT_EXTRA.INTERACTION_TYPE.KEY]) {
    eventProperties[EVENT_EXTRA.INTERACTION_TYPE.KEY] = EVENT_EXTRA.INTERACTION_TYPE.VALUE.ACTIVE
  }
  posthog.capture(mappedEvent, eventProperties)
}

export const setTrackedUser = email => {
  amplitudeLogger.setUserId(email) //todo set just if necessary + extra info
}

export const setTrackedUserProperties = userProperties => {
  const identify = new amplitude.Identify()
  _.forEach(userProperties, (value, key) => identify.set(key, value))
  amplitudeLogger.identify(identify)
}

export const shallowCompareArrays = (a, b) => _.every(a, (v, k) => v === b[k])

export const generateActionCreator =
  (fn, dispatch) =>
  (...args) =>
    dispatch(fn(...args))

export { optionalPath, getMatchParameter, getMatchParameterInteger } from './path'

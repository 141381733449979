import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import * as styleHelper from '../../helpers/styleHelper'
import { DefaultButton } from '@fluentui/react/lib/Button'
import { ShortcutTooltipHost } from '../../tooltip/ShortcutTooltipHost'
import { DirectionalHint } from '@fluentui/react/lib/Callout'
import { LockClosedFilled } from '@fluentui/react-icons'

const Container = styled(DefaultButton).attrs({
  styles: {
    flexContainer: {
      width: '100%',
    },
    menuIcon: {
      display: 'none !important',
    },
  },
})`
  // Use !important to fix Internet Explorer
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
  height: auto;
  width: 100%;
  background-color: transparent !important;
  padding: 0;
  margin: 0;
  border: 0 !important;
  color: ${p => p.theme.palette.white}!important;

  &:hover {
    background: inherit !important;
    color: inherit !important;
  }
  @media (hover: hover) and (pointer: fine) {
    &.showHover {
      &:hover {
        background: ${p => p.theme.palette.highlight}!important;
      }
    }
  }

  &.selected {
    background: ${p => p.theme.palette.lightHighlight}!important;
  }

  ${styleHelper.defaultMediaQueries.smallDevices`
    justify-content: center;
  `};
`

const Tooltip = styled(ShortcutTooltipHost).attrs(p => ({
  styles: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: p.padding ?? '12px 8px',
      width: '100%',
    },
  },
}))`
  // className does not work for TooltipHost
`

export const LeftPanelSubcomponent = ({
  tooltip,
  locked = false,
  tooltipPadding,
  children,
  selected = false,
  showHover = true,
  className,
  tooltipKeys,
  ...rest
}) => {
  const containerClass = cn(className, { selected, showHover })
  return (
    <Container className={containerClass} {...rest}>
      {locked && <LockClosedFilled className="absolute left-1 top-1 z-10 text-lg text-gray-800" />}
      <Tooltip
        content={tooltip}
        directionalHint={DirectionalHint.rightCenter}
        padding={tooltipPadding}
        tooltipKeys={tooltipKeys}
      >
        {children}
      </Tooltip>
    </Container>
  )
}

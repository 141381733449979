import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { getAllNotifications } from '../common/src/actions/notificationsAPI'
import { notificationHelper } from '../common/src/helpers'

export const useUnreadNotifications = () => {
  const dispatch = useDispatch()
  useQuery({
    queryKey: ['notifications', 'all'],
    queryFn: () => {
      return dispatch(getAllNotifications({ state: notificationHelper.NOTIFICATION_STATE.UNREAD }))
    },
    staleTime: 10 * 1000, // 10 seconds
    refetchInterval: 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: true,
    refetchOnMount: false,
  })
}

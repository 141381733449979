import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import _ from 'lodash'
import { Suspense, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@fluentui/react-components'
import { OutlookLogoIcon } from '@fluentui/react-icons-mdl2-branded'
import { graphEventHelper, graphStateHelper, itemHelper, userHelper } from '../../common/src/helpers'
import { useSelection } from '../../hooks/PMHooks'
import { GhostItemCell } from '../../components/itemCell/GhostItemCell'
import { Loading } from '../../components/basic/Loading'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { useMe } from '../../common/src/hooks/usersHooks'
import { SECTIONS, usePlaceholders } from './HomeConstants'
import { getCalendarViewEventsInRange } from '../../common/src/actions/graphAPI'
import { getRenderList } from './HomeAgendaLogic'
import { StaticItemList } from '../../components/list/staticItemList/StaticItemList'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { linkSubject } from '../../reactions/linkSubject'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { useTranslation } from 'react-i18next'
import { SERVER_URLS } from '../../common/src/constants'
import { useRowRenderer } from './commonRowrenderer'
import { datefns } from '../../utils/datefns'
import { useAgendaItems } from '@/queries/agenda'

const suggestDate = _.flow([datefns.add({ days: 1 }), datefns.set({ hours: 12, minutes: 0, seconds: 0 })])

const useGraphEventsInInterval = interval => {
  const all = useSelector(graphStateHelper.getAll)
  const inInterval = useMemo(() => _.partialRight(graphEventHelper.occursInInterval, interval), [interval])
  return useMemo(
    () =>
      all.filter(gr => {
        return graphEventHelper.isEvent(gr) && inInterval(gr)
      }),
    [all, inInterval]
  )
}

const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const HomeAgenda = ({ onSelectItem, mode, onSelectGraphResource }) => {
  const dispatch = useDispatch()
  const me = useMe()
  const email = userHelper.getEmail(me)
  const { t } = useTranslation()
  const { items, queryResult } = useAgendaItems(mode)
  const { isPlaceholderData: loading } = queryResult
  const { interval, now } = queryResult.data
  const selection = useSelection()
  const isGraphEnabled = useSelector(graphStateHelper.isGraphEnabled)
  const createItemModal = useCreateItemModal()
  const placeholders = usePlaceholders()

  useEffect(() => {
    if (!email) {
      return
    }
    dispatch(
      getCalendarViewEventsInRange({
        start: datefns.add({ days: -1 })(now),
        end: datefns.add({ days: 7 })(now),
      })
    )
  }, [email, now, dispatch])

  const graphEvents = useGraphEventsInInterval(interval)
  const elements = getRenderList({
    items,
    graphEvents,
    now,
  })

  const rowRenderer = useRowRenderer({
    onSelectItem,
    onSelectGraphResource,
    now,
    selection,
    allItemsInList: items,
  })
  const placeholder = useRandomValueFromArray(placeholders)
  const noRowsRenderer = useCallback(() => {
    const onClick = () => {
      const date = suggestDate(new Date())
      createItemModal({
        mode: EVENT_EXTRA.CREATE_ITEM.MODE.HOME_AGENDA,
        displayProjectSelector: true,
        dueDate: date,
        open: false,
        completion: i => {
          linkSubject.next({
            urlData: itemHelper.getUriData(i),
            section: SECTIONS.AGENDA,
          })
        },
      })
    }
    const outlookButtonClassname = cn({ pm_hidden: isGraphEnabled })
    const connectCalendarHref = `${SERVER_URLS.GRAPH_PERMISSIONS}?next=${window.location.pathname}`

    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder}>
          <ButtonsColumn>
            <Button appearance="primary" onClick={onClick}>
              {t('item.create_item')}
            </Button>
            <Button
              href={connectCalendarHref}
              as="a"
              target="_blank"
              icon={<OutlookLogoIcon />}
              className={outlookButtonClassname}
            >
              {t('office.connect_outlook_calendar')}
            </Button>
          </ButtonsColumn>
        </LazyIViewPlaceholder>
      </Suspense>
    )
  }, [isGraphEnabled, placeholder, t, createItemModal])

  return (
    <StaticItemList
      rows={elements}
      rowRenderer={rowRenderer}
      noRowsRenderer={noRowsRenderer}
      loading={loading}
      shimmerComponent={GhostItemCell}
    />
  )
}

import { SERVER_URLS, isEmbeddedPlatform } from '../common/src/constants'
import { openExternalLink } from '../utils/externalLinkHandler'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { isEmbeddedOnTeams } from '../helpers/queryParamsHelper'
import { useDispatch, useSelector } from 'react-redux'
import { openPurchaseExperienceAction } from '../utils/teams'
import { EVENT_EXTRA } from '../common/src/eventTracking/amplitudeEvents'
import { isElectronApp } from '../integrations/electron'
import { uiSelectors } from '../selectors'
import { useConfig } from '../queries/config'
import { stateHelper } from '@/common/src/helpers'
import { openDevPanel } from '@/utils/ExpoCommunication'
import { queryParamsHelper } from '@/helpers'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

const onClickFactory = href => () => {
  if (isEmbeddedOnTeams() || isElectronApp()) {
    const url = new URL(href)
    url.searchParams.append('embedded', '1')
    href = url.toString()
  }
  openExternalLink(href)
}

export const MODE = {
  LINK: 1,
  CONTEXT: 2,
}

const subMenuMapper = (arr, mode) => {
  if (mode === MODE.CONTEXT) {
    return {
      subMenuProps: {
        items: arr,
      },
    }
  }
  return {
    links: arr,
  }
}

const ARROW = '\u{279A}'
const chooseIcon = iconName => iconName

const appendArrowInTeams = text => {
  const isTeams = isEmbeddedOnTeams()
  if (isTeams) {
    return text + ' ' + ARROW
  }
  return text
}

export const useAccountOptions = mode => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const taskConnectorsEnabled = useConfig().data?.task_connectors ?? true
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()
  const isAppfluenceUser = useSelector(stateHelper.isAppfluenceCurrentUser)
  const isExpo = queryParamsHelper.isEmbeddedOnMobile()
  const isIos = queryParamsHelper.isEmbeddedOnIos()
  const hideUpgradeButton = !shouldDisplayUpgrade
  const platform = useSelector(uiSelectors.getPMPlatform)
  const isEmbeddedExperience = isEmbeddedPlatform(platform)
  const showDevPanelOption = isAppfluenceUser && isExpo
  return useMemo(
    () =>
      [
        {
          key: 'upgrade',
          name: appendArrowInTeams(t('left_panel.upgrade_name')),
          onClick: () =>
            dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.LEFT_PANEL_ACCOUNT)),
          iconProps: { iconName: chooseIcon('Ribbon') },
          hidden: hideUpgradeButton,
        },
        {
          key: 'manage_account',
          name: appendArrowInTeams(t('left_panel.account.manage_account')),
          onClick: onClickFactory(SERVER_URLS.MANAGE_ACCOUNT),
          ...(mode === MODE.CONTEXT && {
            iconProps: { iconName: chooseIcon('Settings') },
          }),
          split: true,
          ...subMenuMapper(
            [
              {
                key: 'personal_info',
                name: appendArrowInTeams(t('left_panel.account.personal_info')),
                onClick: onClickFactory(SERVER_URLS.PERSONAL_INFO),
                iconProps: { iconName: chooseIcon('ContactCardSettings') },
              },
              {
                key: 'change_avatar',
                name: appendArrowInTeams(t('left_panel.account.change_avatar')),
                onClick: onClickFactory(SERVER_URLS.CHANGE_AVATAR),
                iconProps: { iconName: chooseIcon('EditContact') },
              },
              {
                key: 'user_groups',
                name: appendArrowInTeams(t('left_panel.account.user_groups')),
                onClick: onClickFactory(SERVER_URLS.USER_GROUPS),
                iconProps: { iconName: chooseIcon('People') },
              },
              {
                key: 'promote_connectors',
                name: appendArrowInTeams(t('left_panel.account.task_connectors')),
                onClick: onClickFactory(SERVER_URLS.PROMOTE_CONNECTORS),
                iconProps: { iconName: chooseIcon('PlugConnected') },
                hidden: !taskConnectorsEnabled,
              },
              {
                key: 'setup_links',
                name: appendArrowInTeams(t('left_panel.account.setup_links')),
                onClick: onClickFactory(SERVER_URLS.SETUP_LINKS),
                iconProps: { iconName: chooseIcon('Link') },
              },
              {
                key: 'email_preferences',
                name: appendArrowInTeams(t('left_panel.account.email_preferences')),
                onClick: onClickFactory(SERVER_URLS.EMAIL_PREFERENCES),
                iconProps: { iconName: chooseIcon('MailOptions') },
              },
              {
                key: 'delete_account',
                name: appendArrowInTeams(t('left_panel.account.delete_account')),
                onClick: onClickFactory(SERVER_URLS.DELETE_ACCOUNT),
                iconProps: { iconName: chooseIcon('UserRemove') },
                hidden: !isIos,
              },
            ].filter(c => !c.hidden),
            mode
          ),
        },
        {
          key: 'download_apps',
          name: appendArrowInTeams(t('left_panel.account.download_apps')),
          onClick: onClickFactory(SERVER_URLS.MANAGE_DOWNLOADS),
          iconProps: { iconName: chooseIcon('Download') },
          hidden: isEmbeddedExperience,
        },
        {
          key: 'open_dev_panel',
          name: appendArrowInTeams(t('left_panel.account.open_dev_panel')),
          onClick: () => openDevPanel(),
          iconProps: { iconName: chooseIcon('ConstructionCone') },
          hidden: !showDevPanelOption,
        },
        {
          key: 'contact_us',
          name: appendArrowInTeams(t('left_panel.account.contact_us')),
          onClick: onClickFactory(SERVER_URLS.CONTACT_US),
          iconProps: { iconName: chooseIcon('ChatInviteFriend') },
        },
        {
          key: 'help',
          name: appendArrowInTeams(t('left_panel.account.help')),
          onClick: onClickFactory('https://appfluence.com/help/'),
          iconProps: { iconName: chooseIcon('Help') },
        },
      ].filter(c => !c.hidden),
    [t, isIos, hideUpgradeButton, mode, taskConnectorsEnabled, isEmbeddedExperience, dispatch, showDevPanelOption]
  )
}

export const useLanguageOption = mode => {
  const { t, i18n } = useTranslation()
  return useMemo(
    () => ({
      key: 'language',
      name: t('general.language'),
      ...(mode === MODE.CONTEXT && {
        iconProps: { iconName: 'LocaleLanguage' },
      }),
      ...subMenuMapper(
        [
          {
            key: 'language.en',
            name: t('static.english'),
            onClick: () => {
              i18n.changeLanguage('en')
            },
          },
          {
            key: 'language.es',
            name: t('static.spanish'),
            onClick: () => {
              i18n.changeLanguage('es')
            },
          },
          {
            key: 'language.it',
            name: t('static.italian'),
            onClick: () => {
              i18n.changeLanguage('it')
            },
          },
          {
            key: 'language.de',
            name: t('static.german'),
            onClick: () => {
              i18n.changeLanguage('de')
            },
          },
          {
            key: 'language.pt',
            name: t('static.portuguese'),
            onClick: () => {
              i18n.changeLanguage('pt')
            },
          },
          {
            key: 'language.fr',
            name: t('static.french'),
            onClick: () => {
              i18n.changeLanguage('fr')
            },
          },
          {
            key: 'language.zh',
            name: t('static.chinese'),
            onClick: () => {
              i18n.changeLanguage('zh')
            },
          },
          {
            key: 'language.ja',
            name: t('static.japanese'),
            onClick: () => {
              i18n.changeLanguage('ja')
            },
          },
        ],
        mode
      ),
    }),
    [i18n, mode, t]
  )
}

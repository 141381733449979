import _ from 'lodash'
import { useEffect, useRef } from 'react'
import { Editor, Path, Point, Range, Transforms } from 'slate'
import { CheckListItemKeys, ElementTypes, ListByListItem, ListItemByList, ShortCuts } from './NotesEditorTypes'
import { useSelector } from 'react-redux'
import { didToggleItemInMeeting } from '../../selectors/uiSelectors'
import { useToastController } from '@fluentui/react-components'
import { FluentToast } from '../toast/FluentToast'

export const emptyValue = [{ type: ElementTypes.paragraph, children: [{ text: '' }] }]

const typeIsList = type => !!ListItemByList[type]

export const toggleElementType = (editor, type, unsetProps = []) => {
  const isActive = isElementTypeActive(editor, type)
  const isList = typeIsList(type)

  Transforms.unwrapNodes(editor, {
    match: n => typeIsList(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? ElementTypes.paragraph : isList ? ListItemByList[type] : type,
  })
  Transforms.unsetNodes(editor, unsetProps)

  if (!isActive && isList) {
    const match = Editor.above(editor, { match: n => Editor.isBlock(editor, n) })
    if (!match) {
      return
    }
    const [, path] = match
    wrapItemInList(editor, type, path)
  }
}

export const toggleLeafMark = (editor, type) => {
  const isActive = isLeafMarkActive(editor, type)

  if (isActive) {
    Editor.removeMark(editor, type)
  } else {
    Editor.addMark(editor, type, true)
  }
}

export const isElementTypeActive = (editor, type) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === type,
  })
  return !!match
}

export const isLeafMarkActive = (editor, type) => {
  const marks = Editor.marks(editor)
  return marks ? marks[type] === true : false
}

const checkListItemUnsetProps = [CheckListItemKeys.id, CheckListItemKeys.url, CheckListItemKeys.user]
export const withShortcuts = editor => {
  const { deleteBackward, insertText, insertBreak } = editor

  editor.insertBreak = () => {
    const { selection } = editor

    if (!selection || !Range.isCollapsed(selection)) {
      insertBreak()
      return
    }

    const match = Editor.above(editor, {
      match: n => Editor.isBlock(editor, n),
    })
    if (!match) {
      insertBreak()
      return
    }
    const [node] = match

    const listType = ListByListItem[node.type]
    if (!listType) {
      insertBreak()
      return
    }

    if (Editor.isEmpty(editor, node)) {
      Transforms.setNodes(editor, { type: ElementTypes.paragraph })
      Transforms.unwrapNodes(editor, {
        match: n => n.type === listType,
        split: true,
      })
      return
    }

    insertBreak()
    if (node.type === ElementTypes.checkListItem) {
      Transforms.unsetNodes(editor, checkListItemUnsetProps)
    }
  }

  editor.insertText = text => {
    const { selection } = editor

    if (text !== ' ' || !selection || !Range.isCollapsed(selection)) {
      insertText(text)
      return
    }

    const { anchor } = selection
    const match = Editor.above(editor, { match: n => Editor.isBlock(editor, n) })
    if (!match) {
      insertText(text)
      return
    }

    const [node, path] = match
    const type = node.type
    const start = Editor.start(editor, path)
    const range = { anchor, focus: start }

    const beforeText = Editor.string(editor, range)
    const shortCutType = _.find(ShortCuts, s => s.regex.test(beforeText))?.type
    if (!shortCutType || shortCutType === type) {
      insertText(text)
      return
    }
    const shortCutListType = ListByListItem[shortCutType]
    const listType = ListByListItem[type]
    if (shortCutListType && listType /*shortCutListType !== listType*/) {
      insertText(text)
      return
    }

    Transforms.select(editor, range)
    Transforms.delete(editor)
    Transforms.setNodes(editor, { type: shortCutType }, { match: n => Editor.isBlock(editor, n) })

    if (!shortCutListType && listType) {
      Transforms.unwrapNodes(editor, {
        match: n => n.type === listType,
        split: true,
      })
    }

    if (!listType) {
      wrapItemInList(editor, shortCutListType, path)
    }
    insertText('')
  }

  editor.deleteBackward = unit => {
    const { selection } = editor

    if (!selection || !Range.isCollapsed(selection)) {
      deleteBackward(unit)
      return
    }

    const match = Editor.above(editor, {
      match: n => Editor.isBlock(editor, n),
    })
    if (!match) {
      deleteBackward(unit)
      return
    }

    const [node, path] = match
    const type = node.type
    const start = Editor.start(editor, path)
    if (type === ElementTypes.paragraph || !Point.equals(selection.anchor, start)) {
      if (Editor.isEmpty(editor, node)) {
        const target = Editor.before(editor, selection, { unit }) || Editor.start(editor, [])
        const at = { anchor: selection.anchor, focus: target }
        if (Range.isCollapsed(at)) {
          // it's the last element, so delegating to default behaviour
          // because the last element cannot be deleted.
          deleteBackward(unit)
        } else {
          Transforms.removeNodes(editor, { at: path })
        }
      } else {
        deleteBackward(unit)
      }
      return
    }

    Transforms.setNodes(editor, { type: ElementTypes.paragraph })
    const listType = ListByListItem[type]
    if (listType) {
      Transforms.unwrapNodes(editor, {
        match: n => n.type === listType,
        split: true,
      })
    }
  }

  return editor
}

export const findEndPathOfPreviousList = (editor, listType, at) => {
  const [previousElement, previousPath] = Editor.previous(editor, { at }) || []
  if (previousElement?.type !== listType) {
    return null
  }
  const [, lastPath] = Editor.last(editor, previousPath)
  const parent = Path.parent(lastPath)
  return Path.next(parent)
}

export const findStartPathOfNextList = (editor, listType, at) => {
  const [nextElement, nextPath] = Editor.next(editor, { at }) || []
  if (nextElement?.type !== listType) {
    return null
  }
  const [, firstPath] = Editor.first(editor, nextPath)
  return Path.parent(firstPath)
}

const wrapItemInList = (editor, listType, path) => {
  if (!editor || !listType) {
    return false
  }
  const listItemType = ListItemByList[listType]
  if (!listItemType) {
    return false
  }

  const previousPath = findEndPathOfPreviousList(editor, listType, path)
  if (previousPath) {
    Transforms.moveNodes(editor, {
      match: n => Editor.isBlock(editor, n),
      at: path,
      to: previousPath,
    })
    return true
  }

  const nextPath = findStartPathOfNextList(editor, listType, path)
  if (nextPath) {
    Transforms.moveNodes(editor, {
      match: n => Editor.isBlock(editor, n),
      at: path,
      to: nextPath,
    })
    return true
  }
  const list = { type: listType, children: [] }
  Transforms.wrapNodes(editor, list, {
    match: n => n.type === listItemType,
  })
  return true
}

const handleInsertOverCheckListItem = ({ editor, element, selection }) => {
  const [currentElement, path] =
    Editor.above(editor, {
      at: selection,
      match: n => n.type === ElementTypes.checkListItem,
    }) || []
  if (!currentElement) {
    return false
  }
  const nextPath = Path.next(path)
  const options = { at: nextPath, select: true }
  Transforms.insertNodes(editor, element, options)
  return true
}

const handleInsertAfter = ({ editor, element, listType, selection }) => {
  const { path } =
    Editor.after(editor, selection, {
      unit: 'line',
      distance: 1,
    }) || {}
  if (!path) {
    const list = { type: listType, children: [element] }
    Editor.insertNode(editor, list)
    return true
  }
  const [optionalElement, optionalPath] = Editor.above(editor, {
    at: path,
    mode: 'highest',
    match: n => Editor.isBlock(editor, n),
  })
  if (optionalElement?.type === listType) {
    //if it's a list, place item at its beginning
    Transforms.insertNodes(editor, element, {
      at: [...optionalPath, 0],
      select: true,
    })
  } else {
    //add a new list with the item in it
    const list = { type: listType, children: [element] }
    Transforms.insertNodes(editor, list, {
      at: [...optionalPath],
      select: true,
    })
  }
  return true
}

const handleInsertNoSelection = ({ editor, element, selection }) => {
  if (!selection) {
    Editor.insertNode(editor, element)
    return true
  }
  return false
}

export const insertItemInList = ({ editor, listType, element, lastSelection }) => {
  const fullSelection = editor.selection || lastSelection
  const selection = fullSelection && {
    anchor: fullSelection.focus,
    focus: fullSelection.focus,
  }
  const handlers = [handleInsertNoSelection, handleInsertOverCheckListItem, handleInsertAfter]
  return !!_.find(handlers, fn => fn({ editor, element, listType, selection }))
}
export const insertNewText = ({ editor, element, selection }) => {
  // clearNotes(editor)
  Transforms.select(editor, Editor.start(editor, []))
  Transforms.delete(editor, { distance: 1000000 })
  Editor.insertNode(editor, element.newText)
  return true
}

// TODO: Implement this
/*
const clearNotes = (editor) => {
  Transforms.select(editor, Editor.start(editor, []));
  Transforms.delete(editor, { distance: 10000 })
  const match = Editor.above(editor, {
    match: n => Editor.isBlock(editor, n),
  })
  const [node] = match
  if (!Editor.isEmpty(editor, node)) {
    clearNotes()
  }
}
*/

export const addElementFromOutside = ({ editor, element, selection }) => {
  const { type } = element
  switch (type) {
    case ElementTypes.bulletedListItem:
    case ElementTypes.numberedListItem:
    case ElementTypes.checkListItem: {
      const listType = ListByListItem[type]
      return insertItemInList({ editor, listType, element, lastSelection: selection })
    }
    case ElementTypes.newText:
      return insertNewText({ editor, element, selection })
    default:
      return false
  }
}

export const withNormalize = editor => {
  const { normalizeNode } = editor
  editor.normalizeNode = entry => {
    const [node] = entry
    if (Editor.isEditor(node)) {
      //normalize: merge consecutive checklist
      let prev
      let edit = false
      _.each(node.children, (sibling, index) => {
        if (sibling?.type === ElementTypes.checkList && sibling?.type === prev?.type) {
          Transforms.mergeNodes(editor, {
            at: [index],
          })
          edit = true
          return false //break
        }
        prev = sibling
      })
      if (edit) {
        return
      }
    }
    normalizeNode(entry)
  }
  return editor
}

export const useDisplayInfoCardOnToggleItem = () => {
  const didToggle = useSelector(didToggleItemInMeeting)
  const firstValue = useRef(null)
  const { dispatchToast } = useToastController()
  useEffect(() => {
    if (firstValue.current === null) {
      firstValue.current = didToggle
    }
    if (!firstValue.current && didToggle) {
      dispatchToast(
        <FluentToast>Done with this agenda item. To mark the task as done, you have to open it first.</FluentToast>,
        {
          intent: 'success',
        }
      )
    }
  }, [didToggle, dispatchToast])
}

import _ from 'lodash'
import { Panel } from '@fluentui/react/lib/Panel'
import { Dialog } from '@fluentui/react/lib/Dialog'
import { useReducer } from 'react'
import { dateToTimestampInSeconds } from '../../common/src/helpers/dateHelper'

export const SURFACE_TYPE = {
  PANEL: 'panel',
  DIALOG: 'dialog',
}

export const SURFACE_BY_TYPE = {
  [SURFACE_TYPE.PANEL]: Panel,
  [SURFACE_TYPE.DIALOG]: Dialog,
}

export const ACTION_TYPE = {
  ERROR: 'error',
  LOADING: 'loading',
  FINISHED: 'finished',
  TEXT: 'text',
  OWNER_EMAIL: 'owner_email',
  NOTES: 'notes',
  NOTES_AI: 'notes_ai',
  CLEAR: 'clear',
  OPEN_CONFIRM_CLOSE: 'open_confirm_close',
  GO_BACK_TO_CREATION: 'go_back_to_creation',
  OPEN_DATE_VIEW: 'open_date_view',
  INIT: 'init',
  SET_DUE_DATE: 'set_due_date',
  SET_START_DATE: 'set_start_date',
  SET_QUADRANT: 'set_quadrant',
  SET_PROJECT: 'set_project',
  SET_LINKED_PROJECT: 'set_linked_project',
  SET_CREATE_PROJECT_WITH_AI: 'set_create_project_with_ai',
  SET_ALL_DAY: 'set_all_day',
  CLEAR_DATES: 'clear_dates',
  OPEN_CONFIRM_CREATE: 'open_confirm_create',
  LOADING_PROJECT: 'loading_project',
  PROJECT_LOADED: 'project_loaded',
  DEPENDENCY: 'dependency',
}

export const VIEW = {
  MAIN_FORM: 0,
  CONFIRM_CLOSE: 1,
  DATE: 2,
  CONFIRM_CREATE: 3,
}

const defaultState = {
  loading: false,
  text: '',
  projectID: 0,
  invalidTextMessage: null,
  notes: '',
  ownerEmail: '',
  view: VIEW.MAIN_FORM,
  dueDate: undefined,
  allDay: false,
  startDate: undefined,
  quadrant: 0,
  linkedItem: undefined,
  linkedProjectID: 'create',
  createProjectWithAI: true,
  dependency: 0,

  showFeedback: false,
  success: false,
  usedAI: false,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.OPEN_DATE_VIEW:
      state.view = VIEW.DATE
      break
    case ACTION_TYPE.OPEN_CONFIRM_CLOSE:
      state.view = VIEW.CONFIRM_CLOSE
      break
    case ACTION_TYPE.OPEN_CONFIRM_CREATE:
      state.view = VIEW.CONFIRM_CREATE
      break
    case ACTION_TYPE.GO_BACK_TO_CREATION:
      state.view = VIEW.MAIN_FORM
      break
    case ACTION_TYPE.ERROR:
      state.invalidTextMessage = 'You must set a name'
      break
    case ACTION_TYPE.LOADING:
      state.loading = true
      state.showFeedback = false
      state.invalidTextMessage = null
      break
    case ACTION_TYPE.FINISHED:
      {
        const { error } = action.payload
        state.loading = false
        if (!error) {
          state.text = ''
          state.notes = ''
          state.usedAI = false
          state.copiedFromID = null
        }

        state.showFeedback = true
        state.success = !error
      }
      break
    case ACTION_TYPE.TEXT:
      state.text = action.payload
      state.invalidTextMessage = null
      state.showFeedback = false
      break
    case ACTION_TYPE.OWNER_EMAIL:
      state.ownerEmail = action.payload
      break
    case ACTION_TYPE.NOTES:
      state.notes = action.payload
      break
    case ACTION_TYPE.NOTES_AI:
      state.notes = action.payload
      state.usedAI = true
      break
    case ACTION_TYPE.DEPENDENCY:
      state.dependency = action.payload
      break
    case ACTION_TYPE.SET_PROJECT:
      state.projectID = action.payload
      break
    case ACTION_TYPE.SET_LINKED_PROJECT:
      state.linkedProjectID = action.payload
      break
    case ACTION_TYPE.SET_CREATE_PROJECT_WITH_AI:
      state.createProjectWithAI = action.payload
      break
    case ACTION_TYPE.SET_DUE_DATE:
      state.dueDate = action.payload
      if (!state.dueDate || dateToTimestampInSeconds(state.dueDate) < dateToTimestampInSeconds(state.startDate)) {
        state.startDate = state.dueDate
      }
      break
    case ACTION_TYPE.SET_START_DATE:
      state.startDate = action.payload
      if (state.startDate && dateToTimestampInSeconds(state.dueDate) < dateToTimestampInSeconds(state.startDate)) {
        state.dueDate = state.startDate
      }
      break
    case ACTION_TYPE.CLEAR_DATES:
      state.dueDate = undefined
      state.startDate = undefined
      break
    case ACTION_TYPE.INIT:
      state.allDay = action.payload.allDay ?? true
      state.showFeedback = false
      state.loading = false
      state.dueDate = action.payload.dueDate
      if (_.isNumber(action.payload.quadrant)) state.quadrant = action.payload.quadrant
      state.text = action.payload.name
      state.notes = action.payload.notes
      state.ownerEmail = action.payload.ownerEmail || action.payload.meEmail
      state.projectID = action.payload.project?.get?.('idd') ?? 0
      state.linkedItem = action.payload.linkedItem
      state.startDate = action.payload.startDate
      state.usedAI = false
      state.copiedFromID = action.payload.copiedFromID
      state.linkedProjectID = action.payload.linkedProjectID ?? 'create'
      state.createProjectWithAI = true
      break
    case ACTION_TYPE.SET_QUADRANT:
      state.quadrant = action.payload.quadrant
      break
    case ACTION_TYPE.SET_ALL_DAY:
      state.allDay = action.payload
      break
    case ACTION_TYPE.CLEAR:
      state.loading = false
      state.text = ''
      state.invalidTextMessage = null
      state.notes = ''
      state.view = VIEW.MAIN_FORM
      state.dueDate = undefined
      state.showFeedback = false
      state.success = false
      state.usedAI = false
      state.copiedFromID = null
      state.linkedProjectID = 'create'
      state.createProjectWithAI = true
      break
    default:
      throw new Error('Wrong action on Create Item Modal')
  }
  return { ...state }
}

export const useCreateItemReducer = () => {
  return useReducer(reducer, { ...defaultState })
}

import rand from 'lodash/random'

const sleep = t => new Promise(resolve => setTimeout(() => resolve, t))

export const retry = async (fn, maxRetry = 5, retryTime) => {
  let pending = maxRetry,
    err
  const time = retryTime !== undefined ? retryTime : rand(200, 500)
  while (pending) {
    try {
      return await fn()
    } catch (err_) {
      err = err_
      console.error('Error importing component\n\n', err)
    }
    pending -= 1
    await sleep(time)
  }
  throw err
}

import { type ReactNode, useState } from 'react'
import { ItemsSummaryDialogContext } from '@/typedContexts'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '@/common/src/helpers'
import { List } from 'immutable'
import { DataTable } from '@/views/planning/effort/EffortTable'
import { useCollaboratorsMap } from '@/common/src/hooks/usersHooks'

type ItemsSummaryDialogProps = {
  itemIds: number[]
  open: boolean
  onClose: () => void
}
const ItemsSummaryDialog = ({ itemIds, open, onClose }: ItemsSummaryDialogProps) => {
  const { t } = useTranslation()
  const items = useSelector(state => stateHelper.getItemsFromIDs(state, List(itemIds)))
  const effortByUser = items.reduce((acc: { [key: string]: { Totals: { effort: number } } }, item: any) => {
    const username = itemHelper.getOwnerUsername(item)
    const effort = itemHelper.getEstimatedEffort(item)

    // Initialize or update user's effort with nested Totals structure
    acc[username] = acc[username] || { Totals: { effort: 0 } }
    acc[username].Totals.effort += effort

    return acc
  }, {})

  // Add totals as the last key with the same nested structure
  const totalEffort = Object.values(effortByUser).reduce((sum, user: any) => sum + user.Totals.effort, 0)
  const finalResult = {
    ...effortByUser,
    Totals: { Totals: { effort: totalEffort } },
  }

  const team = useCollaboratorsMap().sortBy((v: any, k: any) => k)

  return (
    <Dialog
      open={open}
      onOpenChange={(event, data) => {
        if (!data.open) {
          onClose()
        }
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('billable_hours_dialog.title')}</DialogTitle>
          <DialogContent>
            <div className="flex flex-col items-center">
              <DataTable
                range={[]}
                table={finalResult}
                viewKey="Effort"
                onClickCell={null}
                team={team}
                reference={finalResult.Totals.Totals.effort}
                inMinutes
              />
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="primary" onClick={onClose}>
                {t('general.close')}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

export type ItemsSummaryDialogSingletonProps = {
  children: ReactNode
}
export const ItemsSummaryDialogSingleton = ({ children }: ItemsSummaryDialogSingletonProps) => {
  const [open, setOpen] = useState(false)
  const [itemIds, setItemIds] = useState<number[]>([])
  return (
    <ItemsSummaryDialogContext.Provider
      value={{
        openDialog: itemIds => {
          setOpen(true)
          setItemIds(itemIds)
        },
      }}
    >
      {children}
      <ItemsSummaryDialog itemIds={itemIds} open={open} onClose={() => setOpen(false)} />
    </ItemsSummaryDialogContext.Provider>
  )
}

export const UI_KEYS = {
  REDUCER: 'ui',

  IS_FIRST_LAUNCH: 'isFirstLaunch',

  SELECTION: 'selection',
  SELECTION_MEETING: 'meeting',
  SELECTION_NOTIFICATION: 'notification',

  RECENT: 'recent',
  RECENT_ITEMS: 'items',

  COMMENTS_NOT_SEND: 'commentsNotSend',
  IS_STATE_REHYDRATED: 'isStateRehydrated',
  VIEWPORT_SCALE: 'viewportScale',
  ONE_ON_ONE: 'oneOnOne',
  PER_SESSION: 'preSession',
  DID_TOGGLE_MEETING_ITEM: 'didToggleMeetingItem',
  PREFERS_MATRIX_MODE: 'prefersMatrixMode',
  NUMBER_OF_PROJECTS: 'numberOfProjects',

  GROWTH_INVITED_EMAIL_SET: 'growthInvitedEmailSet',

  SHOWED_COMPLETED_ITEM_HELP: 'showedCompletedItemHelp',

  SHOW_TRIAL_MESSAGE_BAR: 'showTrialMessageBar',
  SHOW_NOTIFICATION_MESSAGE_BAR: 'showNotificationMessageBar',

  HOME_AGENDA_MODE: 'agendaMode',
  HOME_MY_ITEMS_MODE: 'homeMyItemsMode',

  ITEM_MULTIPLE_SELECTION_ON: 'itemMultipleSelectionOn',
  MULTIPLE_SELECTED_ITEMS: 'multipleSelectedItems',

  PREFERS_DARK_MODE: 'prefersDarkMode',

  LAST_DUE_TIMESTAMP_CREATED: 'lastDueTimestamp',
  LAST_START_TIMESTAMP_CREATED: 'lastStartTimestamp',
  AI_MESSAGE_DISMISSED: 'aiMessageDismissed',

  PM_PLATFORM: 'pmPlatform',

  PROJECTS_PANEL_SHOW_ITEM_COUNTS: 'projectsPanelShowItemCounts',
  PROJECTS_DISPLAY_MODE: 'projectsDisplayMode',

  WEBINAR_REMINDER_ENABLED: 'webinarReminderEnabled',
  LAST_WEBINAR_ID_REMINDED: 'webinarLastIdReminded',
  DISMISS_TRIAL_MESSAGE_BAR_ISO_TIMESTAMP: 'dismissTrialMessageBarIsoTimestamp',

  ITEMS_DISPLAY_MODE: 'itemsDisplayMode',
  CALENDAR_COLOR_MAPPING: 'calendarColorMapping',
  ITEMS_SHOW_AVATARS: 'itemsShowAvatars',
}

export const VIEWPORT = {
  RATE: 0.05,
  MAX: 1.2,
  MIN: 0.8,
}

export const MATRIX_MODE = {
  MATRIX: 'matrix',
  LIST: 'list',
}

export const ROUTE_ID = {
  VERSION: 'version',
  LOADING: 'loading',
  LOADING_STATIC: 'loading_static',
  ONE_ON_ONE: 'one_on_one',
  ONE_ON_ONE_APP: 'app_one_on_one',
  SEARCH: 'search',
  SEARCH_APP: 'app_search',
  SINGLE_PROJECT: 'single_project',
  SINGLE_ITEM: 'single_item',
  SINGLE_ITEM_READ_ONLY: 'single_item_read_only',
  PROJECT_APP: 'project_app',
  ITEM_APP: 'item_app',
  GRAPH_RESOURCE_APP: 'graph_resource_app',
  PROJECTS: 'app_projects',
  MATRIX_APP: 'app_matrix',
  MATRIX_ONE: 'one_matrix',
  MATRIX_READ_ONLY: 'readonly_matrix',
  LIST_APP: 'app_list',
  LIST_ONE: 'one_list',
  LIST_READ_ONLY: 'readonly_list',
  KANBAN_APP: 'app_kanban',
  KANBAN_ONE: 'one_kanban',
  KANBAN_READ_ONLY: 'readonly_kanban',
  CALENDAR_APP: 'app_calendar',
  CALENDAR_ONE: 'one_calendar',
  CALENDAR_READ_ONLY: 'readonly_calendar',
  CALENDAR_TEAMS: 'teams_calendar',
  REPORTS_APP: 'app_reports',
  REPORTS_ONE: 'one_reports',
  GLOBAL_CALENDAR_APP: 'app_global_calendar',
  GLOBAL_REPORTS_APP: 'app_global_reports',
  GANTT_APP: 'app_gantt',
  GANTT_ONE: 'one_gantt',
  GANTT_READ_ONLY: 'readonly_gantt',
  GLOBAL_GANTT_APP: 'app_global_gantt',
  INBOX: 'app_inbox',
  FEED_APP: 'app_feed',
  FEED_ONE: 'one_feed',
  GLOBAL_FEED_APP: 'app_global_feed',
  FEED_TEAMS: 'teams_feed',
  HOME: 'home',
  REDIRECT: 'redirect',
  REDIRECT_FIRST_PROJECT: 'redirect_first_project',
  NEW_PROJECT_APP: 'new_project_modal',
  BLANK_PROJECT_APP: 'blank_project_modal',
  NEW_PROJECT_FROM_CUSTOM_TEMPLATE_APP: 'new_project_from_custom_template_modal',
  NEW_PROJECT_FROM_PUBLIC_TEMPLATE_APP: 'new_project_from_public_template_modal',
  NEW_CUSTOM_PROJECT_TEMPLATE_APP: 'new_custom_project_template',
  GROWTH: 'growth',
  MEETING: 'meeting',
  TEAMS_MEETING: 'teams_meeting',
  PAYWALL: 'paywall',
  EFFORT_PLANNING: 'effort_planning',
  INNACTIVITY_SIGNED_OUT: 'innactivity_signed_out',
  PRINT_PROJECT: 'print_project',
  RESTORE_ITEMS_VIEW: 'restore_items_view',
  IN_APP_PURCHASE_MACOS: 'in_app_purchase_macos',
  IN_APP_PURCHASE_SUCCESS: 'in_app_purchase_success',
}

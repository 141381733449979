import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { isIOS } from '../../common/src/helpers/platformHelper'
import { getViewportScale } from '../../selectors/uiSelectors'

const getViewportWithScale = scale =>
  `width=300, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, viewport-fit=cover `

export const ViewportManager = ({ children }) => {
  const viewportScale = useSelector(getViewportScale)
  const first = useRef(true)
  useEffect(() => {
    if (!first.current && isIOS()) {
      window.open(window.location.href, '_self')
    }
    first.current = false
    const vp = document.querySelector('meta[name=viewport]')
    vp.content = getViewportWithScale(viewportScale)
  }, [viewportScale])
  return children
}

import { useEffect, useState } from 'react'
import { useToastController } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { fromJS } from 'immutable'
import { Button } from '@fluentui/react-components'
import { getMatchParameterInteger } from '../../../common/src/utils'
import { MainLayout } from '../../../components/layout/MainLayout'
import { useSelector, useDispatch } from 'react-redux'
import { stateHelper, itemHelper } from '../../../common/src/helpers'
import { getItemSummariesInProject, putItems } from '../../../common/src/actions/itemsAPI'
import { useFetch } from '../../../common/src/hooks/networkingHooks'
import { useMemo } from 'react'
import { getMultipleSelectedItems } from '../../../selectors/uiSelectors'
import { ItemVirtualizedTable } from '../../../components/table/ItemVirtualizedTable'
import { setMultipleSelectionOn } from '../../../actions/uiActions'
import { uriDataCreator } from '../../../common/src/helpers/URLHelper'
import { linkSubject, SOURCES } from '../../../reactions/linkSubject'
import { PM_API_RESOURCE_TYPE } from '../../../common/src/constants'
import { FlexContainer } from '../../../components/layout/FlexContainer'
import { Spinner } from '../../../components/basic/Loading'
import { FluentToast } from '../../../components/toast/FluentToast'
import { sortBy } from '@/utils/arrayUtils'

const itemsParser = res => {
  const all = sortBy(res.objects, i => -i[itemHelper.KEYS.LAST_MODIFIED_TIMESTAMP])
  return all.map(item => fromJS(item))
}

const EMPTY_ARRAY = []

export const RestoreItemsView = ({ match }) => {
  const dispatch = useDispatch()
  const { dispatchToast } = useToastController()
  const { t } = useTranslation()
  const pid = getMatchParameterInteger(match, 'pid')
  const project = useSelector(st => stateHelper.getProject(st, pid))
  const projectIddField = project?.get('idd') ?? 0
  const name = project?.get('name') ?? ''
  const selection = useSelector(getMultipleSelectedItems)
  const selectionLength = selection?.size ?? 0
  const [restoring, setRestoring] = useState(false)

  const fetchItems = useMemo(() => {
    if (projectIddField) {
      return () =>
        dispatch(
          getItemSummariesInProject(
            {
              state__lte: undefined,
              state: 3,
              limit: 1000,
            },
            projectIddField,
            { skipRequestTracking: true }
          )
        )
    }
  }, [projectIddField, dispatch])

  const { data: fetchedItems, loading } = useFetch(fetchItems, itemsParser)
  const items = fetchedItems ?? EMPTY_ARRAY

  useEffect(() => {
    dispatch(setMultipleSelectionOn(true))
  }, [items, dispatch])

  const main = restoring ? (
    <FlexContainer>
      <Spinner size="large" label={t('restore_items.restoring')} />
    </FlexContainer>
  ) : (
    <ItemVirtualizedTable items={items} loading={loading} shouldShowProject={false} className="h-full" />
  )

  const onClickGoBack = () => {
    const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.PROJECT, pid)
    linkSubject.next({ urlData, source: SOURCES.RESTORE_ITEMS })
  }

  const onClickRestore = async () => {
    const count = selectionLength
    const cache = items.reduce((acc, item) => {
      acc[itemHelper.getId(item)] = item
      return acc
    }, {})

    const restored = selection.map(id => {
      const i = cache[id]
      const completion = itemHelper.getCompletionPercentage(i)
      const state = completion === 100 ? 1 : 0
      return i.set('state', state)
    })
    setRestoring(true)
    await dispatch(putItems(restored))
    onClickGoBack()
    dispatchToast(<FluentToast>{t('restore_items.restore_toast', { count })}</FluentToast>, {
      intent: 'success',
    })
  }

  const topBar = (
    <div className="mx-2">
      <div className="flex items-center justify-between">
        <h2>Restore Items</h2>
        <Button appearance="primary" onClick={onClickGoBack} disabled={restoring}>
          {t('general.go_back')}
        </Button>
      </div>
      <div>Project: {name}</div>
      <div className="flex items-center justify-center">
        <Button appearance="primary" disabled={selectionLength === 0 || restoring} onClick={onClickRestore}>
          {t('restore_items.restore_button', { count: selectionLength })}
        </Button>
      </div>
    </div>
  )

  const components = {
    main,
    topBar,
  }

  return <MainLayout {...components} />
}

import { memo, useCallback, useEffect, useRef, useMemo, useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { AutoFocusTextField } from '../../../components/textFields/AutoFocusTextField'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { Label } from '../../../components/text/Text'
import { Icon } from '@fluentui/react/lib/Icon'
import { itemHelper, projectHelper, stateHelper, tagHelper, userHelper } from '../../../common/src/helpers'
import { ACTION_TYPE, SURFACE_BY_TYPE, SURFACE_TYPE, useCreateItemReducer, VIEW } from '../CreationModalCommon'
import { Spinner } from '../../../components/basic/Loading'
import { useNarrowWidth } from '../../../hooks/useNarrowWidth'
import { useCreateNewItemCustom } from '../../../hooks/itemHooks'
import { UserCollectionCell } from '../../../components/users/usersCollection/UserCollectionCell'
import { OwnerPanel } from '../../../components/item/OwnerPanel'
import { useBoolean } from '../../../common/src/hooks/useBoolean'
import { fromJS } from 'immutable'
import { BaseFlexRow } from '../../../components/layout/FlexContainer'
import { BackButton } from '../../../components/buttons/NavButtons'
import { extenders, teamsClassName } from '../../../style/teamsExtenders'
import { ProjectsDropdown } from '../../../components/dropdown/ProjectsDropdown'
import { ICON_NAMES } from '../../../common/src/msIcons'
import { dateToTimestampInSeconds } from '../../../common/src/helpers/dateHelper'
import { OfficeLikeQuadrantSelector as QuadrantSelector } from '../../../components/input/quadrant/OfficeLikeQuadrantSelector'
import { useTranslation } from 'react-i18next'
import { useRouteId } from '../../../hooks/useRouteId'
import { ItemCreationNotes } from './ItemCreationNotes'
import { getProject } from '../../../common/src/actions/projectsAPI'
import { CreateItemLinkSection } from './CreateItemLinkSection'
import { updateLinks } from '../../../common/src/actions/linksAPI'
import { getSentry } from '../../../getSentry'
import { NOTES_LIMIT_FOR_AI } from '../../../hooks/aiHooks'
import { FillWithAIButton } from '../../../components/buttons/FillWithAIButton'
import { dispatchEvent, EVENT_EXTRA, EVENTS } from '../../../common/src/eventTracking/amplitudeEvents'
import { addTag } from '../../../common/src/actions/tagsAPI'
import { getLastDueTimestampCreated, getLastStartTimestampCreated } from '../../../selectors/uiSelectors'
import { useResizeDetector } from 'react-resize-detector'
import { cn } from '@appfluence/classnames'
import { useConfig } from '../../../queries/config'
import { formatDate } from '../../../utils/datefns'
import { DateSection } from './DateSection'
import { ProjectSelectorDrawer } from '@/views/modal/createItem/ProjectSelectorDrawer'
import { hasTouchScreen } from '@/helpers/deviceDetectionHelper'
import { Checkbox } from '@fluentui/react-components'
import { getProjectLink } from '@/common/src/constants'
import { useConvertItemIntoProject } from '@/queries/items'

const Header = styled(BaseFlexRow)`
  padding: 20px 18px;
  box-sizing: border-box;
`

const ReversedRow = styled(BaseFlexRow)`
  flex-direction: row-reverse;

  & > * {
    margin-left: 12px;
  }
`

const ProjectRow = styled(BaseFlexRow)`
  justify-content: space-between;
  align-items: flex-start;
`

const Quadrant = styled(QuadrantSelector)`
  margin-left: 16px;
  margin-top: 28px;
`

const PanelTitle = styled.h2`
  color: ${p => p.theme.palette.black};
  font-size: 20px;
  font-weight: 600;
  margin-left: 8px;
`

const styles = {
  dialog: {
    main: {
      width: '100%!important',
    },
    // Override https://github.com/microsoft/fluentui/blob/8cd4834b7147c0b9120178a67a5706d396498db3/packages/react/src/components/Modal/Modal.styles.ts#L96
    scrollableContent: {
      '@supports (-webkit-overflow-scrolling: touch)': {
        maxHeight: 'none',
      },
    },
  },
  title: {
    fieldGroup: {
      minHeight: '2em',
    },
  },
}

const NameTextField = extenders.textField(styled(AutoFocusTextField)`
  margin-bottom: 12px;
`)

const SCreateItemLinkSection = styled(CreateItemLinkSection)`
  margin-bottom: 16px;
`

const ProjectSelectorField = extenders.textField(styled(ProjectsDropdown)`
  flex: 1;
  margin-bottom: 12px;
`)

const Messages = styled.div`
  margin-left: 16px;
  position: relative;
`

const MessageContainer = styled(motion.div).attrs({
  initial: {
    y: -20,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 20,
    opacity: 0,
  },
  transition: {
    duration: 0.4,
  },
})`
  z-index: 1;
`

const FeedbackIcon = styled(Icon)`
  margin-right: 5px;
  font-size: 22px;
`

const SuccessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => (p.success ? p.theme.palette.affirmative : p.theme.palette.negative)};
  z-index: 2;
`

const Feedback = memo(({ success }) => {
  const { t } = useTranslation()
  const iconName = success ? 'CheckMark' : 'Cancel'
  const message = success ? t('item.success') : t('item.failure')
  return (
    <SuccessContainer success={success}>
      <FeedbackIcon iconName={iconName} />
      <span>{message}</span>
    </SuccessContainer>
  )
})

const DateButton = memo(({ startDate, dueDate, ...rest }) => {
  const { t } = useTranslation()
  const p = {
    text: t('item.dates'),
    iconProps: {
      iconName: ICON_NAMES.Calendar,
    },
  }
  if (dueDate) {
    p.text = formatDate('ShortenedAllDay')(dueDate)
  }
  if (startDate) {
    p.text = formatDate('ShortenedAllDay')(startDate) + ' - ' + p.text
  }
  return <DefaultButton {...p} {...rest} />
})

//This is a hack to force display none in dialog, due to a blink after creating items
const useForcedHidden = hidden => {
  const [forcedHidden, setForcedHidden] = useState(true)
  useLayoutEffect(() => {
    if (!hidden) {
      setForcedHidden(false)
    }
    const timeout = setTimeout(() => {
      if (hidden) {
        setForcedHidden(true)
      }
    }, 250)
    return () => {
      clearTimeout(timeout)
    }
  }, [hidden])
  return forcedHidden
}

const useInit = ({
  hidden,
  dueDate,
  startDate,
  localDispatch,
  quadrant,
  name,
  notes,
  ownerEmail,
  project,
  meEmail,
  linkedItem,
  allDay,
  copiedFromID,
}) => {
  useEffect(() => {
    if (!hidden) {
      localDispatch({
        type: ACTION_TYPE.INIT,
        payload: {
          project,
          dueDate,
          startDate,
          quadrant,
          ownerEmail,
          meEmail,
          name,
          notes,
          linkedItem,
          allDay,
          copiedFromID,
        },
      })
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden])
}

export const CreateItemModal = memo(
  ({
    isInbox,
    displayProjectSelector = false,
    showLinkedProjectSelector = true,
    quadrant,
    mode,
    open = true,
    hidden = true,
    initialOwnerEmail,
    completion,
    onDismiss = _.identity(),
    isBlocking = true,
    surface = SURFACE_TYPE.DIALOG,
    dueDate,
    startDate,
    allDay,
    notes = '',
    initialName = '',
    initialProject,
    copiedFromID: givenCopiedFromID,
    linkedItem: givenLinkedItem,
  }) => {
    const narrowWidth = useNarrowWidth()
    const routeId = useRouteId()
    const nameFieldRef = useRef(null)
    const meEmail = useSelector(stateHelper.getMeEmail)
    const projectsMap = useSelector(stateHelper.getAllProjects)
    const [state, localDispatch] = useCreateItemReducer()
    const lastDueTimestamp = useSelector(getLastDueTimestampCreated)
    const lastDue = lastDueTimestamp ? new Date(lastDueTimestamp) : undefined
    const lastStartTimestamp = useSelector(getLastStartTimestampCreated)
    const lastStart = lastStartTimestamp ? new Date(lastStartTimestamp) : undefined
    const createNewItem = useCreateNewItemCustom()
    const {
      loading,
      invalidTextMessage,
      text,
      ownerEmail,
      showFeedback,
      success,
      projectID,
      dependency,
      linkedItem,
      copiedFromID,
      linkedProjectID,
      createProjectWithAI,
    } = state
    const project = projectsMap.get(projectID)
    const linkedProject = projectsMap.get(linkedProjectID)
    const targetsInbox = !projectID
    const projectFromStore = useSelector(state => stateHelper.getProject(state, projectID))
    const forcedHidden = useForcedHidden(hidden)
    const owner = useSelector(state => stateHelper.getUserWithEmail(state, ownerEmail)) ?? fromJS({ email: ownerEmail })
    const isPanel = surface === SURFACE_TYPE.PANEL
    const { t } = useTranslation()
    const [clickedAddMore, setClickedAddMore] = useState(false)
    const dispatch = useDispatch()
    const config = useConfig().data
    const canUseAI = config?.ai && config?.ai_deployed
    const { mutate: convertItemIntoProject } = useConvertItemIntoProject()

    const projectForInit = useMemo(() => {
      // If there's no project selector, we don't want to override the initial project
      if (!displayProjectSelector) {
        return initialProject
      }
      // In other case, we could use the previous project
      return initialProject ?? projectFromStore
    }, [displayProjectSelector, initialProject, projectFromStore])

    useInit({
      hidden,
      dueDate,
      startDate,
      allDay,
      localDispatch,
      quadrant,
      name: initialName,
      notes,
      ownerEmail: initialOwnerEmail,
      meEmail,
      project: projectForInit,
      linkedItem: givenLinkedItem,
      copiedFromID: givenCopiedFromID,
    })

    useEffect(() => {
      if (projectID && (!projectHelper.getOwnersURIs(project) || projectHelper.getOwnersURIs(project).size === 0)) {
        dispatch(getProject(projectID, true))
      }
    }, [dispatch, project, projectID])

    const [isOpenOwnerPanel, { setTrue: showOwnerPanel, setFalse: hideOwnerPanel }] = useBoolean(false)

    const createLink = newItem => {
      const newLinks = [
        {
          name: itemHelper.getName(linkedItem),
          linked_item: itemHelper.getResourceURI(linkedItem),
          dependency: dependency,
        },
      ]
      dispatch(updateLinks(itemHelper.getId(newItem), newLinks))
    }

    const onSubmit = async (addMore, isConfirmed = false) => {
      if (!text) {
        return localDispatch({ type: ACTION_TYPE.ERROR })
      }
      const validOwner = targetsInbox || projectHelper.isOwner(project, userHelper.getURI(owner))
      if (!validOwner && !isConfirmed) {
        const Sentry = getSentry()
        const extraInfo = {
          targetsInbox,
          project: project?.toJS(),
          owner: owner?.toJS(),
          ownerURI: userHelper.getURI(owner),
          isOwner: projectHelper.isOwner(project, userHelper.getURI(owner)),
          validOwner,
        }
        Sentry.captureException(new Error('Error delegating item'), {
          level: 'warning',
          extra: extraInfo,
        })
        console.warn('Error delegating item', extraInfo)
        setClickedAddMore(addMore)
        return localDispatch({ type: ACTION_TYPE.OPEN_CONFIRM_CREATE })
      }

      localDispatch({ type: ACTION_TYPE.LOADING })
      const response = await createNewItem({
        notify: false,
        open,
        delayToOpen: 150,
        name: text,
        quadrant: state.quadrant,
        notes: state.notes,
        ownerEmail,
        dueDate: state.dueDate ? dateToTimestampInSeconds(state.dueDate) : undefined,
        startDate: state.startDate ? dateToTimestampInSeconds(state.startDate) : undefined,
        allDay: state.allDay,
        projectID: isInbox ? undefined : projectID,
        copiedFromID,
        link: linkedProjectID && linkedProjectID !== 'create' ? getProjectLink(linkedProjectID) : undefined,
        eventProps: {
          mode: mode,
          quadrant: state.quadrant,
          view: routeId,
        },
      })
      const { error, payload } = response
      setClickedAddMore(false)
      localDispatch({ type: ACTION_TYPE.FINISHED, payload: { error } })
      if (!error) {
        // on mobile, dismiss modal
        if (!addMore) {
          onDismiss()
        }
        const item = fromJS(payload)
        completion?.(item)
        if (state.usedAI) {
          dispatch(addTag({ object: item, tagName: tagHelper.AI_TAG_NAME }))
        }
        if (linkedItem) {
          createLink(item)
        }
        if (showLinkedProjectSelector && linkedProjectID === 'create') {
          convertItemIntoProject(
            { itemId: itemHelper.getId(item), useAI: canUseAI && createProjectWithAI },
            {
              onSuccess: project => {
                //linkSubject.next({ urlData: uriDataCreator(1, PM_API_RESOURCE_TYPE.PROJECT, project.idd) })
                //onDismiss()
              },
            }
          )
          dispatch(
            dispatchEvent(EVENTS.LINK_ITEM_TO_PROJECT, {
              mode: EVENT_EXTRA.LINK_ITEM_TO_PROJECT.MODE.CREATE_ITEM,
            })
          )
        }
      }

      setTimeout(() => {
        try {
          nameFieldRef.current.focus()
        } catch (err) {
          console.log('Cannot focus title')
        }
      }, 10)
    }

    const onSubmitCreate = addMore => () => onSubmit(addMore)

    const onChange = useCallback(
      (ev, value) => localDispatch({ type: ACTION_TYPE.TEXT, payload: value }),
      [localDispatch]
    )

    const onChangeNotes = useCallback(
      value => {
        localDispatch({ type: ACTION_TYPE.NOTES, payload: value })
      },
      [localDispatch]
    )

    const onChangeOwner = useCallback(
      email => localDispatch({ type: ACTION_TYPE.OWNER_EMAIL, payload: email }),
      [localDispatch]
    )
    useEffect(() => {
      if (_.isEmpty(ownerEmail)) {
        onChangeOwner(meEmail)
      }
    }, [meEmail, ownerEmail, onChangeOwner])

    const onChangeProject = useCallback(
      project => {
        const id = projectHelper.getIdd(project)
        localDispatch({ type: ACTION_TYPE.SET_PROJECT, payload: id })
      },
      [localDispatch]
    )

    const onChangeQuadrant = useCallback(
      q => {
        localDispatch({
          type: ACTION_TYPE.SET_QUADRANT,
          payload: {
            quadrant: q,
          },
        })
      },
      [localDispatch]
    )

    const onChangeDependency = useCallback(
      (evt, value) => {
        localDispatch({
          type: ACTION_TYPE.DEPENDENCY,
          payload: value,
        })
      },
      [localDispatch]
    )

    const updateNotesAI = notes => localDispatch({ type: ACTION_TYPE.NOTES_AI, payload: notes })

    const onKeyDown = evt => {
      if (evt.code === 'Enter') {
        const addMore = evt.metaKey || evt.ctrlKey
        evt.preventDefault()
        evt.stopPropagation()
        onSubmit(addMore).catch(console.log)
      }
    }

    const catchDismiss = () => {
      const WIP = !!state.notes || !!text
      if (WIP) {
        localDispatch({ type: ACTION_TYPE.OPEN_CONFIRM_CLOSE })
        return
      }
      setTimeout(() => {
        localDispatch({ type: ACTION_TYPE.CLEAR })
      }, 300)
      onDismiss()
    }

    const renderDialogFooter = () => {
      if (surface === SURFACE_TYPE.DIALOG) {
        return (
          <DialogFooter>
            <PrimaryButton
              id="createItemModal_addAnotherButton"
              key="addAnother"
              onClick={onSubmitCreate(true)}
              disabled={loading}
            >
              {`${t('general.add')} ${t('general.another')}`}
            </PrimaryButton>
            <PrimaryButton id="createItemModal_addButton" key="add" onClick={onSubmitCreate(false)} disabled={loading}>
              {t('general.add')}
            </PrimaryButton>
            <DefaultButton id="createItemModal_cancelButton" key="back" onClick={catchDismiss} disabled={loading}>
              {t('general.close')}
            </DefaultButton>
          </DialogFooter>
        )
      }
      return false
    }

    const _renderPanelFooter = () => (
      <ReversedRow>
        <PrimaryButton id="createItemModal_addButton" key="add" onClick={onSubmitCreate(false)} disabled={loading}>
          {t('general.add')}
        </PrimaryButton>
        <DefaultButton id="createItemModal_cancelButton" key="back" onClick={catchDismiss} disabled={loading}>
          {t('item.go_back')}
        </DefaultButton>
      </ReversedRow>
    )

    const renderPanelFooter = isPanel ? _renderPanelFooter : _.identity

    const _renderNavigationContent = () => (
      <Header>
        <BackButton onClick={onDismiss} />
        <PanelTitle>{t('item.item_creation')}</PanelTitle>
      </Header>
    )

    const renderNavigationContent = isPanel ? _renderNavigationContent : _.identity

    const teamsCN = cn({
      [teamsClassName]: isPanel,
    })
    const projectsCN = cn(
      {
        pm_hidden: !displayProjectSelector,
      },
      teamsCN
    )

    const view = {
      content: void 0,
    }
    switch (state.view) {
      case VIEW.CONFIRM_CLOSE:
        {
          view.content = <ConfirmClose localDispatch={localDispatch} onDismiss={onDismiss} />
        }
        break
      case VIEW.CONFIRM_CREATE:
        {
          view.content = (
            <ConfirmCreate localDispatch={localDispatch} onSubmit={onSubmit} clickedAddMore={clickedAddMore} />
          )
        }
        break
      case VIEW.DATE:
        {
          view.content = (
            <DateSection localDispatch={localDispatch} state={state} lastDue={lastDue} lastStart={lastStart} />
          )
        }
        break
      case VIEW.MAIN_FORM:
      default:
        {
          const onClickDate = () => {
            localDispatch({
              type: ACTION_TYPE.OPEN_DATE_VIEW,
            })
          }
          view.content = (
            <>
              {linkedItem && (
                <SCreateItemLinkSection
                  linkedItem={linkedItem}
                  onChangeDependency={onChangeDependency}
                  dependency={dependency}
                />
              )}
              <div className="relative">
                <DateButton
                  className="absolute right-0 top-[-10px] z-10"
                  startDate={state.startDate}
                  dueDate={state.dueDate}
                  onClick={onClickDate}
                  data-automation-id="createItemModal_dateButton"
                />
                <NameTextField
                  id="createItemModal_textField"
                  onChange={onChange}
                  label={t('item.title') + '*'}
                  placeholder={t('item.title_placeholder')}
                  multiline
                  resizable={false}
                  value={text}
                  borderless={isPanel}
                  errorMessage={invalidTextMessage}
                  onKeyDown={onKeyDown}
                  disabled={loading}
                  ref={nameFieldRef}
                  className={teamsCN}
                  styles={styles.title}
                />
              </div>
              <Label>{t('item.owner') + '*'}</Label>
              <UserCollectionCell
                id="itemFiltersView_ownerCell"
                user={owner}
                project={project}
                onClick={showOwnerPanel}
                disabled={loading}
                className={cn(
                  'mb-3 box-border rounded-sm border border-solid border-pm-neutral-secondary px-1 py-0.5',
                  loading && 'border-none',
                  isPanel && 'border-0 bg-pm-neutral-light'
                )}
              />
              <ProjectRow className={projectsCN}>
                {hasTouchScreen() ? (
                  <div className="flex w-full flex-col">
                    <Label>{t('item.project')}</Label>
                    <ProjectSelectorDrawer
                      onSelectProject={(evt, project) => {
                        localDispatch({ type: ACTION_TYPE.SET_PROJECT, payload: projectHelper.getIdd(project) })
                      }}
                      selectedProject={project}
                    />
                  </div>
                ) : (
                  <>
                    <ProjectSelectorField
                      label={t('item.project')}
                      enableInbox
                      selectedProject={project || 0}
                      onProjectChanged={onChangeProject}
                      className={projectsCN}
                    />
                    <Quadrant
                      size={32}
                      selected={project ? state.quadrant : -1}
                      onClickQuadrant={project ? onChangeQuadrant : undefined}
                    />
                  </>
                )}
              </ProjectRow>
              {showLinkedProjectSelector && (
                <>
                  <Label>Linked Project</Label>
                  <Checkbox
                    label="Create and Link to New Project"
                    checked={linkedProjectID === 'create'}
                    onChange={(ev, data) => {
                      localDispatch({
                        type: ACTION_TYPE.SET_LINKED_PROJECT,
                        payload: data.checked ? 'create' : null,
                      })
                    }}
                  />
                  {linkedProjectID === 'create' ? (
                    !!config?.ai_deployed && (
                      <div className="mb-2 flex flex-col">
                        <Checkbox
                          label={t('item.convert_item_to_project_dialog.use_ai')}
                          checked={createProjectWithAI}
                          onChange={(ev, data) =>
                            localDispatch({ type: ACTION_TYPE.SET_CREATE_PROJECT_WITH_AI, payload: data.checked })
                          }
                          disabled={!config?.ai}
                        />
                        {!!config?.ai && (
                          <span className="text-neutral-700 dark:text-neutral-400">
                            ℹ️ {t('item.convert_item_to_project_dialog.ai_explanation')}
                          </span>
                        )}
                        {!config?.ai && (
                          <div className="text-pm-negative">
                            {t('new_project.create_with_ai.disabled_title')}
                            <br />
                            {t('new_project.create_with_ai.disabled_subtitle')}
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <ProjectsDropdown
                      onProjectChanged={project => {
                        localDispatch({
                          type: ACTION_TYPE.SET_LINKED_PROJECT,
                          payload: projectHelper.getIdd(project),
                        })
                      }}
                      selectedProject={linkedProjectID === 'create' ? undefined : linkedProject}
                    />
                  )}
                </>
              )}
              <Label>{t('item.notes')}</Label>
              <ItemCreationNotes
                onChangeNotes={onChangeNotes}
                placeholder={t('item.notes_placeholder')}
                value={state.notes}
                additionalToolbarButtons={
                  canUseAI ? (
                    <FillWithAIButton
                      updateNotes={updateNotesAI}
                      disabled={!text || state.notes?.length > NOTES_LIMIT_FOR_AI}
                      eventTrackingMode={EVENT_EXTRA.AI_FILL_NOTES.MODE.CREATE_ITEM_MODAL}
                      itemName={text}
                      itemCurrentNotes={state.notes}
                      // Use project only if we are in a project view
                      itemProjectIdd={!displayProjectSelector ? projectID : undefined}
                    />
                  ) : undefined
                }
              />
              {renderDialogFooter()}
              <OwnerPanel
                user={owner}
                project={project}
                onChange={onChangeOwner}
                isOpen={isOpenOwnerPanel}
                onDismiss={hideOwnerPanel}
                hiddenClearButton={true}
              />
            </>
          )
        }
        break
    }

    const dialogStyles = {
      root: forcedHidden
        ? {
            display: 'none',
          }
        : {},
      main: {
        width: '100%!important',
      },
    }

    const LocalSurface = SURFACE_BY_TYPE[surface]

    // To avoid warning in console because of using deprecated prop "isOpen" and "isBlocking" in Dialog
    const surfaceProps = isPanel
      ? { isOpen: !hidden, isBlocking }
      : {
          hidden,
          modalProps: {
            isBlocking: isBlocking,
            styles: styles.dialog,
          },
        }

    return (
      <LocalSurface
        {...surfaceProps}
        onDismiss={catchDismiss}
        onRenderNavigationContent={renderNavigationContent}
        styles={dialogStyles}
        key={'create'}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: (
            <>
              {t(linkedItem ? 'item.new_linked_item' : 'item.new_item')}
              <Messages>
                <AnimatePresence>
                  {loading && (
                    <MessageContainer key="loadingSpinner">
                      <Spinner size="medium" labelPosition={'right'} label={t('item.creating_short')} />
                    </MessageContainer>
                  )}
                  {showFeedback && (
                    <MessageContainer key="feedback" narrowWidth={narrowWidth}>
                      <Feedback success={success} />
                    </MessageContainer>
                  )}
                </AnimatePresence>
              </Messages>
            </>
          ),
          styles: {
            title: {
              display: 'flex',
              alignItems: 'end',
            },
          },
        }}
        maxWidth={'650px'}
        minWidth={'350px'}
        onRenderFooterContent={renderPanelFooter}
        isFooterAtBottom
      >
        <JumpyContainer>{view.content}</JumpyContainer>
      </LocalSurface>
    )
  }
)

const JumpyContainer = ({ children }) => {
  const { height, ref } = useResizeDetector({ handleWidth: false })
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, height }}
      transition={{ duration: 0.4, type: 'spring' }}
    >
      <div ref={ref} className="flex flex-col">
        {children}
      </div>
    </motion.div>
  )
}

const ConfirmClose = memo(({ localDispatch, onDismiss }) => {
  const { t } = useTranslation()
  const onDiscard = () => {
    setTimeout(() => {
      localDispatch({ type: ACTION_TYPE.CLEAR })
    }, 300)
    onDismiss()
  }
  const onGoBack = () => {
    localDispatch({
      type: ACTION_TYPE.GO_BACK_TO_CREATION,
    })
  }
  return (
    <>
      <p className="text-pm-black">{t('item.pending_data')}</p>
      <DialogFooter>
        <PrimaryButton key="yes" onClick={onDiscard}>
          {t('general.yes')}
        </PrimaryButton>
        <DefaultButton key="no" onClick={onGoBack}>
          {t('general.no')}
        </DefaultButton>
      </DialogFooter>
    </>
  )
})

const ConfirmCreate = ({ localDispatch, onSubmit, clickedAddMore }) => {
  const { t } = useTranslation()

  const onConfirm = () => {
    onSubmit(clickedAddMore, true)
    localDispatch({
      type: ACTION_TYPE.GO_BACK_TO_CREATION,
    })
  }
  const onGoBack = () => {
    localDispatch({
      type: ACTION_TYPE.GO_BACK_TO_CREATION,
    })
  }
  return (
    <>
      <p>{t('item.user_not_in_project')}</p>
      <DialogFooter>
        <PrimaryButton key="yes" onClick={onConfirm}>
          {t('general.yes')}
        </PrimaryButton>
        <DefaultButton key="no" onClick={onGoBack}>
          {t('general.no')}
        </DefaultButton>
      </DialogFooter>
    </>
  )
}

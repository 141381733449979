import './i18n'
import _ from 'lodash'
import { initializeTeams } from './utils/teams'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { store } from './configureStore'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { CustomRouter } from './CustomRouter'
import { RouterContent } from './routes/routerContent'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { registerIcons } from '@fluentui/react/lib/Styling'
import { initializeWs } from './common/src/websocket/websocket'
import { CustomThemeProvider } from './themes'
import * as queryParamsHelper from './helpers/queryParamsHelper'
import { customIcons } from './icons/customIcons'
import { registerPublicMethods } from './registerPublicMethods'
import { WEBAPP_PM_PLATFORMS } from './common/src/constants'
import { DynamicPlatformConfig } from './common/src/config/dynamicPlatformConfig'
import { SessionTokenManager } from './common/src/SessionTokenManager'
import { isFirstLaunchSelector, isStateRehydrated } from './selectors/uiSelectors'
import { setIsCancelled } from './actions/onboardingActions'
import { setIsFirstLaunch, setPMPlatform } from './actions/uiActions'
import { isInternetExplorer } from './common/src/helpers/platformHelper'
import { initAll } from './common/src/actions/genericActions'
import {
  AMPLITUDE_ACTION_TYPES,
  dispatchEvent as trackEvent,
  EVENT_EXTRA,
} from './common/src/eventTracking/amplitudeEvents'
import { useCodes } from './hooks/useCodes'
import { CYPRESS_ID } from './constants/cypress'
import { useConfig } from './queries/config'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { Button, useToastController } from '@fluentui/react-components'
import { FluentToast } from './components/toast/FluentToast'
import { useTranslation } from 'react-i18next'
import { useFastRequests } from './utils/appInitialization'
import { useSettings } from '@/queries/settings'

initializeTeams((...args) => store.dispatch(...args))
initializeIcons()
registerIcons({ icons: customIcons })

const useOnboarding = isRehydrated => {
  const dispatch = useDispatch()
  const isFirstLaunch = useSelector(isFirstLaunchSelector)

  useEffect(() => {
    if (isRehydrated) {
      if (isFirstLaunch && queryParamsHelper.showOnboarding()) {
        dispatch(setIsCancelled(false))
        dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.WEB_APP_ONBOARDING_INITIAL))
      }
    }
  }, [isRehydrated, isFirstLaunch, dispatch])
}

const RequestInitializator = () => {
  useFastRequests()
  return null
}

const useCheckQueryParams = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (queryParamsHelper.fromNotification()) {
      dispatch(
        trackEvent(AMPLITUDE_ACTION_TYPES.OPEN_WEB_PUSH, {
          status: EVENT_EXTRA.WEB_PUSH.STATUS.OPEN_NEW_TAB,
        })
      )
    }
  }, [dispatch])
  const checkQueryParams = useCallback(async () => {
    const token = window.prioritymatrix.sessionToken
    if (token) {
      SessionTokenManager.set(token)
    }

    const platformInfo = await window.electronAPI?.getPlatformInfo?.()
    let platform = undefined

    if (queryParamsHelper.isWindows()) {
      import('./style/windowsEmbedded.css')
    } else if (queryParamsHelper.isEmbeddedOnAndroid()) {
      platform = WEBAPP_PM_PLATFORMS.ANDROID_WEBVIEW
    } else if (queryParamsHelper.isEmbeddedOnIos()) {
      platform = WEBAPP_PM_PLATFORMS.IOS_WEBVIEW
    } else if (platformInfo) {
      platform = platformInfo.platform
    }

    if (platform) {
      DynamicPlatformConfig.setName(platform)
      dispatch(setPMPlatform(platform))
    }
    if (platformInfo?.version) {
      DynamicPlatformConfig.setVersion(platformInfo.version)
    }
  }, [dispatch])

  const checkQueryParamsOnce = useMemo(() => {
    return _.once(checkQueryParams)
  }, [checkQueryParams])

  checkQueryParamsOnce()
}

const checkIE = () => {
  if (isInternetExplorer()) {
    import('./style/iePanelFix.css')
  }
}

const App = () => {
  return (
    <Provider store={store}>
      <CustomThemeProvider>
        <ServiceWorkerManager />
        <AppContent />
      </CustomThemeProvider>
      <RequestInitializator />
    </Provider>
  )
}

const AppContent = () => {
  const dispatch = useDispatch()
  useCheckQueryParams()

  // Codes
  useCodes()

  useEffect(() => {
    initializeWs(store)
  }, [])

  const [loading, setLoading] = useState(true)

  useConfig()
  useSettings()

  const isInitialized = useRef(false)

  const onLaunch = useCallback(async () => {
    checkIE()
    dispatch(setIsFirstLaunch())
    registerPublicMethods(store)
    dispatch(initAll())
    setLoading(false)
  }, [dispatch])

  const isRehydrated = useSelector(isStateRehydrated)
  useEffect(() => {
    if (isRehydrated && !isInitialized.current) {
      isInitialized.current = true
      onLaunch()
    }
  }, [isRehydrated, onLaunch])

  useOnboarding(isRehydrated)

  return (
    <CustomRouter>
      <div className="fixed h-full w-full bg-pm-white" data-automation-id={CYPRESS_ID.MAIN_CONTAINER}>
        <RouterContent loading={loading} />
      </div>
    </CustomRouter>
  )
}

function ServiceWorkerManager() {
  const { t } = useTranslation()
  const { dispatchToast } = useToastController()
  const toastIsUnmounted = useRef(true)

  const { updateServiceWorker } = useRegisterSW({
    onNeedRefresh() {
      // Avoid showing multiple toasts to update the app
      if (!toastIsUnmounted.current) return
      toastIsUnmounted.current = false
      dispatchToast(
        <FluentToast
          footer={
            <Button
              onClick={async () => {
                await updateServiceWorker()
                setTimeout(() => window.location.reload(), 1000)
              }}
            >
              {t('new_version.reload_button')}
            </Button>
          }
        >
          {t('new_version.message')}
        </FluentToast>,
        {
          timeout: -1,
          onStatusChange: (e, { status }) => (toastIsUnmounted.current = status === 'unmounted'),
        }
      )
    },
    onRegisteredSW(_swScriptUrl, registration) {
      registration &&
        window.addEventListener(
          'focus',
          _.debounce(
            () => {
              registration.update()
            },
            60 * 1000,
            { leading: true, trailing: true }
          )
        )
      console.log('SW Registered: ', registration)
    },
    onRegisterError(error) {
      console.error('SW registration error', error)
    },
  })
  return false
}

export default App

import { useQuery } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useMe } from '@/common/src/hooks/usersHooks'
import { itemHelper, stateHelper, userHelper } from '@/common/src/helpers'
import { dateToTimestampInSeconds } from '@/common/src/helpers/dateHelper'
import { HOME_DROPDOWN_OPTIONS } from '@/actions/uiActions'
import { getPaginatedSearchItem } from '@/common/src/actions/searchAPI'
import { SEARCH_REDUCER_KEYS } from '@/common/src/reducers/searchKeys'
import _ from 'lodash'
import { checkOwnershipByMode } from '@/views/home/checkOwnershipByMode'
import { datefns } from '@/utils/datefns'
import { useState } from 'react'
import { differenceInSeconds } from 'date-fns'

const getTimeInterval = (now: Date) => {
  const end = _.flow([datefns.add({ days: 7 }), datefns.endOfDay])(now)
  const start = _.flow([datefns.add({ months: -1 }), datefns.startOfDay])(now)
  return [start, end] as [start: Date, end: Date]
}

const getDueItemsInInterval = (startDate: Date, endDate: Date, email: string, mode: string) => {
  const params: Record<string, unknown> = {
    state__lte: itemHelper.STATE.UNFINISHED,
    dueDate__gt: dateToTimestampInSeconds(startDate),
    dueDate__lt: dateToTimestampInSeconds(endDate),
    summaries: 1,
  }
  if (mode === HOME_DROPDOWN_OPTIONS.OWNED) {
    params.owner = email
  } else {
    params.returnfollowers = 1
  }
  return getPaginatedSearchItem(params, SEARCH_REDUCER_KEYS.SECTION_AGENDA)
}

const useItemsInInterval = (interval: [start: Date, end: Date], mode: string) => {
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const meEmail = useSelector(stateHelper.getMeEmail)
  const [start, end] = _.map(interval, d => dateToTimestampInSeconds(d))
  const inInterval = (x: number) => _.inRange(x, start, end)
  const checkOwnership = (checkOwnershipByMode as any)[mode]
  return allItems.filter((i: any) => {
    const due = itemHelper.getDueTimestamp(i)
    return checkOwnership(i, meEmail) && inInterval(due) && itemHelper.getState(i) === itemHelper.STATE.UNFINISHED
  })
}

export const useAgendaItems = (mode: string) => {
  const dispatch = useDispatch()
  const me = useMe()
  const email = userHelper.getEmail(me)
  const [placeholderNow] = useState(() => new Date())

  const queryResult = useQuery({
    queryKey: ['agendaItems', { email, mode }],
    queryFn: async () => {
      // Avoid modifying placeholder "now" if we are at the first request
      const now = Math.abs(differenceInSeconds(placeholderNow, new Date())) < 5 ? placeholderNow : new Date()
      const interval = getTimeInterval(now)
      return { interval, now, result: await dispatch(getDueItemsInInterval(...interval, email, mode)) }
    },
    refetchInterval: 5 * 60 * 1000, // 5 minutes
    staleTime: 5 * 60 * 1000, // 5 minutes
    placeholderData: { interval: getTimeInterval(placeholderNow), now: placeholderNow, result: undefined },
  })

  return { queryResult, items: useItemsInInterval(queryResult.data!.interval, mode) }
}

import _ from 'lodash'
import { fromJS, is, Set, Map } from 'immutable'
import { actionTypeString, PERSIST_REHYDRATE_ACTION_TYPE } from '../helpers/reducerHelper'
import { APIS, PM_API_RESOURCES } from '../constants'
import { ACTIONS as WS_ACTIONS } from '../websocket/wsActions'
import { ACTIONS as COMBINED_API_ACTIONS } from '../actions/combinedAPI'
import { ACTIONS as ITEMS_ACTIONS } from '../actions/itemActions'
import { fileHelper, itemHelper, projectHelper, userHelper, reminderHelper } from '../helpers'
import { Fns } from '../helpers/functionalUtils'
import { dateToTimestampInSeconds } from '../helpers/dateHelper'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import * as commonReducerLogic from './commonReducerLogic'
import { ITEMS_REDUCER_KEYS } from './itemsReducerKeys'
import { resourceURICreator, resourceURIParser } from '../helpers/URLHelper'

const base = fromJS({
  [ITEMS_REDUCER_KEYS.BY_ID]: {},
  [ITEMS_REDUCER_KEYS.MODIFIED]: {},
  [ITEMS_REDUCER_KEYS.UNSYNCED]: [],
  [ITEMS_REDUCER_KEYS.ACCESS_DENIED]: {},
  [ITEMS_REDUCER_KEYS.INBOX_PLUS]: Set(),
  [ITEMS_REDUCER_KEYS.ME_EMAIL]: '',
  [ITEMS_REDUCER_KEYS.ATTENTION_NEEDED]: Set(),
})

const addTag = commonReducerLogic.addTagCreator(ITEMS_REDUCER_KEYS.BY_ID, itemHelper.KEYS.TAGS)
const removeTag = commonReducerLogic.removeTagCreator(ITEMS_REDUCER_KEYS.BY_ID, itemHelper.KEYS.TAGS)

const computeAttention = (state, jsItem, trust = false) => {
  const { id } = jsItem
  const item = fromJS(jsItem)
  const email = state.get(ITEMS_REDUCER_KEYS.ME_EMAIL)
  const attentionSet = state.get(ITEMS_REDUCER_KEYS.ATTENTION_NEEDED)
  const isDeleted = itemHelper.isDeleted(item)
  if (isDeleted) {
    state = state.set(ITEMS_REDUCER_KEYS.ATTENTION_NEEDED, attentionSet.delete(id))
  }
  if (trust || itemHelper.needsAttention(item, email)) {
    state = state.set(ITEMS_REDUCER_KEYS.ATTENTION_NEEDED, attentionSet.add(id))
  }
  return state
}

const storeSingleJSItem = (state, item, checkingIsInbox = true) => {
  const { id, last_read } = item
  const isModified = !!state.getIn([ITEMS_REDUCER_KEYS.MODIFIED, id])
  if (isModified) {
    return state
  }

  const prev = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, id])
  const prevLastRead = (prev && prev.get(itemHelper.KEYS.LAST_READ)) || 0
  const incomingLastRead = last_read || 0
  if (prevLastRead > incomingLastRead) {
    item.last_read = prevLastRead
  }

  const prevRelatedItems = (prev && prev.get(itemHelper.KEYS.RELATED_ITEMS)) || []
  item.related_items = prevRelatedItems

  const localItem = itemHelper.createFromRaw(item)
  if (checkingIsInbox) {
    const isInbox = itemHelper.isInbox(localItem)
    if (isInbox) {
      state = addInboxItems(state, [item])
    } else {
      state = removeInboxItems(state, [item])
    }
  }

  const isDeleted = itemHelper.isDeleted(localItem)
  if (isDeleted) {
    return state.deleteIn([ITEMS_REDUCER_KEYS.BY_ID, id])
  }
  return state.mergeIn([ITEMS_REDUCER_KEYS.BY_ID, id], localItem)
}

const storeItems = (state, items, checkingIsInbox = false) => {
  return state.withMutations(_st => {
    _.each(items, item => {
      storeSingleJSItem(_st, item, checkingIsInbox)
    })
    return _st
  })
}

const storeInboxItems = (state, items) => {
  state = addInboxItems(state, items)
  return storeItems(state, items, false)
}

const addInboxItems = (state, items) => {
  const set = state.get(ITEMS_REDUCER_KEYS.INBOX_PLUS)
  const modifiedSet = set.withMutations(s => {
    _.each(items, i => s.add(i.id))
  })
  return state.set(ITEMS_REDUCER_KEYS.INBOX_PLUS, modifiedSet)
}
const removeInboxItems = (state, items) => {
  const set = state.get(ITEMS_REDUCER_KEYS.INBOX_PLUS)
  const modifiedSet = set.withMutations(s => {
    _.each(items, i => s.remove(i.id))
  })
  return state.set(ITEMS_REDUCER_KEYS.INBOX_PLUS, modifiedSet)
}

//Summaries should not overwrite full items
const storeSummaries = (state, items, requested_time) => {
  return state.withMutations(_st => {
    _.each(items, item => {
      if (item) {
        if (requested_time) {
          item.requested_time = requested_time
        }
        const id = item.id
        if (!item.resource_uri) {
          item.resource_uri = resourceURICreator(1, 'item', id)
        }
        const isNew = !_st.getIn([ITEMS_REDUCER_KEYS.BY_ID, id])
        if (isNew) {
          const localItem = itemHelper.createFromRaw(item)
          const isDeleted = itemHelper.isDeleted(localItem)
          if (!isDeleted) {
            _st.setIn([ITEMS_REDUCER_KEYS.BY_ID, id], localItem)
          }
        }
      }
    })
    return _st
  })
}

const deleteItemsInProject = (state, idd) => {
  const itemsMap = state.get(ITEMS_REDUCER_KEYS.BY_ID)
  const filteredItemsMap = itemsMap.filterNot(item => itemHelper.belongsToProjectWithId(item, idd))
  return state.set(ITEMS_REDUCER_KEYS.BY_ID, filteredItemsMap)
}

const deleteItemsInDeletedProject = (state, projectJS) => {
  const project = fromJS(projectJS)
  if (projectHelper.isDeleted(project)) {
    return deleteItemsInProject(state, projectHelper.getIdd(project))
  }
  return state
}

const AT = _.mapValues(actionTypeString, f => _.partial(f, APIS.PM, _))

const markItemsRead = (state, { payload: ids }) =>
  state.withMutations(_st => {
    ids.forEach(id => {
      const item = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, id])
      if (item) {
        const timestamp = dateToTimestampInSeconds(new Date())
        const modifiedItem = item
          .set(itemHelper.KEYS.LAST_READ, timestamp)
          .set(itemHelper.KEYS.HAS_UNREAD_MENTION, false)
          .set(itemHelper.KEYS.NOTIFICATION, null)
        _st.setIn([ITEMS_REDUCER_KEYS.BY_ID, id], modifiedItem)
        // Delete attention
        const attentionSet = _st.get(ITEMS_REDUCER_KEYS.ATTENTION_NEEDED)
        _st.set(ITEMS_REDUCER_KEYS.ATTENTION_NEEDED, attentionSet.delete(id))
      }
    })
  })

const handlers = {
  [AT.start(PM_API_RESOURCES.INVITE_FOLLOWER_TO_ITEM)]: (state, action) => {
    const { meta } = action
    const { itemID, email } = meta
    let item = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, itemID])
    let followers = item.get(itemHelper.KEYS.FOLLOWERS)
    followers = followers.push(fromJS({ email: email }))
    item = item.set(itemHelper.KEYS.FOLLOWERS, followers)
    return state.setIn([ITEMS_REDUCER_KEYS.BY_ID, itemID], item)
  },
  [AT.error(PM_API_RESOURCES.INVITE_FOLLOWER_TO_ITEM)]: (state, action) => {
    const { meta } = action
    const { itemID, email } = meta
    let item = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, itemID])
    let followers = item.get(itemHelper.KEYS.FOLLOWERS)
    followers = followers.filter(f => f.get('email') !== email)
    item = item.set(itemHelper.KEYS.FOLLOWERS, followers)
    return state.setIn([ITEMS_REDUCER_KEYS.BY_ID, itemID], item)
  },
  [AT.start(PM_API_RESOURCES.UNINVITE_FOLLOWER_TO_ITEM)]: (state, action) => {
    const { meta } = action
    const { itemID, email } = meta
    let item = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, itemID])
    let followers = item.get(itemHelper.KEYS.FOLLOWERS)
    followers = followers.filterNot(u => userHelper.getEmail(u) === email)
    item = item.set(itemHelper.KEYS.FOLLOWERS, followers)
    return state.setIn([ITEMS_REDUCER_KEYS.BY_ID, itemID], item)
  },
  [AT.success(PM_API_RESOURCES.ME)]: (state, action) => {
    const { payload } = action
    const email = payload.email
    state = state.set(ITEMS_REDUCER_KEYS.ME_EMAIL, email)
    return state
  },
  [AT.success(PM_API_RESOURCES.ATTENTION_NEEDED)]: (state, action) => {
    const { payload } = action
    const timestamp = dateToTimestampInSeconds(new Date())
    const isFirstPage = payload.meta.offset === 0
    // We trust in this endpoint as a source of truth, so we add the last_attention field in case it's not present
    const items = _.map(payload.objects, item => {
      const lastRead = item.last_read || 0
      const { last_attention, reminder } = item
      const hasPendingReminder = !_.isEmpty(reminder) && last_attention < lastRead
      if (!last_attention || hasPendingReminder) {
        item.last_attention = timestamp
      }
      return item
    })
    //computeAttention
    state = state.withMutations(_st => {
      if (isFirstPage) {
        _st.set(ITEMS_REDUCER_KEYS.ATTENTION_NEEDED, Set()) // Reset attention needed
      }
      _.each(items, i => {
        _st = computeAttention(_st, i, true)
      })
    })
    state = storeItems(state, items)
    return state
  },
  [AT.success(PM_API_RESOURCES.ITEM_SUMMARIES)]: (state, { payload }) => {
    state = storeSummaries(state, payload.objects, _.get(payload, 'meta.requested_time'))
    return state
  },
  [AT.success(PM_API_RESOURCES.ITEM_SUMMARIES_IN_PROJECT)]: (...args) =>
    handlers[AT.success(PM_API_RESOURCES.ITEM_SUMMARIES)](...args),
  [AT.success(PM_API_RESOURCES.INBOX_PLUS)]: (state, { payload }) => {
    return storeInboxItems(state, payload.objects)
  },
  [AT.success(PM_API_RESOURCES.ALL_ITEMS)]: (state, { payload }) => {
    return storeItems(state, payload.objects)
  },
  [AT.success(PM_API_RESOURCES.ITEMS_IN_PROJECT)]: (state, { payload }) => {
    return storeItems(state, payload.objects)
  },
  [AT.success(PM_API_RESOURCES.MEETING_ITEMS)]: (state, { payload }) => {
    return storeItems(state, payload.objects)
  },
  [AT.success(PM_API_RESOURCES.RELATED_ITEMS)]: (state, { payload, meta }) => {
    state = storeItems(state, payload.objects)
    const relatedItems = payload.objects
    const relatedItemIDs = _.map(relatedItems, 'id')
    const { itemID } = meta
    return state.setIn([ITEMS_REDUCER_KEYS.BY_ID, itemID, itemHelper.KEYS.RELATED_ITEMS], fromJS(relatedItemIDs))
  },
  [AT.success(PM_API_RESOURCES.SEARCH)]: (state, { payload, meta }) => {
    if (meta.params.summaries === 1) {
      return storeSummaries(state, payload.objects)
    }
    return storeItems(state, payload.objects)
  },
  [AT.success(PM_API_RESOURCES.RECENTLY_VISITED_COMMENTS)]: (state, action) => {
    const { payload, meta } = action
    const { objects } = payload
    if (_.get(meta, 'params.return_item_summary')) {
      const items = _.map(objects, i => i.item_summary)
      state = storeSummaries(state, items, _.get(payload, 'meta.requested_time'))
    }
    return state
  },
  [AT.success(PM_API_RESOURCES.RECENTLY_TYPED_COMMENTS)]: (...args) =>
    handlers[AT.success(PM_API_RESOURCES.RECENTLY_VISITED_COMMENTS)](...args),
  [AT.success(PM_API_RESOURCES.ALL_COMMENTS_WITH_ITEM_SUMMARY)]: (...args) =>
    handlers[AT.success(PM_API_RESOURCES.RECENTLY_VISITED_COMMENTS)](...args),
  [AT.success(PM_API_RESOURCES.ITEM)]: (state, action) => {
    const { payload: item } = action
    const id = item.id
    state = storeSingleJSItem(state, item, true)
    const access = state.getIn([ITEMS_REDUCER_KEYS.ACCESS_DENIED, id])
    if (access) {
      state = state.setIn([ITEMS_REDUCER_KEYS.ACCESS_DENIED, id], false)
    }
    return state
  },
  [AT.success(PM_API_RESOURCES.ITEMS)]: (state, action) => {
    const { payload: items } = action
    for (const item of items) {
      const id = item.id
      state = storeSingleJSItem(state, item, true)
      const access = state.getIn([ITEMS_REDUCER_KEYS.ACCESS_DENIED, id])
      if (access) {
        state = state.setIn([ITEMS_REDUCER_KEYS.ACCESS_DENIED, id], false)
      }
    }
    return state
  },
  [AT.success(PM_API_RESOURCES.ITEM_PUT)]: (state, action) => {
    const { payload: item, meta } = action
    const { hash } = meta
    const id = item.id
    const path = [ITEMS_REDUCER_KEYS.MODIFIED, id]
    const modified = state.getIn(path)
    if (modified && modified.hashCode() === hash) {
      state = state.deleteIn(path)
    } else {
      state = state.setIn([ITEMS_REDUCER_KEYS.BY_ID, id, itemHelper.KEYS.VERSION_ID], item.version_id)
    }
    return handlers[AT.success(PM_API_RESOURCES.ITEM)](state, action)
  },
  [AT.success(PM_API_RESOURCES.ITEMS_PUT)]: (state, action) => {
    const { payload: items, meta } = action
    const { hashes } = meta
    for (const item of items) {
      const id = item.id
      const path = [ITEMS_REDUCER_KEYS.MODIFIED, id]
      const modified = state.getIn(path)
      const hash = hashes.get(id)
      if (modified && modified.hashCode() === hash) {
        state = state.deleteIn(path)
      } else {
        state = state.setIn([ITEMS_REDUCER_KEYS.BY_ID, id, itemHelper.KEYS.VERSION_ID], item.version_id)
      }
    }

    return handlers[AT.success(PM_API_RESOURCES.ITEMS)](state, action)
  },
  [AT.success(PM_API_RESOURCES.ITEM_POST)]: (...args) => handlers[AT.success(PM_API_RESOURCES.ITEM)](...args),
  [AT.success(PM_API_RESOURCES.ITEMS_POST)]: (...args) => handlers[AT.success(PM_API_RESOURCES.ITEMS)](...args),
  [AT.success(PM_API_RESOURCES.INVITE_FOLLOWER_TO_ITEM)]: (state, action) => {
    const { payload, meta } = action
    const followers = payload.objects
    const { itemID } = meta
    let item = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, itemID])
    item = item.set(itemHelper.KEYS.FOLLOWERS, fromJS(followers))
    state = state.setIn([ITEMS_REDUCER_KEYS.BY_ID, itemID], item)
    return state
  },
  [AT.success(PM_API_RESOURCES.UNINVITE_FOLLOWER_TO_ITEM)]: (...args) =>
    handlers[AT.success(PM_API_RESOURCES.INVITE_FOLLOWER_TO_ITEM)](...args),
  [AT.success(PM_API_RESOURCES.TAG_ADD)]: (state, { meta }) => {
    const { itemID, tag } = meta
    if (itemID) {
      state = addTag(state, itemID, tag)
    }
    return state
  },
  [AT.success(PM_API_RESOURCES.TAG_REMOVE)]: (state, { meta }) => {
    const { itemID, tag } = meta
    if (itemID) {
      state = removeTag(state, itemID, tag)
    }
    return state
  },
  [AT.success(PM_API_RESOURCES.POST_FILE)]: (state, action) => {
    const { payload, meta } = action
    const { itemID, fileID } = meta
    const files = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, itemID, itemHelper.KEYS.FILES])
    const filteredFiles = fileID ? files.filterNot(f => fileHelper.getID(f) === fileID) : files
    const newFiles = filteredFiles.push(fromJS(payload))
    state = state.setIn([ITEMS_REDUCER_KEYS.BY_ID, itemID, itemHelper.KEYS.FILES], newFiles)
    return state
  },
  [AT.success(PM_API_RESOURCES.DELETE_FILE)]: (state, action) => {
    const { meta } = action
    const { itemID, fileID } = meta
    const files = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, itemID, itemHelper.KEYS.FILES])
    const newFiles = files.filterNot(f => fileHelper.getID(f) === fileID)
    state = state.setIn([ITEMS_REDUCER_KEYS.BY_ID, itemID, itemHelper.KEYS.FILES], newFiles)
    return state
  },
  [AT.success(PM_API_RESOURCES.PROJECT_PUT)]: (state, { payload: project }) => {
    return deleteItemsInDeletedProject(state, project)
  },
  [AT.success(PM_API_RESOURCES.FOLLOWED_ITEMS)]: (state, { payload }) => {
    const idSet = payload.ids
    return state.withMutations(_st => {
      _.each(idSet, id => {
        if (_st.hasIn([ITEMS_REDUCER_KEYS.BY_ID, id])) {
          _st.setIn([ITEMS_REDUCER_KEYS.BY_ID, id, itemHelper.KEYS.FOLLOWING], true)
        }
      })
      return _st
    })
  },
  [AT.error(PM_API_RESOURCES.ITEM)]: (state, { payload, meta }) => {
    const { status } = payload
    const codes = [401, 403, 404, 410]
    if (_.findIndex(codes, Fns.equals(status)) >= 0) {
      const { itemID } = meta
      state = state.setIn([ITEMS_REDUCER_KEYS.ACCESS_DENIED, itemID], true)
    }
    return state
  },
  [AT.success(PM_API_RESOURCES.REMINDER_POST)]: (state, { payload: reminder }) => {
    const itemID = resourceURIParser(reminder.item).id
    let item = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, itemID])

    let mapedReminder = Map(reminder)
    mapedReminder = mapedReminder.set(reminderHelper.KEYS.REMINDER_CREATION_DATE, _.toInteger(reminder.creation_date))
    mapedReminder = mapedReminder.set(reminderHelper.KEYS.TIMESTAMP, _.toInteger(reminder.timestamp))

    item = item.set(itemHelper.KEYS.REMINDER, mapedReminder)
    return state.setIn([ITEMS_REDUCER_KEYS.BY_ID, itemID], item)
  },
  [AT.success(PM_API_RESOURCES.REMINDER_PUT)]: (state, { payload: reminder }) => {
    const itemID = resourceURIParser(reminder.item).id
    let item = state.getIn([ITEMS_REDUCER_KEYS.BY_ID, itemID])

    let mapedReminder = Map(reminder)
    mapedReminder = mapedReminder.set(reminderHelper.KEYS.REMINDER_CREATION_DATE, _.toInteger(reminder.creation_date))
    mapedReminder = mapedReminder.set(reminderHelper.KEYS.TIMESTAMP, _.toInteger(reminder.timestamp))

    item = item.set(itemHelper.KEYS.REMINDER, mapedReminder)
    state = state.setIn([ITEMS_REDUCER_KEYS.BY_ID, itemID], item)
    return state
  },
  [PERSIST_REHYDRATE_ACTION_TYPE]: (state, { payload }) => {
    if (payload.items) {
      state = state.merge(payload.items)
    }
    return state
  },
  [GENERIC_ACTION_TYPE.CLEAR_ALL]: () => base,
  [WS_ACTIONS.ITEM_RECEIVED]: (state, { payload: item }) => {
    state = computeAttention(state, item)
    state = storeSingleJSItem(state, item, true)
    return state
  },
  [COMBINED_API_ACTIONS.MARK_ITEM_AS_MODIFIED]: (state, { payload: item }) => {
    return state.setIn([ITEMS_REDUCER_KEYS.MODIFIED, itemHelper.getId(item)], item)
  },
  [COMBINED_API_ACTIONS.MARK_ITEM_AS_MODIFIED_IN_MODEL]: (state, { payload: item }) => {
    const id = itemHelper.getId(item)
    state = state.setIn([ITEMS_REDUCER_KEYS.MODIFIED, id], item)
    state = state.setIn([ITEMS_REDUCER_KEYS.BY_ID, id], item)
    return state
  },
  [COMBINED_API_ACTIONS.MARK_ITEMS_AS_MODIFIED_IN_MODEL]: (state, { payload: items }) => {
    for (const item of items) {
      const id = itemHelper.getId(item)
      state = state.setIn([ITEMS_REDUCER_KEYS.MODIFIED, id], item)
      state = state.setIn([ITEMS_REDUCER_KEYS.BY_ID, id], item)
    }
    return state
  },
  [COMBINED_API_ACTIONS.REMOVE_ITEM_FROM_MODIFIED]: (state, { payload: item }) => {
    const id = itemHelper.getId(item)
    const path = [ITEMS_REDUCER_KEYS.MODIFIED, id]
    const existingItem = state.getIn(path)
    if (is(item, existingItem)) {
      state = state.deleteIn(path)
    }
    return state
  },
  [ITEMS_ACTIONS.MARK_ITEMS_AS_READ]: markItemsRead,
  [ITEMS_ACTIONS.MARK_ITEMS_AS_READ_LOCAL]: markItemsRead,
}

export const items = (state = base, action) => {
  const fn = handlers[action.type]
  if (fn) {
    state = fn(state, action)
  }
  return state
}

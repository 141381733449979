import { useEffect } from 'react'
import _ from 'lodash'
import { itemHelper, projectHelper, meetingHelper } from '../../common/src/helpers'
import { manageItem, manageProject } from '../../helpers/manageObjectHelper'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { APP_NAME } from '../../constants/constants'
import { ROUTE_ID } from '../../routes/routeIdList'
import { getSelectedMeetingId } from '../../selectors/uiSelectors'
import { useTranslation } from 'react-i18next'
import { meetingsSelectors } from '../../common/src/selectors'
import { useSelectedItem, useSelectedItemId, useSelectedProject, useSelectedProjectId } from '../../hooks/PMHooks'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { useHistory } from 'react-router'
import { RELATIVE_URL_BY_ROUTE_ID } from '../../helpers/routeHelper'
import { addItemIdToRecentlyVisited } from '../../actions/uiActions'
import { getSentry } from '@/getSentry'

const separator = ' - '
const documentTitleForAppViews =
  ({ nameForEmpty, showName = true }) =>
  ({ projectName, itemName, meetingName, t } = {}) => {
    const defaultComponent = showName ? t(nameForEmpty) + separator + APP_NAME : APP_NAME
    const noProjectAndItemComponent =
      !showName && !projectName && !itemName && !meetingName
        ? t(nameForEmpty) + separator + defaultComponent
        : defaultComponent
    const projectComponent = projectName
      ? projectName + separator + noProjectAndItemComponent
      : noProjectAndItemComponent
    return itemName && !meetingName
      ? itemName + separator + projectComponent
      : meetingName
        ? meetingName + separator + projectComponent
        : projectComponent
  }

const prefix = 'window_title'
const DOCUMENT_TITLE = {
  DEFAULT: documentTitleForAppViews({ showName: false }),
  VERSION: documentTitleForAppViews({ nameForEmpty: `${prefix}.version` }),
  ONE_ON_ONE: documentTitleForAppViews({ nameForEmpty: `${prefix}.one_on_one` }),
  SEARCH: documentTitleForAppViews({ nameForEmpty: `${prefix}.search` }),
  PROJECT: documentTitleForAppViews({ nameForEmpty: `${prefix}.project`, showName: false }),
  ITEM: documentTitleForAppViews({ nameForEmpty: `${prefix}.item`, showName: false }),
  PROJECTS: documentTitleForAppViews({ nameForEmpty: `${prefix}.projects` }),
  MATRIX: documentTitleForAppViews({ nameForEmpty: `${prefix}.matrix` }),
  LIST: documentTitleForAppViews({ nameForEmpty: `${prefix}.list` }),
  CALENDAR: documentTitleForAppViews({ nameForEmpty: `${prefix}.calendar` }),
  REPORTS: documentTitleForAppViews({ nameForEmpty: `${prefix}.reports` }),
  INBOX: documentTitleForAppViews({ nameForEmpty: `${prefix}.inbox` }),
  FEED: documentTitleForAppViews({ nameForEmpty: `${prefix}.feed` }),
  GLOBAL_FEED: documentTitleForAppViews({ nameForEmpty: `${prefix}.global_feed` }),
  GANTT: documentTitleForAppViews({ nameForEmpty: `${prefix}.gantt` }),
  HOME: documentTitleForAppViews({ nameForEmpty: `${prefix}.home` }),
  NEW_PROJECT: documentTitleForAppViews({ nameForEmpty: `${prefix}.new_project` }),
  BLANK_PROJECT: documentTitleForAppViews({ nameForEmpty: `${prefix}.blank_project` }),
  NEW_PROJECT_FROM_CUSTOM_TEMPLATE: documentTitleForAppViews({
    nameForEmpty: `${prefix}.new_project_from_custom_template`,
  }),
  NEW_PROJECT_FROM_PUBLIC_TEMPLATE: documentTitleForAppViews({
    nameForEmpty: `${prefix}.new_project_from_public_template`,
  }),
  NEW_CUSTOM_PROJECT_TEMPLATE: documentTitleForAppViews({ nameForEmpty: `${prefix}.new_custom_project_template` }),
  MEETING: documentTitleForAppViews({ nameForEmpty: `${prefix}.meeting` }),
  GROWTH: documentTitleForAppViews({ nameForEmpty: `${prefix}.growth` }),
  EFFORT_PLANNING: documentTitleForAppViews({ nameForEmpty: `${prefix}.workload_management` }),
}

const truncateName = name =>
  _.truncate(name, {
    length: 32,
    separator: ' ',
  })

const updateTitle =
  ({ fn, checkProject = true, checkItem = true, checkMeeting = true }) =>
  async (store, dispatch, t, { selectedMeeting, selectedItem, selectedProject }) => {
    if (!_.isFunction(fn)) {
      document.title = DOCUMENT_TITLE.DEFAULT({ t })
      return
    }

    const { error: projectIsError, payload: projectPayload } = checkProject
      ? await manageProject({
          store,
          dispatch,
          id: projectHelper.getIdd(selectedProject),
          t,
        })
      : {}
    const { error: itemIsError, payload: itemPayload } = checkItem
      ? await manageItem({
          store,
          dispatch,
          id: itemHelper.getId(selectedItem),
          t,
        })
      : {}

    const meetingIsError = !checkMeeting || !selectedMeeting
    const projectName = checkProject && !projectIsError ? projectHelper.getName(projectPayload.project) : null
    const itemName = checkItem && !itemIsError ? itemHelper.getName(itemPayload.item) : null
    const meetingName = checkMeeting && !meetingIsError ? meetingHelper.getTitle(selectedMeeting) : null

    document.title = fn({
      projectName: projectName && truncateName(projectName),
      itemName: itemName && truncateName(itemName),
      meetingName: meetingName && truncateName(meetingName),
      t,
    })
  }

const UPDATE_DOCUMENT_FUNCTIONS = {
  [ROUTE_ID.VERSION]: updateTitle({ fn: DOCUMENT_TITLE.VERSION, checkProject: false, checkItem: false }),
  [ROUTE_ID.ONE_ON_ONE]: updateTitle({ fn: DOCUMENT_TITLE.ONE_ON_ONE, checkProject: false, checkItem: false }),
  [ROUTE_ID.ONE_ON_ONE_APP]: updateTitle({ fn: DOCUMENT_TITLE.ONE_ON_ONE, checkProject: false, checkItem: false }),
  [ROUTE_ID.SEARCH]: updateTitle({ fn: DOCUMENT_TITLE.SEARCH, checkProject: false, checkItem: false }),
  [ROUTE_ID.SEARCH_APP]: updateTitle({ fn: DOCUMENT_TITLE.SEARCH, checkProject: false, checkItem: false }),
  [ROUTE_ID.SINGLE_PROJECT]: updateTitle({ fn: DOCUMENT_TITLE.PROJECT, checkItem: false }),
  [ROUTE_ID.SINGLE_ITEM]: updateTitle({ fn: DOCUMENT_TITLE.ITEM, checkProject: false }),
  [ROUTE_ID.PROJECTS]: updateTitle({ fn: DOCUMENT_TITLE.PROJECTS, checkItem: false }),
  [ROUTE_ID.LIST_APP]: updateTitle({ fn: DOCUMENT_TITLE.LIST }),
  [ROUTE_ID.LIST_ONE]: updateTitle({ fn: DOCUMENT_TITLE.LIST }),
  [ROUTE_ID.LIST_APP]: updateTitle({ fn: DOCUMENT_TITLE.LIST }),
  [ROUTE_ID.CALENDAR_READ_ONLY]: updateTitle({ fn: DOCUMENT_TITLE.CALENDAR }),
  [ROUTE_ID.CALENDAR_ONE]: updateTitle({ fn: DOCUMENT_TITLE.CALENDAR }),
  [ROUTE_ID.CALENDAR_APP]: updateTitle({ fn: DOCUMENT_TITLE.CALENDAR }),
  [ROUTE_ID.GLOBAL_CALENDAR_APP]: updateTitle({ fn: DOCUMENT_TITLE.CALENDAR }),
  [ROUTE_ID.GLOBAL_REPORTS_APP]: updateTitle({ fn: DOCUMENT_TITLE.REPORTS }),
  [ROUTE_ID.MATRIX_READ_ONLY]: updateTitle({ fn: DOCUMENT_TITLE.MATRIX }),
  [ROUTE_ID.MATRIX_ONE]: updateTitle({ fn: DOCUMENT_TITLE.MATRIX }),
  [ROUTE_ID.MATRIX_APP]: updateTitle({ fn: DOCUMENT_TITLE.MATRIX }),
  [ROUTE_ID.FEED_ONE]: updateTitle({ fn: DOCUMENT_TITLE.FEED }),
  [ROUTE_ID.FEED_APP]: updateTitle({ fn: DOCUMENT_TITLE.FEED }),
  [ROUTE_ID.GLOBAL_FEED_APP]: updateTitle({ fn: DOCUMENT_TITLE.GLOBAL_FEED, checkProject: false }),
  [ROUTE_ID.FEED_APP]: updateTitle({ fn: DOCUMENT_TITLE.FEED }),
  [ROUTE_ID.GANTT_READ_ONLY]: updateTitle({ fn: DOCUMENT_TITLE.GANTT }),
  [ROUTE_ID.GANTT_ONE]: updateTitle({ fn: DOCUMENT_TITLE.GANTT }),
  [ROUTE_ID.GANTT_APP]: updateTitle({ fn: DOCUMENT_TITLE.GANTT }),
  [ROUTE_ID.INBOX]: updateTitle({ fn: DOCUMENT_TITLE.INBOX, checkProject: false }),
  [ROUTE_ID.PROJECT_APP]: updateTitle({ fn: DOCUMENT_TITLE.PROJECT, checkItem: false }),
  [ROUTE_ID.ITEM_APP]: updateTitle({ fn: DOCUMENT_TITLE.ITEM, checkProject: false }),
  [ROUTE_ID.HOME]: updateTitle({ fn: DOCUMENT_TITLE.HOME, checkProject: false }),
  [ROUTE_ID.NEW_PROJECT_APP]: updateTitle({ fn: DOCUMENT_TITLE.NEW_PROJECT, checkProject: false, checkItem: false }),
  [ROUTE_ID.BLANK_PROJECT_APP]: updateTitle({
    fn: DOCUMENT_TITLE.BLANK_PROJECT,
    checkProject: false,
    checkItem: false,
  }),
  [ROUTE_ID.NEW_PROJECT_FROM_CUSTOM_TEMPLATE_APP]: updateTitle({
    fn: DOCUMENT_TITLE.NEW_PROJECT_FROM_CUSTOM_TEMPLATE,
    checkProject: false,
    checkItem: false,
  }),
  [ROUTE_ID.NEW_PROJECT_FROM_PUBLIC_TEMPLATE_APP]: updateTitle({
    fn: DOCUMENT_TITLE.NEW_PROJECT_FROM_PUBLIC_TEMPLATE,
    checkProject: false,
    checkItem: false,
  }),
  [ROUTE_ID.NEW_CUSTOM_PROJECT_TEMPLATE_APP]: updateTitle({
    fn: DOCUMENT_TITLE.NEW_CUSTOM_PROJECT_TEMPLATE,
    checkProject: false,
    checkItem: false,
  }),
  [ROUTE_ID.GROWTH]: updateTitle({
    fn: DOCUMENT_TITLE.GROWTH,
    checkProject: false,
    checkItem: false,
  }),
  [ROUTE_ID.MEETING]: updateTitle({ fn: DOCUMENT_TITLE.MEETING, checkProject: false, checkItem: false }),
  [ROUTE_ID.EFFORT_PLANNING]: updateTitle({ fn: DOCUMENT_TITLE.EFFORT_PLANNING }),
}

const ITEM_MOBILE_ROUTE_IDs = new Set([
  ROUTE_ID.ITEM_APP,
  ROUTE_ID.SINGLE_ITEM,
  ROUTE_ID.SINGLE_ITEM_READ_ONLY,
  ROUTE_ID.REDIRECT,
])

/// Redirect to single item view if on narrow mode, item is selected and user opened a desktop link
const useRedirectMobileToSingleItem = routeId => {
  const itemID = useSelectedItemId()
  const projectID = useSelectedProjectId()
  const narrow = useNarrowWidth()
  const history = useHistory()
  useEffect(() => {
    if (itemID && narrow && routeId && !ITEM_MOBILE_ROUTE_IDs.has(routeId)) {
      const fn = RELATIVE_URL_BY_ROUTE_ID[routeId]
      if (!fn) {
        getSentry().captureException(new Error('Missing routeId'), {
          extra: {
            routeId,
          },
        })
      }
      history.replace(RELATIVE_URL_BY_ROUTE_ID[routeId]?.(projectID))
      history.push(RELATIVE_URL_BY_ROUTE_ID[ROUTE_ID.ITEM_APP]?.(itemID))
    }
  }, [itemID, narrow, routeId, history, projectID])
}

const useAddItemToRecentlyVisited = () => {
  const dispatch = useDispatch()
  const itemID = useSelectedItemId()
  useEffect(() => void dispatch(addItemIdToRecentlyVisited(itemID)), [dispatch, itemID])
}

export const RouteListener = ({ routeId }) => {
  const store = useStore()
  const state = store.getState()
  const dispatch = useDispatch()
  // Apply effect in case item or project change
  const selectedProject = useSelectedProject()
  const selectedItem = useSelectedItem()

  const meetingID = getSelectedMeetingId(state)
  const meetingSelector = meetingsSelectors.getMeeting
  const selectedMeeting = useSelector(meetingSelector(meetingID))

  useRedirectMobileToSingleItem(routeId)
  useAddItemToRecentlyVisited()

  const { t } = useTranslation()

  useEffect(() => {
    const updateDocumentFunction = UPDATE_DOCUMENT_FUNCTIONS[routeId]
    if (updateDocumentFunction) {
      updateDocumentFunction(store, dispatch, t, { selectedItem, selectedMeeting, selectedProject })
    }
  }, [routeId, selectedProject, selectedItem, selectedMeeting, store, dispatch, t])

  return false
}

/**
 *  Utils to interact with immutable items
 **/
import _ from 'lodash'
import { fromJS, List } from 'immutable'
import { ITEM_KEYS as KEYS } from '../itemKeys'
import { pickPredicate, safeMapFnCreator } from './immutableHelpers'
import { getAvatarURIForEmail, getIconURL, getProtocolLinkForItemWithId, PM_API_RESOURCE_TYPE } from '../constants'
import { resourceURICreator, resourceURIParser, uriDataCreator, URLParser } from './URLHelper'
import { allDayDateStringRelativeToToday, toDate } from './dateHelper'
import userHelper from './userHelper'
import tagHelper from './tagHelper'
import { intlFormatDistance, isBefore } from 'date-fns'

let helper = {}

helper.KEYS = KEYS

helper.STATE = {
  UNFINISHED: 0,
  DONE: 1,
  TRASHED: 2,
  DELETED: 3,
}

helper.FREQUENCY_TYPE = {
  NEVER: 'Never',
  DAILY: 'Daily',
  WEEKDAYS: 'Weekdays',
  WEEKLY: 'Weekly',
  BIWEEKLY: 'Biweekly',
  FOUR_WEEKLY: '4-weekly',
  MONTHLY: 'Monthly',
  BIMONTHLY: '2-monthly',
  QUARTERLY: 'Quarterly',
  SEMESTERLY: 'Semesterly',
  YEARLY: 'Yearly',
  FIXED_WEEK: 'Fixed Weeks',
}

helper.EFFORT_KEYS = {
  NO_EFFORT: 0,
  ONE_HOUR: 1,
  TWO_HOURS: 2,
  HALF_A_DAY: 3,
  ONE_DAY: 4,
  HALF_A_WEEK: 5,
  ONE_WEEK: 6,
  TWO_WEEKS: 7,
  ONE_MONTH: 8,
  SEVERAL_MONTHS: 9,
}

helper.DEFAULT_ESTIMATED_EFFORT = {
  [helper.EFFORT_KEYS.NO_EFFORT]: 0,
  [helper.EFFORT_KEYS.ONE_HOUR]: 60,
  [helper.EFFORT_KEYS.TWO_HOURS]: 120,
  [helper.EFFORT_KEYS.HALF_A_DAY]: 240,
  [helper.EFFORT_KEYS.ONE_DAY]: 480,
  [helper.EFFORT_KEYS.HALF_A_WEEK]: 1200,
  [helper.EFFORT_KEYS.ONE_WEEK]: 2400,
  [helper.EFFORT_KEYS.TWO_WEEKS]: 4800,
  [helper.EFFORT_KEYS.ONE_MONTH]: 9600,
  [helper.EFFORT_KEYS.SEVERAL_MONTHS]: 19200,
}

helper.isItem = o => {
  // Item summaries do not have resource URI
  // So... let's check this property to check if it's item 🤪
  return _.isNumber(o.get(KEYS.QUADRANT))
}

helper.belongsToProjectWithId = (item, id) => {
  const URI = helper.getProjectURI(item)
  if (!id && !URI) {
    //inbox
    return true
  }
  //need to compare id, since api version may be different
  return id && URI && resourceURIParser(URI).id === id
}

helper.belongsToProject = (item, projectUri) => {
  const id = projectUri && resourceURIParser(projectUri).id
  return helper.belongsToProjectWithId(item, id)
}

helper.needsAttention = (i, meEmail) => {
  const editedByUsername = i.get(helper.KEYS.EDITED_BY_USERNAME)
  if (!helper.isOwnerOrFollower(i, meEmail) || (editedByUsername === meEmail && _.isEmpty(helper.getReminder(i)))) {
    return false
  }
  const lastRead = helper.getLastRead(i)
  const lastAttention = helper.getLastAttention(i)
  if (lastAttention === void 0 && lastRead === void 0) {
    //summary
    return false
  }
  return !lastRead || lastRead < lastAttention
}

helper.quickNeedsAttention = i => {
  const lastRead = helper.getLastRead(i)
  const lastAttention = helper.getLastAttention(i)
  if (lastAttention === void 0 && lastRead === void 0) {
    return false
  }
  return !lastRead || lastRead < lastAttention
}

helper.showInBold = i => {
  const lastRead = helper.getLastRead(i) || 0
  const lastAttention = helper.getLastAttention(i)
  return lastAttention && lastRead < lastAttention
}

helper.isRecurring = item => {
  const freq = helper.getFrequency(item)
  return freq !== helper.FREQUENCY_TYPE.NEVER
}

helper.getFrequency = item => {
  const freq = item.get(KEYS.FREQUENCY)
  if (!freq) {
    return helper.FREQUENCY_TYPE.NEVER
  }
  return freq
}

helper.getProjectURI = i => i.getIn([KEYS.PROJECTS, 0])

helper.getProjectIdd = i => {
  const projectURI = helper.getProjectURI(i)
  const r = resourceURIParser(projectURI)
  const idd = r && r.id
  return idd ? idd : 0
}
// This method is only for normal inbox item.
// If you want to check if item is from the inbox plus
// you need to use 'isInboxPlusItem' from stateHelper.
helper.isInbox = i => helper.getProjectIdd(i) <= 0

helper.getEditedByUsername = i => i.get(KEYS.EDITED_BY_USERNAME)
helper.getId = i => i.get(KEYS.ID)
helper.getVersionID = i => i.get(KEYS.VERSION_ID)
helper.getIndex = i => i.get(KEYS.INDEX)
helper.getName = i => i.get(KEYS.NAME)
helper.getNotes = i => i.get(KEYS.DESCRIPTION_TEXT)
helper.getOwnerUsername = i => i.get(KEYS.OWNER_USERNAME)
helper.getCreatorUsername = i => i.get(KEYS.CREATOR_USERNAME)
helper.getOwnerAvatar = i => getAvatarURIForEmail(helper.getOwnerUsername(i))
helper.getQuadrant = i => i.get(KEYS.QUADRANT)
helper.getEffort = i => i.get(KEYS.EFFORT)
helper.getEstimatedEffort = i => i.get(KEYS.ESTIMATED_EFFORT)
helper.getRealizedEffort = i => i.get(KEYS.REALIZED_EFFORT)
helper.getResourceURI = i => i.get(KEYS.RESOURCE_URI) || helper.generateResourceUri(i)
helper.getState = i => i.get(KEYS.STATE)
helper.getMacResourceURL = i => i.get(KEYS.MAC_RESOURCE_URL)
helper.getLastCommentID = i => i.get(KEYS.LAST_COMMENT_ID)
helper.getLastRead = i => i.get(KEYS.LAST_READ)
helper.getLastAttention = i => i.get(KEYS.LAST_ATTENTION)
helper.getReminder = i => i.get(KEYS.REMINDER)
helper.getLinkedResources = i => i.get(KEYS.LINKED_RESOURCES)
helper.getPublicLink = i => i.get(KEYS.PUBLIC_LINK)

helper.prepareItemForPut = i => {
  const obj = i.filter(pickPredicate(...PUT_WHITELIST)).toJS()
  return JSON.stringify(obj)
}

helper.prepareItemsForPut = items => {
  const obj = items.map(item => item.filter(pickPredicate(...PUT_WHITELIST)).toJS())
  return JSON.stringify(obj)
}

helper.getFiles = i => {
  const files = i.get(KEYS.FILES)
  return files ? files : List()
}

helper.getTimestamp = (i, type = KEYS.TIMESTAMP) => {
  const timestamp = i.get(type)
  return timestamp ? timestamp : 0
}

helper.getLastModifiedTimestamp = i => helper.getTimestamp(i, KEYS.LAST_MODIFIED_TIMESTAMP)
helper.getCreationTimestamp = i => helper.getTimestamp(i, KEYS.CREATION_DATE)
helper.getCompletionTimestamp = i => helper.getTimestamp(i, KEYS.COMPLETION_DATE)
helper.getStartTimestamp = i => helper.getTimestamp(i, KEYS.START_DATE)
helper.getDueTimestamp = i => helper.getTimestamp(i, KEYS.DUE_DATE)
helper.getUntilTimestamp = i => helper.getTimestamp(i, KEYS.UNTIL_DATE)

helper.getLastModifiedTimestampDate = i => toDate(helper.getLastModifiedTimestamp(i))
helper.getCreationDate = i => toDate(helper.getCreationTimestamp(i))
helper.getCompletionDate = i => toDate(helper.getCompletionTimestamp(i))
helper.getStartDate = i => toDate(helper.getStartTimestamp(i))
helper.getDueDate = i => toDate(helper.getDueTimestamp(i))
helper.getUntilDate = i => toDate(helper.getUntilTimestamp(i))
helper.getTimestampDate = i => toDate(helper.getTimestamp(i))

helper.getAllDay = i => i.get(KEYS.ALL_DAY)
helper.isAllDay = helper.getAllDay

helper.isCompletedOnTime = i => {
  const due = i.get(KEYS.DUE_DATE)
  const completionDate = i.get(KEYS.COMPLETION_DATE)
  return due && completionDate && completionDate < due
}

helper.isOneOnOneFor = (i, e1, e2) => {
  return (helper.isOwner(i, e1) && helper.isFollower(i, e2)) || (helper.isOwner(i, e2) && helper.isFollower(i, e1))
}

helper.isOwner = (i, e) => _.toLower(i.get(KEYS.OWNER_USERNAME)) === e

helper.getFollowers = (i, sortByEmail = true) => {
  const followers = i.get(KEYS.FOLLOWERS)
  if (!followers) {
    return
  }
  return sortByEmail
    ? followers.sortBy(follower => {
        return userHelper.getEmail(follower)
      })
    : followers
}
helper.isFollower = (i, e) => {
  const followers = helper.getFollowers(i, false)
  return followers && followers.some(f => _.toLower(f.get('email')) === e)
}
helper.isOwnerOrFollower = (i, e) => helper.isOwner(i, e) || helper.isFollower(i, e)
helper.getFollowing = i => i.get(KEYS.FOLLOWING)

helper.EMPTY_ICON = 'empty_orange128.png'
helper.EMPTY_ICON_WITHOUT_EXTENSION = _.trimEnd(helper.EMPTY_ICON, '.png')
helper.getIconName = i => i.get(KEYS.ICON)
helper.getIconNameSafely = i => {
  const iconName = helper.getIconName(i)
  return iconName || helper.EMPTY_ICON
}
helper.getIconNameSafelyWithoutExtension = i => {
  return _.trimEnd(helper.getIconNameSafely(i), '.png')
}

helper.getIconURL = i => {
  const icon = helper.getIconName(i)
  if (icon && icon !== helper.EMPTY_ICON) {
    return getIconURL(icon)
  } else {
    return void 0
  }
}

helper.setProjectURI = (i, URI) => {
  return i.set('projects', fromJS(URI ? [URI] : []))
}

helper.setProjectIdd = (i, idd) => {
  if (idd) {
    const projectURI = resourceURICreator(1, PM_API_RESOURCE_TYPE.PROJECT, idd)
    return helper.setProjectURI(i, projectURI)
  }
  return helper.setProjectURI(i)
}

helper.getUriData = i => uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, helper.getId(i))
helper.generateResourceUri = i => resourceURICreator(1, PM_API_RESOURCE_TYPE.ITEM, helper.getId(i))

helper.getProtocolLink = i => getProtocolLinkForItemWithId(helper.getId(i))

helper.getCompletionPercentage = i => i.get(KEYS.COMPLETION_PERCENTAGE)
helper.isCompleted = i => helper.getCompletionPercentage(i) === 100

helper.isDeleted = i => i.get(KEYS.STATE) > helper.STATE.DONE

helper.getTags = i => {
  const tags = i.get(KEYS.TAGS)
  if (!tags) {
    return List()
  }
  const uniqueTags = tags
    .groupBy(tag => helper.getName(tag))
    .map(group => group.first())
    .toList()
  return uniqueTags
}

helper.isStarred = i => {
  const hasTags = i.get(KEYS.TAGS) !== undefined
  if (!hasTags) return undefined
  const tags = helper.getTags(i)
  return tags.some(t => tagHelper.getName(t) === tagHelper.STARRED_TAG_NAME)
}

helper.setQuadrant = (i, q) => {
  if (q >= 0 && q <= 3) {
    return i.set(KEYS.QUADRANT, q)
  }
  throw new Error('Wrong quadrant number')
}

//default mode is desc
helper.setIndexBetweenItems = (i, prev, next) => {
  const prevIdx = prev && prev.get(KEYS.INDEX)
  const nextIdx = next && next.get(KEYS.INDEX)
  if (prev && next) {
    //between
    const idx = _.toInteger((prevIdx + nextIdx) / 2)
    return i.set(KEYS.INDEX, idx)
  } else if (prev) {
    //last
    const idx = prevIdx - 1000
    return i.set(KEYS.INDEX, idx)
  } else if (next) {
    //first
    const idx = nextIdx + 1000
    return i.set(KEYS.INDEX, idx)
  }
  return i.set(KEYS.INDEX, 0)
}

// Notifications

helper.checkCompletionNotification = (i, oldItem, currentUserEmail) => {
  return helper.isCompleted(i) && !helper.isCompleted(oldItem) && i.get(KEYS.COMPLETED_BY_USERNAME) !== currentUserEmail
}

helper.checkDelegationNotification = (i, oldItem, currentUserEmail) => {
  const currentOwnerUsername = i.get(KEYS.OWNER_USERNAME)
  return (
    currentOwnerUsername !== oldItem.get(KEYS.OWNER_USERNAME) &&
    currentOwnerUsername === currentUserEmail &&
    i.get(KEYS.DELEGATED_BY_USERNAME) !== currentUserEmail
  )
}

helper.checkCommentNotification = (i, oldItem) => {
  const oldCommentID = helper.getLastCommentID(oldItem)
  const commentID = helper.getLastCommentID(i)
  return commentID > oldCommentID
}

helper.checkCommentNotificationWithComment = (i, comment, currentUserEmail) => {
  const commentAuthor = comment['author_email']
  if (commentAuthor === currentUserEmail) {
    return false
  }

  const lastRead = helper.getLastRead(i)
  const commentDate = toDate(comment.timestamp)
  const commentTimestamp = commentDate ? commentDate.getTime() / 1000 : 0
  return commentTimestamp > lastRead
}

helper.hasUnreadMention = i => i.get(KEYS.HAS_UNREAD_MENTION)

helper.getNotification = i => i.get(KEYS.NOTIFICATION)

// UI
helper.getDueTextFromNow = i => {
  const due = helper.getDueDate(i)
  if (!due) {
    return null
  }
  if (helper.isAllDay(i)) {
    return allDayDateStringRelativeToToday(due)
  }
  return intlFormatDistance(due, new Date(), {
    locale: navigator.language,
  })
}

helper.getDueColor = (i, positiveColor, negativeColor) => {
  const due = helper.getDueDate(i)
  if (!due) {
    return null
  }
  if (helper.isCompletedOnTime(i)) {
    return positiveColor
  } else if (!helper.isCompleted(i) && isBefore(due, new Date())) {
    return negativeColor
  }
  return null
}

helper.getRecurrence = i => i.get(KEYS.RECURRENCE)
helper.getChildId = i => i.get(KEYS.CHILD_ID)

helper.computeLinkedProjectDashboard = i => {
  const macResourceURL = helper.getMacResourceURL(i)
  const parsedLink = URLParser(macResourceURL)
  const type = parsedLink && parsedLink.type
  const linkIsProject = type === 'project' || type === 'matrix'
  const linkProjectId = linkIsProject && parsedLink && parsedLink.id
  return linkProjectId || undefined
}

helper.getLinkedProjectDashboard = i => i.get(KEYS.LINKED_PROJECT_DASHBOARD)

helper = _.mapValues(helper, f => (_.isFunction(f) ? safeMapFnCreator(f) : f))

helper.createFromRaw = item => {
  let localItem = fromJS(item)
  const linkedProjectDashboard = helper.computeLinkedProjectDashboard(localItem)
  localItem = localItem.set(helper.KEYS.LINKED_PROJECT_DASHBOARD, linkedProjectDashboard)
  return localItem
}

export default helper

const PUT_WHITELIST = [
  'id',
  'idd',
  'requested_time',
  'owner_username',
  'projects',
  'version_id',
  'completed_by_username',
  'name',
  'descriptionText',
  'quadrant',
  'index',
  'icon',
  'state',
  'completionPercentage',
  'effort',
  'frequency',
  'childID',
  'parentID',
  'edited_by',
  'allDay',
  'editedByDevice',
  'macResourceURL',
  'copiedFromID',
  'creationDate',
  'dueDate',
  'startDate',
  'untilDate',
  'completionDate',
]

import _ from 'lodash'
import { useMemo, Suspense, useCallback } from 'react'
import { ShimmerVirtualizedList } from '../../../components/list/ShimmerVirtualizedList'
import { Loading } from '../../../components/basic/Loading'
import { ProjectTemplateCell } from './ProjectTemplateCell'
import { useRandomValueFromArray } from '../../../hooks/arrayHooks'
import { mergeStyleSets } from '@fluentui/react/lib/Styling'
import { GhostProjectTemplateCell } from './GhostProjectTemplateCell'
import { PROJECT_TEMPLATE_TYPE } from '../ProjectTemplateType'
import { useTranslation } from 'react-i18next'
import { LazyIViewPlaceholder } from '../../../components/placeholder/LazyIViewPlaceholder'
import { cn } from '@/modules/classnames'
import { useCollaboratorsMap } from '@/common/src/hooks/usersHooks'

const classNames = {
  container: 'ProjectTemplateList-container',
}

const getClassNames = _.memoize(() => {
  return mergeStyleSets({
    container: [
      classNames.container,
      {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        padding: '8px',
      },
    ],
  })
})

export const ProjectTemplateList = ({
  type = PROJECT_TEMPLATE_TYPE.CUSTOM,
  templates,
  onSelect,
  loading,
  onDelete,
  className,
  endReached = undefined,
  increaseViewportBy = undefined,
}) => {
  const { t } = useTranslation()
  const collaboratorsMap = useCollaboratorsMap()

  const createOnClick = useCallback(
    template => () => {
      onSelect({ type, template })
    },
    [onSelect, type]
  )

  const rowRenderer = useCallback(
    index => {
      const template = templates[index]
      const creator = collaboratorsMap.get(template.creator_username)
      return (
        <ProjectTemplateCell
          creator={creator}
          type={type}
          template={template}
          onClick={createOnClick(template)}
          onDelete={onDelete}
        />
      )
    },
    [templates, type, createOnClick, onDelete, collaboratorsMap]
  )

  const placeholders = useMemo(() => {
    return {
      title: t('project_template.placeholder_0.title'),
      message: t('project_template.placeholder_0.message'),
    }
  }, [t])

  const placeholder = useRandomValueFromArray(placeholders)
  const noRowsRenderer = useCallback(() => {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }, [placeholder])

  const classNames = getClassNames()
  return (
    <div className={cn(classNames.container, className)}>
      <ShimmerVirtualizedList
        rows={templates}
        rowRenderer={rowRenderer}
        noRowsRenderer={noRowsRenderer}
        loading={loading}
        shimmerComponent={GhostProjectTemplateCell}
        computeItemKey={(idx, template) => template?.id ?? `idx-${idx}`}
        endReached={endReached}
        increaseViewportBy={increaseViewportBy}
      />
    </div>
  )
}

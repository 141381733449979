import { itemHelper, stateHelper } from '../common/src/helpers'
import { getItemIfNeeded, sendItems } from '../common/src/actions/combinedAPI'
import { AMPLITUDE_ACTION_TYPES, EVENT_EXTRA, dispatchEvent } from '../common/src/eventTracking/amplitudeEvents'
import { useContext, useEffect } from 'react'
import { ItemLinkPanelContext, AlertModalContext, MoveItemModalContext, MoveItemsModalContext } from '../contexts'
import { useDispatch, useSelector } from 'react-redux'
import {
  useAdjustPathnameOnDeletedItem,
  useCopyLink,
  useCreateLinkedItem,
  useDuplicateItem,
  useFollowItem,
  useMarkCompleted,
  useTriggerItemDeletionModal,
} from './itemHooks'
import { useTranslation } from 'react-i18next'
import { copyToClipboard } from '../common/src/helpers/clipboardUtils'
import { getCSVExportSelectionURL, getItemLink } from '../common/src/constants'
import { useToastController } from '@fluentui/react-components'
import { addStarredTag, removeStarredTag } from '../common/src/actions/tagsAPI'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { linkSubject } from '../reactions/linkSubject'
import { LINK_PANEL_ACTION_TYPES } from '../components/panels/item/ItemLinkPanelSingleton'
import { getMultipleSelectedItems, isItemMultipleSelectionOn } from '../selectors/uiSelectors'
import {
  clearItemMultipleSelection,
  setItemSelectedInMultipleSelection,
  setItemsSelectedInMultiSelection,
  setMultipleSelectionOn,
} from '../actions/uiActions'
import { markItemAsReadFromIDs } from '../common/src/actions/itemActions'
import { useSelectedItemId, useSelectedProject } from './PMHooks'
import { Map } from 'immutable'
import {
  Add,
  ArrowDownload,
  CalendarLtr,
  CheckboxChecked,
  CheckboxUnchecked,
  Copy,
  Delete,
  DocumentAdd,
  DocumentLink,
  Eye,
  EyeOff,
  FolderArrowRight,
  FolderLink,
  Image,
  LinkMultiple,
  Mail,
  MailAllRead,
  Money,
  Person,
  Print,
  SelectAllOn,
  Share,
  Star,
  StarOff,
  TaskListAdd,
  WindowNew,
} from '../components/BundledIcons'
import { FluentToast } from '../components/toast/FluentToast'
import { useParams } from 'react-router'
import { generateEmail } from '../utils/generateEmail'
import {
  useChangeIcon,
  useConvertItemToProjectDialog,
  useItemDatesPanel,
  useItemsSummaryDialog,
  useOwnerPanel,
  usePrintItems,
  useProjectLinkSelectorDialog,
} from '../typedContexts'
import { dateToTimestampInSeconds } from '../common/src/helpers/dateHelper.js'
import { useDownloadItemsCSV } from './useDownloadItemsCSV'
import { useAtom } from 'jotai'
import { hideConfirmationDeleteItemsAtom, hideConfirmationMarkItemsAsCompletedAtom } from '@/atoms'

export const contextualMenuKeys = {
  copy: 'copy',
  follow: 'follow',
  link: 'link',
  copyTitle: 'copyTitle',
  delete: 'delete',
  star: 'star',
  popOut: 'popOut',
  markCompleted: 'markCompleted',
  new: 'new',
  newLinked: 'newLinked',
  addLink: 'addLink',
  addLinkProject: 'addLinkProject',
  createLinkProject: 'createLinkProject',
  divider1: 'divider',
  convertToProject: 'convertToProject',
  duplicate: 'duplicate',
  move: 'move',
  deleteMultiple: 'deleteMultiple',
  markCompletedMultiple: 'markCompletedMultiple',
  markReadMultiple: 'markReadMultiple',
  moveMultiple: 'moveMultiple',
  selectThisItem: 'selectThisItem',
  selectAll: 'selectAll',
  multipleExportAsCSV: 'multipleExportAsCSV',
  mailMultiple: 'mailMultiple',
  printMultiple: 'printMultiple',
  iconMultiple: 'iconMultiple',
  datesMultiple: 'datesMultiple',
  ownerMultiple: 'ownerMultiple',
  multipleSummary: 'multipleSummary',
}

const singleKeyOrder = [
  contextualMenuKeys.markCompleted,
  contextualMenuKeys.copy,
  contextualMenuKeys.move,
  contextualMenuKeys.duplicate,
  contextualMenuKeys.convertToProject,
  contextualMenuKeys.addLink,
  contextualMenuKeys.addLinkProject,
  contextualMenuKeys.popOut,
  contextualMenuKeys.star,
  contextualMenuKeys.follow,
  contextualMenuKeys.delete,
  contextualMenuKeys.divider1,
  contextualMenuKeys.new,
  contextualMenuKeys.selectThisItem,
  contextualMenuKeys.selectAll,
]
const multipleKeyOrder = [
  contextualMenuKeys.markReadMultiple,
  contextualMenuKeys.markCompletedMultiple,
  contextualMenuKeys.moveMultiple,
  contextualMenuKeys.ownerMultiple,
  contextualMenuKeys.iconMultiple,
  contextualMenuKeys.datesMultiple,
  contextualMenuKeys.multipleExportAsCSV,
  contextualMenuKeys.mailMultiple,
  contextualMenuKeys.printMultiple,
  contextualMenuKeys.multipleSummary,
  contextualMenuKeys.deleteMultiple,
]

export const useMultiItemOptions = () => {
  const selectedItemId = useSelectedItemId()
  const dispatch = useDispatch()
  const adjustPathnameOnDeletedItem = useAdjustPathnameOnDeletedItem()
  const multipleSelectedItemIds = useSelector(getMultipleSelectedItems)
  const multipleSelectedItems = useSelector(state => stateHelper.getItemsFromIDs(state, multipleSelectedItemIds))
  const { setProps: setAlertModalProps } = useContext(AlertModalContext)
  const { setMoveItemsModalState } = useContext(MoveItemsModalContext)
  const { t } = useTranslation()
  const { dispatchToast } = useToastController()
  const { printItems } = usePrintItems()
  const { openModal: openChangeIconModal } = useChangeIcon()
  const { openPanel: openItemDatesPanel } = useItemDatesPanel()
  const { openPanel: openOwnerPanel } = useOwnerPanel()
  const project = useSelectedProject()
  const downloadItemsCSV = useDownloadItemsCSV()
  const [hideConfirmationDeleteItems, setHideConfirmationDeleteItems] = useAtom(hideConfirmationDeleteItemsAtom)
  const [hideConfirmationMarkItemsAsCompleted, setHideConfirmationMarkItemsAsCompleted] = useAtom(
    hideConfirmationMarkItemsAsCompletedAtom
  )
  const { openDialog: openItemsSummaryDialog } = useItemsSummaryDialog()

  const handleMarkReadMultiple = () => {
    dispatch(markItemAsReadFromIDs(multipleSelectedItemIds))
  }

  const handleDeleteMultipleItems = () => {
    const deleteItems = () => {
      const modifiedItems = multipleSelectedItems.map(item => item.set(itemHelper.KEYS.STATE, itemHelper.STATE.DELETED))
      dispatch(sendItems(modifiedItems))
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.DELETE_ITEM))
      dispatch(clearItemMultipleSelection())
      dispatch(setMultipleSelectionOn(false))
      for (const id of multipleSelectedItemIds) {
        if (id === selectedItemId) {
          adjustPathnameOnDeletedItem(selectedItemId)
          return
        }
      }
    }
    if (hideConfirmationDeleteItems) {
      deleteItems()
      return
    }
    setAlertModalProps({
      open: true,
      title: t('item.contextual_menu.multiple_delete_modal.title'),
      subText: t('item.contextual_menu.multiple_delete_modal.subtext'),
      primaryActionText: t('item.contextual_menu.multiple_delete_modal.primary_action_text'),
      onYesAlwaysClick: () => {
        setHideConfirmationDeleteItems(true)
        deleteItems()
      },
      onPrimaryActionClick: deleteItems,
    })
  }

  const handleMarkCompletedMultiple = () => {
    const markCompleted = () => {
      const modifiedItems = multipleSelectedItems.map(item =>
        item.withMutations(mutator => {
          mutator.set(itemHelper.KEYS.STATE, itemHelper.STATE.DONE)
          mutator.set(itemHelper.KEYS.COMPLETION_PERCENTAGE, 100)
        })
      )
      dispatch(sendItems(modifiedItems))
    }
    if (hideConfirmationMarkItemsAsCompleted) {
      markCompleted()
      return
    }
    setAlertModalProps({
      open: true,
      title: t('item.progress.multiple_mark_completed_modal.title'),
      subText: t('item.progress.multiple_mark_completed_modal.subtext'),
      primaryActionText: t('item.progress.multiple_mark_completed_modal.primary_action_text'),
      onYesAlwaysClick: () => {
        setHideConfirmationMarkItemsAsCompleted(true)
        markCompleted()
      },
      onPrimaryActionClick: markCompleted,
    })
  }

  const handleMoveMultipleItems = () => {
    setMoveItemsModalState({
      isDisplayed: true,
      items: multipleSelectedItems,
    })
  }

  const handleExportAsCSV = async () => {
    const body = {
      items: multipleSelectedItemIds.toArray(),
      filename: `Priority_Matrix_${new Date().toISOString()}.csv`,
    }
    const response = await fetch(getCSVExportSelectionURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      dispatchToast(<FluentToast>{t('item.multiple_selection.export_csv_failed')}</FluentToast>, { intent: 'error' })
      return
    }

    const blob = await response.blob()
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = `${body.filename}`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const handleEmailItems = async () => {
    const items = await downloadItemsCSV(multipleSelectedItemIds.toArray())
    if (!items) return
    generateEmail(items)
  }

  const handlePrintItems = async () => {
    const items = await downloadItemsCSV(multipleSelectedItemIds.toArray())
    if (!items) return
    printItems(items)
  }

  const handleChangeIcon = () => {
    const onChangeIcon = newIcon => {
      const modifiedItems = multipleSelectedItems.map(item => item.set(itemHelper.KEYS.ICON, newIcon))
      dispatch(sendItems(modifiedItems))
      dispatch(clearItemMultipleSelection())
      dispatch(setMultipleSelectionOn(false))
    }
    openChangeIconModal({ onChangeIcon })
  }

  const handleChangeDates = () => {
    const onSaveDates = newDates => {
      const modifiedItems = multipleSelectedItems.map(item =>
        item.withMutations(mutator => {
          if (newDates.startDate) mutator.set(itemHelper.KEYS.START_DATE, dateToTimestampInSeconds(newDates.startDate))
          if (newDates.dueDate) mutator.set(itemHelper.KEYS.DUE_DATE, dateToTimestampInSeconds(newDates.dueDate))
          mutator.set(itemHelper.KEYS.ALL_DAY, newDates.allDay)
          if (newDates.untilDate) mutator.set(itemHelper.KEYS.UNTIL_DATE, dateToTimestampInSeconds(newDates.untilDate))
          if (newDates.frequency) mutator.set(itemHelper.KEYS.FREQUENCY, newDates.frequency)
        })
      )
      dispatch(sendItems(modifiedItems))
      dispatch(clearItemMultipleSelection())
      dispatch(setMultipleSelectionOn(false))
    }
    openItemDatesPanel(onSaveDates)
  }

  const handleChangeOwner = () => {
    const onSaveOwner = newOwner => {
      const modifiedItems = multipleSelectedItems.map(item => item.set(itemHelper.KEYS.OWNER_USERNAME, newOwner))
      dispatch(sendItems(modifiedItems))
      dispatch(clearItemMultipleSelection())
      dispatch(setMultipleSelectionOn(false))
    }
    openOwnerPanel(onSaveOwner, project)
  }

  const handleMultipleSummary = () => {
    const itemIds = multipleSelectedItemIds.toArray()
    openItemsSummaryDialog(itemIds)
  }

  const options = {
    [contextualMenuKeys.deleteMultiple]: {
      key: contextualMenuKeys.deleteMultiple,
      text: t('item.delete_multiple'),
      icon: { children: <Delete />, className: '!text-pm-negative' },
      onClick: handleDeleteMultipleItems,
    },
    [contextualMenuKeys.markCompletedMultiple]: {
      key: contextualMenuKeys.markCompletedMultiple,
      text: t('item.progress.mark_completed_multiple'),
      icon: <CheckboxChecked />,
      onClick: handleMarkCompletedMultiple,
    },
    [contextualMenuKeys.markReadMultiple]: {
      key: contextualMenuKeys.markReadMultiple,
      text: t('item.mark_read_multiple'),
      icon: <MailAllRead />,
      onClick: handleMarkReadMultiple,
    },
    [contextualMenuKeys.moveMultiple]: {
      key: contextualMenuKeys.moveMultiple,
      text: t('item.multiple_selection.move.option_text'),
      icon: <FolderArrowRight />,
      onClick: handleMoveMultipleItems,
    },
    [contextualMenuKeys.multipleExportAsCSV]: {
      key: contextualMenuKeys.multipleExportAsCSV,
      text: t('item.multiple_selection.export_csv'),
      icon: <ArrowDownload />,
      onClick: handleExportAsCSV,
    },
    [contextualMenuKeys.mailMultiple]: {
      key: contextualMenuKeys.mailMultiple,
      text: t('item.multiple_selection.email'),
      icon: <Mail />,
      onClick: handleEmailItems,
    },
    [contextualMenuKeys.printMultiple]: {
      key: contextualMenuKeys.printMultiple,
      text: t('item.multiple_selection.print'),
      icon: <Print />,
      onClick: handlePrintItems,
    },
    [contextualMenuKeys.iconMultiple]: {
      key: contextualMenuKeys.iconMultiple,
      text: t('item.multiple_selection.change_icon'),
      icon: <Image />,
      onClick: handleChangeIcon,
    },
    [contextualMenuKeys.datesMultiple]: {
      key: contextualMenuKeys.datesMultiple,
      text: t('item.multiple_selection.change_dates'),
      icon: <CalendarLtr />,
      onClick: handleChangeDates,
    },
    [contextualMenuKeys.ownerMultiple]: {
      key: contextualMenuKeys.ownerMultiple,
      text: t('item.multiple_selection.change_owner'),
      icon: <Person />,
      onClick: handleChangeOwner,
    },
    [contextualMenuKeys.multipleSummary]: {
      key: contextualMenuKeys.multipleSummary,
      text: t('item.multiple_selection.billable_hours'),
      icon: <Money />,
      onClick: handleMultipleSummary,
    },
  }

  return multipleSelectedItemIds.size > 0 ? multipleKeyOrder.map(key => options[key]) : []
}

export const useItemContextualMenuOptions = (itemId, { blackList = [], allItemsInList } = {}) => {
  const section = useParams().section
  const multipleSelection = useSelector(isItemMultipleSelectionOn)
  const multipleSelectedItemIds = useSelector(getMultipleSelectedItems)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { dispatchToast } = useToastController()
  const createItemModal = useCreateItemModal()
  // Force item to update when API request is made (didn't happen in ItemVirtualizedTable)
  const item = useSelector(state => stateHelper.getItem(state, itemId))
  const itemIsStarred = itemHelper.isStarred(item)
  const itemIsDone = itemHelper.isCompleted(item)
  const itemID = itemHelper.getId(item)
  const itemName = itemHelper.getName(item)
  const itemProjectId = itemHelper.getProjectIdd(item)
  const itemProject = useSelector(state => stateHelper.getProject(state, itemProjectId))
  const itemQuadrant = itemHelper.getQuadrant(item)
  const { dispatch: addLinkDispatch } = useContext(ItemLinkPanelContext)
  const { setMoveItemModalState } = useContext(MoveItemModalContext)
  const isInbox = itemHelper.isInbox(item)
  const isPlus = useSelector(state => stateHelper.isInboxPlusItem(state, item))
  const { openDialog: openConvertItemToProjectDialog } = useConvertItemToProjectDialog()

  const multiItemOptions = useMultiItemOptions()

  const handleMoveItem = () => {
    setMoveItemModalState({
      isDisplayed: true,
      item,
      initProject: isInbox || isPlus ? null : itemProject,
      initQuadrant: isInbox || isPlus ? null : itemQuadrant,
    })
    dispatch(
      dispatchEvent(AMPLITUDE_ACTION_TYPES.MOVE_ITEM, {
        from: EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU,
      })
    )
  }

  const {
    follow: handleFollowItem,
    isFollowed,
    isOwner,
  } = useFollowItem(item, EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU)

  const noFollowersData = isFollowed === undefined
  const noTagsData = itemIsStarred === undefined
  if (isOwner) blackList.push(contextualMenuKeys.follow)

  const createLinkedItem = useCreateLinkedItem(item, EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU)
  const handleCreateLinkedItem = () => {
    dispatch(
      dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_CREATE_LINKED_ITEM, {
        from: EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU,
      })
    )
    createLinkedItem()
  }

  const handleDuplicateItem = useDuplicateItem(item, EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU)

  const handleSelectThisItem = () => {
    dispatch(setMultipleSelectionOn(true))
    dispatch(setItemSelectedInMultipleSelection(itemId, true))
  }

  const handleSelectAllItems = () => {
    dispatch(setMultipleSelectionOn(true))
    const ids = Map.isMap(allItemsInList) ? allItemsInList.keys() : allItemsInList.map(item => itemHelper.getId(item))
    dispatch(setItemsSelectedInMultiSelection(ids))
  }
  const triggerItemDeletionModal = useTriggerItemDeletionModal()
  const handleDeleteItem = () => triggerItemDeletionModal({ item })
  const handlePopOut = () => {
    dispatch(
      dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_ITEM_IN_NEW_TAB, { from: EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU })
    )
    const adjustedWidth = window.innerWidth / 1.5
    const adjustedHeight = window.innerHeight / 1.25
    const windowConfig = 'width=' + adjustedWidth + ',height=' + adjustedHeight
    const paramsToHideChrome = '?hide_chrome=1'
    const link = getItemLink(itemID) + paramsToHideChrome
    window.open(link, '', windowConfig)
  }

  const handleCopyInfo = () => {
    dispatch(
      dispatchEvent(AMPLITUDE_ACTION_TYPES.COPY_ITEM_INFO, { from: EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU })
    )
    copyToClipboard(`${itemName}: ${getItemLink(itemID)}`)
    dispatchToast(<FluentToast>{t('item_detail.copy_info_success')}</FluentToast>, { intent: 'success' })
  }

  const handleCopyLink = useCopyLink(item, EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU)

  const handleCopyTitle = () => {
    dispatch(
      dispatchEvent(AMPLITUDE_ACTION_TYPES.COPY_ITEM_TITLE, { from: EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU })
    )
    copyToClipboard(itemName)
    dispatchToast(<FluentToast>{t('item_detail.copy_title_success')}</FluentToast>, { intent: 'success' })
  }

  const { markCompleted: handleMarkCompleted } = useMarkCompleted(item, EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU)

  const handleStar = () => {
    dispatch(
      dispatchEvent(itemIsStarred ? AMPLITUDE_ACTION_TYPES.UNSTAR_ITEM : AMPLITUDE_ACTION_TYPES.STAR_ITEM, {
        from: EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU,
      })
    )
    const action = itemIsStarred ? removeStarredTag({ object: item }) : addStarredTag({ object: item })
    dispatch(action)
  }

  const handleAddItem = () => {
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.CONTEXTUAL_MENU,
      initialProject: itemProject,
      quadrant: itemHelper.getQuadrant(item),
      displayProjectSelector: true,
      open: !section,
      completion: !section
        ? undefined
        : i => {
            linkSubject.next({
              urlData: itemHelper.getUriData(i),
              section,
            })
          },
    })
  }

  const handleCreateLinkProject = () => {
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.CONTEXTUAL_MENU,
      initialProject: itemProject,
      quadrant: itemHelper.getQuadrant(item),
      displayProjectSelector: false,
      showLinkedProjectSelector: true,
    })
  }

  const handleAddLink = () => {
    dispatch(
      dispatchEvent(AMPLITUDE_ACTION_TYPES.ADD_LINKED_ITEM, { from: EVENT_EXTRA.ITEM_ACTION.FROM.CONTEXTUAL_MENU })
    )
    addLinkDispatch({ type: LINK_PANEL_ACTION_TYPES.SHOW_ADD_LINK, payload: { itemId: itemID } })
  }

  const { openDialog } = useProjectLinkSelectorDialog()

  const handleAddLinkToProject = () => {
    openDialog(item)
  }

  if (!item) return []

  const contextualMenuOptions = {
    [contextualMenuKeys.copy]: {
      key: contextualMenuKeys.link,
      text: t('item_detail.copy_link'),
      icon: <Share />,
      onClick: handleCopyLink,
      split: true,
      subMenuProps: {
        items: [
          {
            key: contextualMenuKeys.copy,
            text: t('item_detail.copy_info'),
            onClick: handleCopyInfo,
          },
          {
            key: contextualMenuKeys.copyTitle,
            text: t('item_detail.copy_title'),
            onClick: handleCopyTitle,
          },
        ],
      },
    },
    [contextualMenuKeys.delete]: {
      key: contextualMenuKeys.delete,
      text: t('item_detail.delete_action'),
      icon: { children: <Delete />, className: '!text-pm-negative' },
      onClick: handleDeleteItem,
    },
    [contextualMenuKeys.divider1]: {
      key: contextualMenuKeys.divider1,
      type: 'divider',
    },
    [contextualMenuKeys.follow]: {
      key: contextualMenuKeys.follow,
      text: isFollowed ? t('item.unfollow') : t('item.follow'),
      icon: isFollowed ? <EyeOff /> : <Eye />,
      onClick: handleFollowItem,
      disabled: noFollowersData,
    },
    [contextualMenuKeys.markCompleted]: {
      key: contextualMenuKeys.markCompleted,
      text: itemIsDone ? t('item.progress.reset_completion') : t('item.progress.mark_completed'),
      icon: itemIsDone ? <CheckboxUnchecked /> : <CheckboxChecked />,
      onClick: () => handleMarkCompleted(itemIsDone ? 0 : 100),
    },
    [contextualMenuKeys.new]: {
      key: contextualMenuKeys.new,
      text: t('item.create_item'),
      icon: <Add />,
      onClick: handleAddItem,
      split: true,
      subMenuProps: {
        items: [
          {
            key: contextualMenuKeys.newLinked,
            text: t('item.create_linked_item'),
            icon: <DocumentAdd />,
            onClick: handleCreateLinkedItem,
          },
          {
            key: contextualMenuKeys.createLinkProject,
            text: t('item.create_link_project'),
            icon: <DocumentLink />,
            onClick: handleCreateLinkProject,
          },
        ],
      },
    },
    [contextualMenuKeys.popOut]: {
      key: contextualMenuKeys.popOut,
      text: t('item_detail.pop_out_item'),
      icon: <WindowNew />,
      onClick: handlePopOut,
    },
    [contextualMenuKeys.star]: {
      key: contextualMenuKeys.star,
      text: itemIsStarred ? t('item.unstar') : t('item.star'),
      icon: itemIsStarred ? <StarOff /> : <Star />,
      onClick: handleStar,
      disabled: noTagsData,
    },
    [contextualMenuKeys.addLink]: {
      key: contextualMenuKeys.addLink,
      text: t('links_section.add_item'),
      icon: <LinkMultiple />,
      onClick: handleAddLink,
    },
    [contextualMenuKeys.addLinkProject]: {
      key: contextualMenuKeys.addLinkProject,
      text: t('item.add_link_project'),
      icon: <FolderLink />,
      onClick: handleAddLinkToProject,
    },
    [contextualMenuKeys.duplicate]: {
      key: contextualMenuKeys.duplicate,
      text: t('item.duplicate'),
      icon: <Copy />,
      onClick: handleDuplicateItem,
    },
    [contextualMenuKeys.convertToProject]: {
      key: contextualMenuKeys.convertToProject,
      text: t('item.contextual_menu.convert_to_project'),
      icon: <TaskListAdd />,
      onClick: () => openConvertItemToProjectDialog(itemId),
    },
    [contextualMenuKeys.move]: {
      key: contextualMenuKeys.move,
      text: t('item_detail.move_item'),
      icon: <FolderArrowRight />,
      onClick: handleMoveItem,
    },
    [contextualMenuKeys.selectAll]: {
      key: contextualMenuKeys.selectAll,
      text: t('item.multiple_selection.select_all'),
      icon: <SelectAllOn />,
      onClick: handleSelectAllItems,
    },
    [contextualMenuKeys.selectThisItem]: {
      key: contextualMenuKeys.selectThisItem,
      text: t('item.multiple_selection.select_item'),
      icon: <CheckboxChecked />,
      onClick: handleSelectThisItem,
    },
  }

  const localBlackList = [...blackList]
  if (!allItemsInList) {
    localBlackList.push(contextualMenuKeys.selectAll)
  }
  const filteredKeyOrder = singleKeyOrder.filter(key => localBlackList.indexOf(key) === -1)

  const showMultipleSelectionOptions = multipleSelection && multipleSelectedItemIds.has(itemID)

  return showMultipleSelectionOptions
    ? [
        {
          key: 'sectionMultiple',
          sectionProps: {
            title: t('item.multiple_selection.count', { count: multipleSelectedItemIds.size }),
            items: multiItemOptions,
          },
        },
      ]
    : [
        {
          key: 'sectionSingle',
          sectionProps: {
            title: multipleSelection ? t('item.contextual_menu.this_item') : undefined,
            items: filteredKeyOrder.map(key => contextualMenuOptions[key]),
          },
        },
      ]
}

export const useGetItemWhenOpenContextualMenu = (item, showContextualMenu) => {
  const itemId = itemHelper.getId(item)
  const dispatch = useDispatch()
  useEffect(() => {
    if (showContextualMenu) dispatch(getItemIfNeeded(itemId))
  }, [showContextualMenu, itemId, dispatch])
}

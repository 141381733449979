import { useCallback, useEffect, useSyncExternalStore } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider as FluentThemeProvider, useTheme } from '@fluentui/react'
import { darkTheme, defaultTheme } from './style/Palettes'
import { useSelector } from 'react-redux'
import { getPrefersDarkMode } from './selectors/uiSelectors'
import { FluentProvider, webDarkTheme, webLightTheme } from '@fluentui/react-components'
import { PortalCompatProvider } from '@fluentui/react-portal-compat'

const sp = new URLSearchParams(window.location.search)
const themeQueryParam = sp.get('theme')

const THEMES = {
  DARK: 'dark',
}
const darkModePreference = window.matchMedia('(prefers-color-scheme: dark)')
const subscribe = callback => {
  darkModePreference.addEventListener('change', callback)
  return () => darkModePreference.removeEventListener('change', callback)
}
const getSnapshot = () => darkModePreference.matches
const useSystemPreferredMode = () => useSyncExternalStore(subscribe, getSnapshot)

export const DEFAULT_QUADRANTS_COLORS = ['#5ca4c0', '#a2c6d4', '#a2c6d4', '#daecea']

/// This hook is used to get current applied theme
/// @returns {boolean} - true if dark theme is applied
export const useIsDarkTheme = () => {
  const systemPreferredMode = useSystemPreferredMode()
  const selectedMode = useSelector(getPrefersDarkMode)

  if (selectedMode !== 'system') {
    return selectedMode === 'dark'
  }
  if (themeQueryParam) {
    return themeQueryParam === THEMES.DARK
  }
  return systemPreferredMode
}

const StyledThemeProviderForFluent = ({ children }) => {
  const fluentTheme = useTheme()
  return <StyledThemeProvider theme={fluentTheme}>{children}</StyledThemeProvider>
}

export const CustomThemeProvider = ({ children }) => {
  const isDark = useIsDarkTheme()
  const fluentPartialTheme = isDark ? darkTheme : defaultTheme
  const fluentV9Theme = isDark ? webDarkTheme : webLightTheme

  const activateDarkMode = useCallback(isDark => {
    const body = document.getElementsByTagName('body')[0]
    const root = document.querySelector(':root')
    if (isDark) {
      body.classList.add('dark')
      root.classList.add('dark')
    } else {
      body.classList.remove('dark')
      root.classList.remove('dark')
    }
  }, [])

  useEffect(() => {
    activateDarkMode(isDark)
  }, [activateDarkMode, isDark])

  return (
    <FluentThemeProvider theme={fluentPartialTheme}>
      <FluentProvider theme={fluentV9Theme}>
        <PortalCompatProvider>
          <StyledThemeProviderForFluent>{children}</StyledThemeProviderForFluent>
        </PortalCompatProvider>
      </FluentProvider>
    </FluentThemeProvider>
  )
}

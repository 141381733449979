import React, { memo, Suspense, useContext, useEffect } from 'react'
import { MainLayout } from '../../../components/layout/MainLayout'
import { RightDetailView } from '../../rightDetailView/RightDetailView'
import { getRelativeURLKeepingQuerySearch } from '../../../helpers/routeHelper'
import { ProjectLevelTopBar } from '../../project/ProjectLevelTopBar'
import { GanttFeaturePlaceholder } from '../GanttFeaturePlaceholder'
import { ViewSizeContext } from '../../../contexts'
import { projectHelper } from '../../../common/src/helpers'
import { useDispatch } from 'react-redux'
import { getItemsInProject } from '../../../common/src/actions/itemsAPI'
import { IENotSupportedView } from '../../IENotSupportedView'
import { isInternetExplorer } from '../../../common/src/helpers/platformHelper'
import { Loading } from '../../../components/basic/Loading'
import { retry } from '../../../common/src/retry'

const Gantt = React.lazy(() => retry(() => import('./Gantt')))

const useFetchLinkedResources = project => {
  const dispatch = useDispatch()
  const id = projectHelper.getIdd(project)
  useEffect(() => {
    if (id) {
      dispatch(
        getItemsInProject(
          {
            dueDate__gt: 1,
            returnlinks: 1,
          },
          id
        )
      )
    }
  }, [dispatch, id])
}

export const GanttView = memo(({ project, items, projectSelectorEnabled, readOnly, filtersType }) => {
  useFetchLinkedResources(project)
  const { narrowWidth } = useContext(ViewSizeContext)
  const desktop = (
    <Suspense fallback={<Loading />}>
      <Gantt items={items} readOnly={readOnly} filtersType={filtersType} />
    </Suspense>
  )
  const mobile = <GanttFeaturePlaceholder />
  const IE = <IENotSupportedView />
  const components = {
    main: isInternetExplorer() ? IE : narrowWidth ? mobile : desktop,
    topBar: (
      <ProjectLevelTopBar
        getNavigationURLForProjectId={getRelativeURLKeepingQuerySearch.calendarForProjectId}
        key={'ProjectMatrixViewTopBar'}
        project={project}
        projectSelectorEnabled={projectSelectorEnabled}
        readOnly={readOnly}
        items={items}
      />
    ),
    rightSide: <RightDetailView readOnly={readOnly} />,
  }
  return <MainLayout {...components} />
})

// TODO write all .env vars here

const readViteEnv = () => {
  return {
    TRACK_EVENTS: import.meta.env.VITE_TRACK_EVENTS === 'true',
    DEVELOPMENT: import.meta.env.DEV,
    VERSION: import.meta.env.VITE_VERSION,
    NAME: import.meta.env.VITE_NAME,
    USE_COOKIE: import.meta.env.VITE_USE_COOKIE === 'true',
    USE_AMPLITUDE_PROXY: import.meta.env.VITE_USE_AMPLITUDE_PROXY === 'true',
    //webapp
    BASE_URL: import.meta.env.VITE_BASE_URL,
    REDIRECT: import.meta.env.VITE_SINGLE_ITEM_REDIRECT === 'true',
    LOCAL_STORAGE_PREFIX: import.meta.env.VITE_LOCAL_STORAGE_PREFIX,
    DISPLAY_DESKTOP_PROMOTION_BANNER: import.meta.env.VITE_LOCAL_STORAGE_PREFIX === 'true',
    USE_WS: import.meta.env.VITE_WS !== 'false',
    OAUTH_CLIENT_ID: import.meta.env.VITE_OAUTH_CLIENT_ID !== 'false',
    OAUTH_CLIENT_SECRET: import.meta.env.VITE_OAUTH_CLIENT_SECRET !== 'false',
  }
}

const readCRAEnv = () => {
  return {
    TRACK_EVENTS: process.env.REACT_APP_TRACK_EVENTS === 'true',
    DEVELOPMENT: process.env.NODE_ENV === 'development',
    VERSION: process.env.REACT_APP_VERSION,
    NAME: process.env.REACT_APP_NAME,
    USE_COOKIE: process.env.REACT_APP_USE_COOKIE === 'true',
    USE_AMPLITUDE_PROXY: process.env.REACT_APP_USE_AMPLITUDE_PROXY === 'true',
    //webapp
    BASE_URL: process.env.REACT_APP_BASE_URL,
    REDIRECT: process.env.REACT_APP_SINGLE_ITEM_REDIRECT === 'true',
    LOCAL_STORAGE_PREFIX: process.env.REACT_APP_LOCAL_STORAGE_PREFIX,
    DISPLAY_DESKTOP_PROMOTION_BANNER: process.env.REACT_APP_LOCAL_STORAGE_PREFIX === 'true',
    USE_WS: process.env.REACT_APP_WS !== 'false',
    OAUTH_CLIENT_ID: process.env.REACT_APP_OAUTH_CLIENT_ID !== 'false',
    OAUTH_CLIENT_SECRET: process.env.REACT_APP_OAUTH_CLIENT_SECRET !== 'false',
  }
}

let environment
if (typeof import.meta.env !== 'undefined') {
  environment = readViteEnv()
} else if (typeof process.env !== 'undefined') {
  environment = readCRAEnv()
}

//common
export const TRACK_EVENTS = environment.TRACK_EVENTS
export const DEVELOPMENT = environment.DEVELOPMENT
export const VERSION = environment.VERSION
export const NAME = environment.NAME
export const USE_COOKIE = environment.USE_COOKIE
export const USE_AMPLITUDE_PROXY = environment.USE_AMPLITUDE_PROXY

//webapp
export const BASE_URL = environment.BASE_URL
export const REDIRECT = environment.REDIRECT
export const LOCAL_STORAGE_PREFIX = environment.LOCAL_STORAGE_PREFIX
export const DISPLAY_DESKTOP_PROMOTION_BANNER = environment.DISPLAY_DESKTOP_PROMOTION_BANNER
export const USE_WS = environment.USE_WS
export const OAUTH_CLIENT_ID = environment.OAUTH_CLIENT_ID
export const OAUTH_CLIENT_SECRET = environment.OAUTH_CLIENT_SECRET

export const BASENAME = `${BASE_URL}index/`

// Guard, just to ensure I update all the projects that depend on common code
// console.log(import.meta.env.VITE_NAME)
if (!NAME) {
  throw new Error('Please, ensure your .env is properly configured')
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useDispatch, useStore } from 'react-redux'
import { getAttentionNeeded, postItem } from '@/common/src/actions/itemsAPI'
import { addMonths } from 'date-fns'
import { markItemAsModifiedInModel, sendItem } from '@/common/src/actions/combinedAPI'
import { useCallback, useEffect, useId, useState } from 'react'
import type { Item } from '@/types/item'
import { itemHelper, stateHelper } from '@/common/src/helpers'
import { resourceURICreator, uriDataCreator } from '@/common/src/helpers/URLHelper'
import { getProjectLink, PM_API_RESOURCE_TYPE } from '@/common/src/constants'
import { fromJS } from 'immutable'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '@/common/src/eventTracking/amplitudeEvents'
import { useToastController } from '@fluentui/react-components'
import { FluentToast } from '@/components/toast/FluentToast'
import { useTranslation } from 'react-i18next'
import { OnboardingStepKeys } from '@/actions/onboardingActions'
import { linkSubject } from '@/reactions/linkSubject'
import { useSendStepEventIfNeeded } from '@/hooks/onboardingHooks'
import { appendQueryParamsToURL, getCredentialsConfig } from '@/common/src/helpers/requestHelper'

export const useAttentionNeededQuery = () => {
  const store = useStore()
  return useQuery({
    queryKey: ['attentionNeeded'],
    queryFn: () => {
      return store.dispatch(
        getAttentionNeeded({
          timestamp__gte: addMonths(new Date(), -2).toISOString(),
          add_mentions: true,
        })
      )
    },
    staleTime: 1000 * 60 * 5,
  })
}

export const useMutateItem = (itemId: number) => {
  const dispatch = useDispatch()
  const [internalItemId, setInternalItemId] = useState(itemId)

  // In ItemDetail we have a useEffect with cleanup code to send the last changes to an item
  // when we change to another item.
  // If we just use itemId as the scope for the mutation, the cleanup code will be called with the new itemId as scope,
  // resulting in that mutation not being enqueued for the previous item.
  // Adding the following useEffect will make sure that the scope is correct when changing items.
  useEffect(() => {
    if (internalItemId !== itemId) {
      setInternalItemId(itemId)
    }
  }, [internalItemId, itemId])

  return useMutation({
    mutationFn: item => {
      return dispatch(sendItem(item))
    },
    scope: {
      id: `item-${internalItemId}`,
    },
  })
}

export type CreateItemArgs = {
  open?: boolean
  notify?: boolean
  delayToOpen?: number
  eventProps?: any
  projectIdd?: number
  quadrant?: number
  onSuccess?: (item: Item) => void
}

export type PseudoItem = Pick<Item, 'name'> &
  Partial<
    Pick<
      Item,
      | 'owner_username'
      | 'descriptionText'
      | 'quadrant'
      | 'macResourceURL'
      | 'dueDate'
      | 'startDate'
      | 'allDay'
      | 'index'
    >
  > & {
    projectId?: number
    copiedFromId?: number
  }
export const useCreateItem = ({
  open = true,
  notify = false,
  delayToOpen = 0,
  eventProps,
  projectIdd,
  quadrant,
  onSuccess,
}: CreateItemArgs = {}) => {
  const dispatch = useDispatch()
  const { dispatchToast, updateToast } = useToastController()
  const toastId = useId()
  const { t } = useTranslation()
  const openItemWithID = useCallback((itemId: number) => {
    const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, itemId)
    linkSubject.next({ urlData })
  }, [])
  const sendStepEventIfNeeded = useSendStepEventIfNeeded()

  return useMutation({
    mutationKey: ['item', 'create', { projectIdd, quadrant }],
    mutationFn: async (pseudoItem: PseudoItem) => {
      const { projectId, copiedFromId, ...rest } = pseudoItem
      const item = rest as Partial<Item>
      if (projectId) {
        const uri = resourceURICreator(1, PM_API_RESOURCE_TYPE.PROJECT, projectId)
        item.projects = [uri]
      }
      const immutableItem = fromJS(item)

      if (notify) {
        dispatchToast(<FluentToast>{t('item.creating')}</FluentToast>, {
          toastId,
          intent: 'info',
          timeout: -1,
        })
      }

      const response = await dispatch(postItem(immutableItem, copiedFromId))
      const { error, payload } = response
      if (error) {
        throw response
      }
      return payload
    },
    onSuccess: (data, variables, context) => {
      dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.CREATE_ITEM, eventProps))

      if (open) {
        const itemID = data.id
        if (delayToOpen > 0) {
          setTimeout(() => {
            openItemWithID(itemID)
          }, delayToOpen)
        } else {
          openItemWithID(itemID)
        }
      }

      // Send onboarding step event
      sendStepEventIfNeeded(OnboardingStepKeys.CREATE_ITEMS)
      onSuccess?.(data)
    },
    onSettled: (data, error, variables, context) => {
      if (!notify) return
      updateToast({
        toastId,
        content: <FluentToast>{error ? t('item.created_unsuccessfully') : t('item.created_successfully')}</FluentToast>,
        intent: error ? 'error' : 'success',
        timeout: 5000,
      })
    },
  })
}

export const useGetIndexForNewItem = (projectId: number, quadrant: number, above: boolean) => {
  const store = useStore()
  const queryClient = useQueryClient()

  return useCallback(() => {
    const pendingItemCreations = queryClient
      .getMutationCache()
      .findAll({ mutationKey: ['item', 'create', { projectIdd: projectId, quadrant }], status: 'pending' })
    const pendingIndexes = pendingItemCreations
      .map(mutation => (mutation.state.variables as PseudoItem).index)
      .filter(index => index !== undefined)
    const suggestedIndex = stateHelper.getSuggestedIndexForNewItem(store.getState(), projectId, quadrant, above)
    if (above) {
      return Math.max(Math.max(...pendingIndexes) + 1000, suggestedIndex)
    } else {
      return Math.min(Math.min(...pendingIndexes) - 1000, suggestedIndex)
    }
  }, [queryClient, projectId, quadrant, store, above])
}

export const useConvertItemIntoProject = () => {
  const store = useStore()
  return useMutation({
    mutationFn: async ({ itemId, useAI }: { itemId: number; useAI: boolean }) => {
      const baseUrl = `/api/v1/item/${itemId}/create_project/`
      const params = { use_ai: useAI }
      const response = await fetch(appendQueryParamsToURL(baseUrl, params)(store.getState()), {
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      const project = (await response.json()) as object
      const item = stateHelper.getItem(store.getState(), itemId)
      const itemWithLink = item.set('macResourceURL', getProjectLink((project as any).idd))
      store.dispatch(markItemAsModifiedInModel(itemHelper.createFromRaw(itemWithLink)))
      return project
    },
  })
}

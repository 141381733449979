import { memo, Suspense, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { itemHelper } from '../../common/src/helpers'
import { isInitializedRequestForPMResource } from '../../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from '../../common/src/constants'
import { Loading } from '../../components/basic/Loading'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { isEmbeddedOnTeams } from '../../helpers/queryParamsHelper'
import { BasicItemList } from '../../components/item/basicList/BasicItemList'
import { useTranslatedPlaceholders } from '../../hooks/translationHooks'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { useSelectedItemId } from '../../hooks/PMHooks'
import { useAttentionNeededQuery } from '@/queries/items'
import { Link, Skeleton, SkeletonItem } from '@fluentui/react-components'
import { getRelativeURLKeepingQuerySearch } from '@/helpers/routeHelper'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { isSameDay, startOfDay } from 'date-fns'
import { useAgendaItems } from '@/queries/agenda'

const HomeAlerts_ = ({ onSelectItem, items, setForceAlertId, agendaMode }) => {
  useAttentionNeededQuery()
  const loading = useSelector(state => !isInitializedRequestForPMResource(state, PM_API_RESOURCES.ATTENTION_NEEDED))
  const selectedItemId = useSelectedItemId()
  const history = useHistory()
  const toAgenda = getRelativeURLKeepingQuerySearch.homeSection('agenda')
  const { t } = useTranslation()

  const { items: agendaItems, queryResult: agendaQueryResult } = useAgendaItems(agendaMode)
  const { isPlaceholderData: agendaLoading } = agendaQueryResult

  const placeholders = useTranslatedPlaceholders('alerts.placeholder_', 7)
  const validPlaceholders = useMemo(() => {
    const allPlaceholders = placeholders.map((p, i) => (i === 6 ? { ...p, type: 3 } : p))
    return !isEmbeddedOnTeams() ? allPlaceholders.slice(0, -1) : allPlaceholders
  }, [placeholders])
  const placeholder = useRandomValueFromArray(validPlaceholders)

  const customOnSelectItem = useCallback(
    item => {
      const id = itemHelper.getId(item)
      setForceAlertId(id)
      onSelectItem(item)
    },
    [setForceAlertId, onSelectItem]
  )

  if (!loading && !items.length) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }

  const now = new Date()
  const startOfToday = startOfDay(now)
  const dueTodayCount = agendaItems.filter(item => isSameDay(now, itemHelper.getDueDate(item))).size
  const overdueLastMonthCount = agendaItems.filter(item => itemHelper.getDueDate(item) < startOfToday).size
  const dueThisWeekCount = agendaItems.size - overdueLastMonthCount

  const agendaSummary = dueTodayCount
    ? t('alerts.agenda_summary.due_today', { count: dueTodayCount })
    : dueThisWeekCount
      ? t('alerts.agenda_summary.due_this_week', { count: dueThisWeekCount })
      : t('alerts.agenda_summary.overdue_last_month', { count: overdueLastMonthCount })

  return (
    <BasicItemList
      items={
        !agendaLoading && agendaItems.size === 0
          ? items
          : [
              <div key="agenda-summary" className="pb-3 pl-3 pt-2">
                <Link
                  key="agenda-summary"
                  href={toAgenda.pathname}
                  onClick={event => {
                    event.preventDefault()
                    history.push(toAgenda)
                  }}
                >
                  {agendaLoading ? (
                    <span className="flex items-baseline gap-1">
                      Agenda:
                      <Skeleton>
                        <SkeletonItem size={12} className="!w-32" />
                      </Skeleton>
                    </span>
                  ) : (
                    `Agenda: ${agendaSummary}`
                  )}
                </Link>
              </div>,
              ...items,
            ]
      }
      selectedItemId={selectedItemId}
      onClickItem={customOnSelectItem}
      loading={loading}
      shouldShowProject
    />
  )
}

export const HomeAlerts = memo(HomeAlerts_)

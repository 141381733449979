const palette = {
  themePrimary: 'var(--color-theme-primary)',
  themeLighterAlt: 'var(--color-theme-lighter-alt)',
  themeLighter: 'var(--color-theme-lighter)',
  themeLight: 'var(--color-theme-light)',
  themeTertiary: 'var(--color-theme-tertiary)',
  themeSecondary: 'var(--color-theme-secondary)',
  themeDarkAlt: 'var(--color-theme-dark-alt)',
  themeDark: 'var(--color-theme-dark)',
  themeDarker: 'var(--color-theme-darker)',
  neutralLighterAlt: 'var(--color-neutral-lighter-alt)',
  neutralLighter: 'var(--color-neutral-lighter)',
  neutralLight: 'var(--color-neutral-light)',
  neutralQuaternaryAlt: 'var(--color-neutral-quaternary-alt)',
  neutralQuaternary: 'var(--color-neutral-quaternary)',
  neutralTertiaryAlt: 'var(--color-neutral-tertiary-alt)',
  neutralTertiary: 'var(--color-neutral-tertiary)',
  neutralSecondary: 'var(--color-neutral-secondary)',
  neutralPrimaryAlt: 'var(--color-neutral-primary-alt)',
  neutralPrimary: 'var(--color-neutral-primary)',
  neutralDark: 'var(--color-neutral-dark)',
  black: 'var(--color-black)',
  white: 'var(--color-white)',
  always_white: 'var(--color-always-white)',
  always_black: 'var(--color-always-black)',
  always_primary: 'var(--color-always-primary)',
  highlight: 'var(--color-highlight)',
  lightHighlight: 'var(--color-light-highlight)',
  overlay: 'var(--color-overlay)',
  affirmative: 'var(--color-affirmative)',
  negative: 'var(--color-negative)',
  yellow: 'var(--color-yellow)',
  orange: 'var(--color-orange)',
  appfluence: 'var(--color-appfluence)',
  teams: 'var(--color-teams)',
}

export const defaultTheme = {
  palette,
}

export const darkTheme = {
  palette,
}

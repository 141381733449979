import _ from 'lodash'

export const toFormData = body =>
  _.reduce(
    body,
    (acc, v, k) => {
      if (acc) {
        acc.append(k, v)
      }
      return acc
    },
    new FormData()
  )

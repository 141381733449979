import _ from 'lodash'

// These values MUST be kept in sync with other apps
// Full list: https://analytics.amplitude.com/appfluence/manage/project/173310/advanced/events
export const EVENTS = {
  APP_STARTED: 'App Started',
  ADD_COLLABORATOR: 'Add Collaborator',
  ADD_FOLLOWER: 'Add Follower',
  ADD_FOLLOWER_GROUP: 'Add Follower Group',
  ADD_TAG: 'Add Tag',
  COMPLETE_ITEM: 'Complete Item',
  COPY_ITEM_LINK: 'Copy Item Link',
  COPY_ITEM_INFO: 'Copy Item Info',
  COPY_ITEM_TITLE: 'Copy Item Title',
  COPY_PROJECT_LINK: 'Get Project Link',
  CREATE_CONNECTOR: 'Create Connector',
  CREATE_ITEM: 'Create item',
  OPEN_CREATE_ITEM_DIALOG: 'Open Create Item Dialog',
  OPEN_DUPLICATE_ITEM: 'Open Duplicate Item',
  CREATE_PROJECT: 'Create Project',
  CREATE_TAB: 'Create Tab',
  DELEGATE_ITEM: 'Delegate Item',
  OPEN_DELETE_ITEM_DIALOG: 'Open Delete Item Dialog',
  STAR_ITEM: 'Star Item',
  UNSTAR_ITEM: 'Unstar Item',
  ADD_LINKED_ITEM: 'Add Linked Item',
  DELETE_ITEM: 'Delete Item',
  INVITE_ITEM: 'Add Follower',
  LOGIN_ERROR: 'Login Error',
  MENTION_USER: 'Mention User',
  MODIFIED_ICON: 'Modified Icon',
  MOVE_ITEM: 'Move Item',
  OPEN_CALENDAR: 'Open Calendar',
  OPEN_CLASSIC_WEBAPP: 'Open Classic Webapp',
  OPEN_DOWNLOAD_PAGE: 'Open Downloads Page',
  OPEN_FEED: 'Open Feed',
  OPEN_HOME: 'Open Home',
  OPEN_GRAPH_RES_ON_OFFICE365: 'Open Graph Resource On Office365',
  OPEN_INBOX: 'Open Inbox',
  OPEN_FOLLOWERS_PANEL: 'Open Followers Panel',
  OPEN_ONE_ON_ONE: 'Open One On One',
  OPEN_SEARCH_VIEW: 'Open Search View',
  OPEN_REPORTS: 'Open Reports',
  OPEN_WEBAPP: 'Open Web App',
  OPEN_WEB_PUSH: 'Open Web Push',
  OPEN_WORKLOAD_MANAGEMENT: 'Open Workload Management',
  OPEN_WEBINARS_LINK: 'Open Webinars Link',
  REMOVE_CONNECTOR: 'Remove Connector',
  REMOVE_TAG: 'Remove Tag',
  REMOVE_FOLLOWER: 'Remove Follower',
  REQUEST_ACCESS_PROJECT: 'Request Access Project',
  SEND_COMMENT: 'Send Comment',
  SELECT_ONE_ON_ONE_COLLABORATOR: 'Select One On One Collaborator',
  SHOW_FILTER: 'Show Filter',
  SIGN_IN: 'Sign In',
  SIGN_IN_FAILED: 'Sign In Failed',
  SIGN_OUT: 'Sign Out',
  UNINVITE_ITEM: 'Remove Follower',
  UPDATE_CONNECTOR: 'Update Connector',
  SET_MATRIX_VIEW_MODE: 'Set Matrix View Mode',
  OPEN_CUSTOM_FILTERS: 'Open Custom Filters',
  SET_CUSTOM_FILTER: 'Set Custom Filter',
  CREATE_CUSTOM_FILTER: 'Create Custom Filter',
  OPEN_CREATE_ITEM_MODAL: 'Open Create Item Modal',
  OPEN_CREATE_LINKED_ITEM: 'Open Create Linked Item',
  OPEN_ITEM_IN_NEW_TAB: 'Open Item In New Tab',

  CSV_EXPORT_PROJECT: 'Csv Export Project',
  IMPORT_FROM_CSV: 'Import Project From CSV',
  IMPORT_FROM_CSV_TO_EXISTING_PROJECT: 'Import CSV To Existing Project',
  INSTANTIATE_TEMPLATE: 'Instantiate Template',
  CREATE_TEMPLATE: 'Create Template',

  WEB_APP_ONBOARDING_INITIAL: 'Web App Onboarding Initialized',
  WEB_APP_ONBOARDING_START: 'Web App Onboarding Started',
  WEB_APP_ONBOARDING_COMPLETED_STEP: 'Web App Onboarding Completed Step',
  WEB_APP_ONBOARDING_FINISHED: 'Web App Onboarding Finished',
  WEB_APP_ONBOARDING_CANCELLED: 'Web App Onboarding Cancelled',

  GROWTH_GRANT_PERMISSIONS: 'Growth Grant Permissions',
  GROWTH_INVITE_USER: 'Growth Invite User',
  GROWTH_OPEN_ONE_ON_ONE: 'Growth Open One on One',
  GROWTH_OPEN_TEAMS_CONVERSATION: 'Growth Open Teams Conversation',
  GROWTH_OPEN_ITEM: 'Growth Open Item',

  OPEN_TEAMS_DEEPLINK: 'Open Teams Deeplink',

  TRIAL_UPGRADE_PRESSED: 'Trial Upgrade Pressed',
  INIT_FULL_PERMISSIONS_EXPERIMENT: 'Init Login Experiment',

  CLICK_PROJECTS_LEFT_MENU_OPTION: 'Click Projects Left Menu Option',

  IN_APP_NOTIFICATION_INTERACTION: 'In-App Notification Interaction',

  ITEM_CONTEXTUAL_MENU_OPEN: 'Item Contextual Menu Open',
  AI_FILL_NOTES: 'AI Fill Notes',
  AI_CREATE_PROJECT: 'AI Create Project',

  LINK_ITEM_TO_PROJECT: 'Link Item To Project',
}

const TEMPLATE_TYPE = {
  CUSTOM: 'Custom',
  PUBLIC: 'Public',
}

export const EVENT_EXTRA = {
  CREATE_PROJECT: {
    MODE: {
      CONFIG_PANEL: 'ConfigPanel',
      LEFT_PANEL_PROJECTS_BUTTON: 'LeftPanelProjectsButton',
      LEFT_PANEL_ADD_BUTTON: 'LeftPanelAddButton',
      PROJECT_CARDS: 'ProjectCards',
      PROJECT_LIST_TOP_BAR: 'ProjectListTopBar',
      APP_TOP_BAR: 'AppTopBar',
    },
  },
  CLICK_PROJECTS_LEFT_MENU_OPTION: {
    MODE: {
      SHOW_ALL_PROJECTS: 'ShowAllProjects',
      OPEN_RECENT_PROJECT: 'OpenRecentProject',
      CREATE_PROJECT: 'CreateProject',
    },
  },
  CREATE_ITEM: {
    MODE: {
      MATRIX_TOP_BAR: 'MatrixTopBar',
      ONE_ON_ONE_TOP_BAR: 'OneOnOneTopBar',
      ONE_ON_ONE_HEADER: 'OneOnOneHeader',
      DOUBLE_CLICK: 'DoubleClick',
      QUADRANT: 'QuadrantButton',
      INBOX: 'InboxButton',
      MEETING_AGENDA: 'MeetingAgenda',
      GRAPH: 'GraphDetails',
      MATRIX_DRAG_DROP: 'MatrixDragDrop',
      HOME_AGENDA: 'HomeAgenda',
      HOME_TOP_BAR: 'HomeTopBar',
      CONTEXTUAL_MENU: 'ContextualMenu',
      PLANNING_EFFORT: 'PlanningEffort',
      LINKED_ITEM: 'LinkedItem',
      ITEM_PLACEHOLDER: 'ItemPlaceholder',
      DUPLICATE_ITEM: 'DuplicateItem',
      APP_TOP_BAR: 'AppTopBar',
      SHARE_INTENT: 'ShareIntent',
      SHARE_INTENT_FILE: 'ShareIntentFile',
    },
  },
  OPEN_FOLLOWERS_PANEL: {
    MODE: {
      AVATARS: 'Avatars',
      CHAT_BOX: 'ChatBox',
    },
  },
  INSTANTIATE_TEMPLATE: {
    TYPE: TEMPLATE_TYPE,
  },
  CREATE_TEMPLATE: {
    TYPE: TEMPLATE_TYPE,
  },
  TRIAL_UPGRADE_PRESSED: {
    MODE: {
      PAYWALL: 'Paywall',
      MESSAGE_BAR: 'Message Bar',
      RIBBON: 'Ribbon',
      SEARCH_LOOKBACK: 'Search Lookback',
      LEFT_PANEL: 'Left Panel',
      LEFT_PANEL_ACCOUNT: 'Left Panel Account',
      TEAMS_NAV_BAR: 'Teams Nav Bar',
      FREEMIUM_QUOTA_CARD: 'Freemium Quota Card',
      MOBILE_NAV_BAR: 'Mobile Nav Bar',
      ITEM_CREATION: 'Item Creation',
      PROJECT_CREATION: 'Project Creation',
      INVITE_MEMBER: 'Invite Member',
      AI_NOTES: 'AI Notes',
    },
  },
  WEB_PUSH: {
    STATUS: {
      ALREADY_OPENED: 'Already opened',
      OPEN_NEW_TAB: 'Open New Tab',
    },
  },
  ITEM_ACTION: {
    FROM: {
      CONTEXTUAL_MENU: 'Contextual Menu',
    },
  },
  AI_FILL_NOTES: {
    MODE: {
      CREATE_ITEM_MODAL: 'Create Item Modal',
      ITEM_DETAIL: 'Item Detail',
    },
  },
  INTERACTION_TYPE: {
    KEY: 'interaction_type',
    VALUE: {
      ACTIVE: 'active',
      PASSIVE: 'passive',
    },
  },
  LINK_ITEM_TO_PROJECT: {
    MODE: {
      CREATE_ITEM: 'Create Item',
      CONVERT_ITEM: 'Convert Item',
      LINK_EXISTING_PROJECT: 'Link Existing Project',
    },
  },
}

export const SESSION = {
  TEAMS: 'Teams',
  ELECTRON: 'Electron',
  OUTLOOK: 'Outlook',
  ANDROID_WEBVIEW: 'Android Webview',
  IOS_WEBVIEW: 'Ios Webview',
  REACT_NATIVE: 'React Native',
  EMBEDDED: 'Embedded',
  PAYING_SINGLE: 'paying_single',
  PAYING_TEAM: 'paying_team',
  COLLABORATORS: 'collaborators',
  WEBAPP_FLAVOUR: 'Webapp Flavour',
}

const ACTION_TYPE_BASE = 'ANALYTICS'

export const ACTION_TYPES = _.mapValues(EVENTS, (v, k) => `${ACTION_TYPE_BASE}/${k}`)

// defined as let due to autocompletion hack
export let AMPLITUDE_ACTION_TYPES = EVENTS
AMPLITUDE_ACTION_TYPES = ACTION_TYPES

export const dispatchEvent = (event, eventProperties = {}) => ({
  type: event,
  payload: eventProperties,
})

export const PostHogMappings = {
  [EVENTS.APP_STARTED]: '$pageview',
  [EVENTS.CREATE_ITEM]: 'Create Item',
}

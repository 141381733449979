import { useMemo, useEffect, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { map, scan, distinctUntilChanged, filter } from 'rxjs/operators'
import { fromEvent, interval, merge, throttleTime, Subject } from 'rxjs'
import { Modal } from '@fluentui/react/lib/Modal'
import { useDispatch } from 'react-redux'
import { useAsyncEffect } from '../../common/src/hooks/useAsyncEffect'
import { DEVELOPMENT } from '../../common/src/environment'
import { INACTIVIY_LOGOUT } from '../../environment'
import { useTranslation } from 'react-i18next'
import { FlexRow } from '../layout/FlexContainer'
import { isEmbedded, isEmbeddedOnOutlook, isEmbeddedOnTeams } from '../../helpers/queryParamsHelper'
import { useExtendedHistory } from '../../hooks/useExtendedHistory'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { isValid, differenceInSeconds } from 'date-fns'
import { useInactivityLogout } from '../../queries/config'

const DATE_0 = new Date(0)

const SRow = styled(FlexRow)`
  justify-content: space-between;
  & > * {
    margin: 0 10px 24px 10px;
  }
`

const Container = styled.div`
  min-width: 200px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Text = styled.h3`
  font-size: 15px;
  color: ${p => p.theme.palette.black};
  margin: 20px;
`

const Countdown = styled(Text)`
  font-size: 30px;
`

const STATUS = {
  PLAYING: 'playing',
  GONE: 'gone',
}

const EVT = {
  DECREASE: 'decrease',
  SOFT_RESET: 'reset',
  TAB_SYNC_RESET: 'tab_sync_reset',
  HARD_RESET: 'hard_reset',
  FORCE_FINISH: 'force_finish',
}

const MINUTE = 60

const counterReducer = inactivityPeriodInSecs => (count, evt) => {
  if (evt === EVT.SOFT_RESET && count > MINUTE) {
    return inactivityPeriodInSecs
  }
  if (evt === EVT.HARD_RESET || evt === EVT.TAB_SYNC_RESET) {
    return inactivityPeriodInSecs
  }
  if (evt === EVT.DECREASE) {
    return count - 1
  }
  if (evt === EVT.FORCE_FINISH) {
    return 0
  }
  return count
}

const isLocalResetEvent = evt => evt === EVT.SOFT_RESET || evt === EVT.HARD_RESET

const counterToState = (state, count) => {
  if (state === STATUS.GONE || count <= 0) return STATUS.GONE
  if (count < MINUTE) return count
  return STATUS.PLAYING
}

let lastTimestampEmited = null

const readLoginSession = () => {
  try {
    return window.localStorage.getItem('login_session')
  } catch (err) {
    console.log(err)
    return undefined
  }
}

const writeLoginSession = date => {
  try {
    const loginDateString = date.toISOString()
    window.localStorage.setItem('login_session', loginDateString)
    lastTimestampEmited = loginDateString
  } catch (err) {
    console.log(err)
  }
}

const useSetup = () => {
  const [state, setState] = useState(STATUS.PLAYING)
  const hardResetSubject = useMemo(() => new Subject(), [])
  const triggerHardReset = () => hardResetSubject.next(EVT.HARD_RESET)
  const triggerForceFinish = () => hardResetSubject.next(EVT.FORCE_FINISH)
  const inactivityPeriodInSecs = useInactivityLogout()
  useEffect(() => {
    if (!INACTIVIY_LOGOUT || inactivityPeriodInSecs === 0) {
      return
    }

    const isSessionExpired = d => differenceInSeconds(new Date(), d) > inactivityPeriodInSecs

    const loginDate = new Date(readLoginSession())
    const validLoginDate = isValid(loginDate)
    const expired = validLoginDate && isSessionExpired(loginDate)
    const validSession = validLoginDate && !expired
    const embedded = isEmbedded() || isEmbeddedOnOutlook() || isEmbeddedOnTeams()
    if (!validSession && !embedded) {
      setState(STATUS.GONE)
      return
    }
    const resetEvents$ = merge(fromEvent(document, 'mousemove', true), fromEvent(document, 'keypress', true)).pipe(
      map(() => EVT.SOFT_RESET),
      throttleTime(800)
    )
    const intervalSource$ = interval(1000)
    const decreateIntervalEvent$ = intervalSource$.pipe(map(() => EVT.DECREASE))
    const localStorageSyncEvent$ = intervalSource$.pipe(
      map(() => readLoginSession()),
      distinctUntilChanged(),
      filter(d => d !== lastTimestampEmited),
      map(loginSessionString => {
        const date = new Date(loginSessionString)
        if (!isValid(date)) {
          return EVT.FORCE_FINISH
        }
        const expired = isSessionExpired(date)
        if (expired) {
          return EVT.FORCE_FINISH
        }
        return EVT.TAB_SYNC_RESET
      })
    )
    const events$ = merge(resetEvents$, decreateIntervalEvent$, hardResetSubject, localStorageSyncEvent$)
    const localStorageSyncStream = events$.pipe(
      filter(isLocalResetEvent),
      filter(() => lastTimestampEmited !== DATE_0.toISOString())
    )
    const localStorageSyncSubscription = localStorageSyncStream.subscribe(() => writeLoginSession(new Date()))
    const counterStream = events$.pipe(scan(counterReducer(inactivityPeriodInSecs), inactivityPeriodInSecs))
    const stateStream = counterStream.pipe(scan(counterToState, STATUS.PLAYING), distinctUntilChanged())
    const stateSubscription = stateStream.subscribe(v => setState(() => v))
    const clearSessionStream = stateStream.pipe(filter(s => s === STATUS.GONE))
    const clearSessionSubscription = clearSessionStream.subscribe(() => writeLoginSession(DATE_0))
    return () => {
      stateSubscription.unsubscribe()
      localStorageSyncSubscription.unsubscribe()
      clearSessionSubscription.unsubscribe()
    }
  }, [inactivityPeriodInSecs, hardResetSubject])
  return { state, triggerHardReset, triggerForceFinish }
}

const useSignOutOnGone = isGone => {
  const dispatch = useDispatch()
  const { history } = useExtendedHistory()
  useAsyncEffect(async () => {
    if (isGone) {
      if (DEVELOPMENT) {
        console.log('gone')
      } else {
        const url = getRelativeURLKeepingQuerySearch.inactivitySignOut()
        history.push(url)
      }
    }
  }, [isGone, dispatch])
}

export const InactivityManager = ({ children }) => {
  const { state, triggerHardReset, triggerForceFinish } = useSetup()
  const { t } = useTranslation()
  const isGone = state === STATUS.GONE
  useSignOutOnGone(isGone)
  const isOpen = _.isFinite(+state)
  const text = isOpen ? state : ''
  return (
    <>
      <Modal isBlocking isOpen={isOpen}>
        <Container>
          <Text>{t('inactivity.modal')}</Text>
          <Countdown>{text}</Countdown>
          <SRow>
            <PrimaryButton onClick={triggerHardReset}>{t('general.continue')}</PrimaryButton>
            <PrimaryButton onClick={triggerForceFinish}>{t('left_panel.account.sign_out')}</PrimaryButton>
          </SRow>
        </Container>
      </Modal>
      {children}
    </>
  )
}

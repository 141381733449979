import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { set, getHours, getMinutes } from 'date-fns'

export const useDidChangeDate = () =>
  useCallback(
    (oldDate, callback) => selectedDate => {
      if (!selectedDate) {
        return callback()
      }
      //keep same hour and minute
      const adjusted = set(selectedDate, {
        minutes: oldDate ? getMinutes(oldDate) : 0,
        hours: oldDate ? getHours(oldDate) : 9,
      })
      callback(adjusted)
    },
    []
  )

export const useDidChangeTime = () =>
  useCallback(
    (date, callback) => (hours, minutes) => {
      const copiedDate = new Date(date)
      copiedDate.setHours(hours)
      copiedDate.setMinutes(minutes)
      callback(copiedDate)
    },
    []
  )

//TODO move to translations/lang/dates
export const useDatePickerStrings = () => {
  const { i18n } = useTranslation()
  return useMemo(() => {
    if (i18n.language.startsWith('es')) {
      return (minDate, maxDate) => {
        let isOutOfBoundsErrorMessage = ''
        if (minDate && maxDate) {
          isOutOfBoundsErrorMessage = `La fecha debe estar entre ${minDate.toLocaleDateString()}-${maxDate.toLocaleDateString()}`
        } else if (minDate) {
          isOutOfBoundsErrorMessage = `La fecha debe ser mayor que ${minDate.toLocaleDateString()}}`
        } else if (maxDate) {
          isOutOfBoundsErrorMessage = `La fecha debe ser menor que ${maxDate.toLocaleDateString()}`
        }

        return {
          months: [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
          shortDays: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],

          goToToday: 'Ir a hoy',
          prevMonthAriaLabel: 'Ir al mes anterior',
          nextMonthAriaLabel: 'Ir al próximo mes',
          prevYearAriaLabel: 'Ir al año anterior',
          nextYearAriaLabel: 'Ir al próximo año',
          closeButtonAriaLabel: 'Cerrar selector de fecha',
          isOutOfBoundsErrorMessage,
        }
      }
    }
    // Fluent UI DatePicker displays in English by default if 'strings' param is not defined
    return (minDate, maxDate) => undefined
  }, [i18n.language])
}

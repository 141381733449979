import { useState, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { fromJS } from 'immutable'
import styled from 'styled-components'
import _ from 'lodash'
import { cn } from '@appfluence/classnames'
import { useToastController } from '@fluentui/react-components'
import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import './Matrix.css'
import { Quadrant } from './Quadrant'
import { itemHelper, projectHelper, stateHelper } from '../../common/src/helpers'
import { ResizableBox } from 'react-resizable'
import { useResizeDetector } from 'react-resize-detector'
import { sendItem } from '../../common/src/actions/combinedAPI'
import { ITEMS_SORT_TYPE } from '../../common/src/actions/filtersActions'
import { neededFiltersTip } from '../../reactions'
import { getProjectCenter } from '../../common/src/selectors/projectSelectors'
import { setProjectCenter } from '../../common/src/actions/projectActions'
import { DragAndDrop, SupportedTypes } from '../generic/DragAndDrop'
import { useCreateNewItemCustom, useMarkCompleted, useTriggerItemDeletionModal } from '../../hooks/itemHooks'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { FileContext } from '../../contexts'
import { getItemsSortType, getItemsStarredFirst, isFilteringItems } from '../../common/src/selectors/filtersSelectors'
import { useIsDarkTheme } from '../../themes'
import { BottomCenterArchived } from '../basic/Archived'
import { useTranslation } from 'react-i18next'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { FlexRow } from '../layout/FlexContainer'
import { FluentToast } from '../toast/FluentToast'
import { getFilenameData } from '../../utils'
import { Subject } from 'rxjs'
import { BinRecycleRegular, CheckmarkFilled } from '@fluentui/react-icons'
import { VibrateMode, vibrate } from '@/utils/ExpoCommunication'
import bindEvents from '@hello-pangea/dnd/src/view/event-bindings/bind-events'
import { computeQuadrantColors } from './MatrixUtils'
import { useItemFilter } from '@/common/src/hooks/filtersHooks'

const DroppableId = {
  Delete: 'delete',
  Complete: 'complete',
}

const Row = styled(FlexRow)`
  ${p => p.flex && 'flex: 1; min-height: 1px;'}
`

const quadrantMargin = 0

const QuadrantBox = styled.div`
  flex: 1;
  overflow: auto;
  margin: ${quadrantMargin}px;
  background: ${p => p.background || 'white'};
`

const MainQuadrantContent = styled.div`
  margin: ${quadrantMargin}px;
  overflow: auto;
  background: ${p => p.background || 'white'};
`

const DragAndDropContainer = styled(DragAndDrop).attrs({
  supportedTypes: [SupportedTypes.Files, SupportedTypes.Link, SupportedTypes.Text],
  styles: {
    subContainer: {
      fontSize: '30px',
    },
    icon: {
      fontSize: '60px',
    },
  },
})``

const getQ0Style = qRect => {
  return {
    height: qRect.height - quadrantMargin,
    width: qRect.width - quadrantMargin,
  }
}

const getQ2Style = qRect => {
  const w = qRect.width - quadrantMargin
  const s = {}
  if (w) {
    s.minWidth = w
    s.maxWidth = w
  }
  return s
}

const getFirstRowStyle = qRect => {
  const h = qRect.height + quadrantMargin
  const s = {}
  if (h) {
    s.height = h
  }
  return s
}

const setSafePercentage = p => (p < 0.2 ? 0.2 : p > 0.8 ? 0.8 : p)

const LOCAL_ACTION_TYPES = {
  SET_RESIZING: 'SET_RESIZING',
  SET_MATRIX_RECT: 'SET_MATRIX_RECT',
  SET_QUADRANT_RECT: 'SET_QUADRANT_RECT',
  SET_QUADRANT_PERCENTAGES: 'SET_QUADRANT_PERCENTAGES',
}

const actionHandler = {
  [LOCAL_ACTION_TYPES.SET_RESIZING]: (state, { resizing }) => {
    return { ...state, resizing }
  },
  [LOCAL_ACTION_TYPES.SET_QUADRANT_RECT]: (state, { size }) => {
    const { mRect } = state
    const percent = {
      heightPercent: setSafePercentage(size.height / mRect.height),
      widthPercent: setSafePercentage(size.width / mRect.width),
    }
    const qRect = {
      ...percent,
      height: percent.heightPercent * mRect.height,
      width: percent.widthPercent * mRect.width,
    }
    return { ...state, qRect }
  },
  [LOCAL_ACTION_TYPES.SET_QUADRANT_PERCENTAGES]: (state, { point }) => {
    const { mRect } = state
    const percent = {
      heightPercent: point.y,
      widthPercent: point.x,
    }
    const qRect = {
      ...percent,
      height: percent.heightPercent * mRect.height,
      width: percent.widthPercent * mRect.width,
    }
    return { ...state, qRect }
  },
  [LOCAL_ACTION_TYPES.SET_MATRIX_RECT]: (state, { mRect }) => {
    const qRect = {
      ...state.qRect,
      height: state.qRect.heightPercent * mRect.height,
      width: state.qRect.widthPercent * mRect.width,
    }
    return { ...state, qRect, mRect }
  },
}

const localReducer = (state, action) => {
  const fn = actionHandler[action.type]
  if (fn) {
    return fn(state, action)
  }
  return state
}

const baseState = {
  resizing: false,
  mRect: {
    height: 1,
    width: 1,
  },
  qRect: {
    height: 1,
    width: 1,
    heightPercent: 0.5,
    widthPercent: 0.5,
  },
}

const MS_FOR_30FPS = 1000 / 30
const minWidthForNarrowTextStyles = 150

export const Matrix = ({
  items,
  onClickItem,
  project,
  selectedItemId,
  sortFn,
  readOnly = false,
  loading = false,
  children,
  showOwner = true,
  filtersType,
}) => {
  const store = useStore()
  const dispatch = useDispatch()
  const { dispatchToast } = useToastController()
  const [{ resizing, mRect, qRect }, localDispatch] = useReducer(localReducer, baseState)
  const isFiltering = useSelector(isFilteringItems)
  const createNewItem = useCreateNewItemCustom()
  const { uploadFiles } = useContext(FileContext)
  const { t } = useTranslation()
  const isMobileVersion = useNarrowWidth()
  const isDarkTheme = useIsDarkTheme()
  const [dragging, setDragging] = useState('none')
  const triggerItemDeletionModal = useTriggerItemDeletionModal()
  const [itemIdToMarkAsCompleted, setItemIdToMarkAsCompleted] = useState(null)
  const itemToMarkAsCompleted = useSelector(state => stateHelper.getItem(state, itemIdToMarkAsCompleted))
  const { markCompleted } = useMarkCompleted(itemToMarkAsCompleted)

  useEffect(() => {
    if (itemIdToMarkAsCompleted) {
      markCompleted(100)
      setItemIdToMarkAsCompleted(null)
    }
  }, [itemIdToMarkAsCompleted, markCompleted])

  const placeholdersByType = useMemo(() => {
    return {
      [SupportedTypes.Files]: t('quadrant.drag_and_drop_file_placeholder'),
      [SupportedTypes.Link]: t('quadrant.drag_and_drop_link_placeholder'),
      [SupportedTypes.Text]: t('quadrant.drag_and_drop_text_placeholder'),
    }
  }, [t])

  // Actions
  const setResizing = useCallback(
    r => {
      localDispatch({
        type: LOCAL_ACTION_TYPES.SET_RESIZING,
        resizing: r,
      })
    },
    [localDispatch]
  )

  const setQuadrantPercentages = useCallback(
    point => {
      localDispatch({
        type: LOCAL_ACTION_TYPES.SET_QUADRANT_PERCENTAGES,
        point,
      })
    },
    [localDispatch]
  )

  const setQuadrantSize = useCallback(
    size => {
      localDispatch({
        type: LOCAL_ACTION_TYPES.SET_QUADRANT_RECT,
        size,
      })
    },
    [localDispatch]
  )

  // Update matrix with the last center saved.
  const projectID = projectHelper.getIdd(project)
  const lastCenter = useSelector(state => getProjectCenter(state, projectID))
  useEffect(() => {
    if (!lastCenter) {
      setQuadrantPercentages({ x: 0.5, y: 0.5 })
      return
    }
    setQuadrantPercentages(lastCenter)
  }, [lastCenter, setQuadrantPercentages])

  // Resizing matrix
  const onResizeMatrix = ({ width, height }) => {
    if (width === null || height === null) return
    localDispatch({
      type: LOCAL_ACTION_TYPES.SET_MATRIX_RECT,
      mRect: { height, width },
    })
  }
  const { ref: resizeDetectorRef } = useResizeDetector({
    handleHeight: true,
    handleWidth: true,
    onResize: onResizeMatrix,
  })

  // Resizing quadrants
  const setQuadrantSizeThrottled = useMemo(() => _.throttle(setQuadrantSize, MS_FOR_30FPS), [setQuadrantSize])
  const onResizeQuadrant = useCallback(
    (ev, data) => {
      const { size } = data
      setQuadrantSizeThrottled(size)
      ev.stopPropagation()
    },
    [setQuadrantSizeThrottled]
  )
  const onResizeStartQuadrant = useCallback(() => setResizing(true), [setResizing])
  const onResizeStopQuadrant = useCallback(() => {
    setResizing(false)

    // Save center
    const { widthPercent, heightPercent } = qRect
    const center = { x: widthPercent, y: heightPercent }
    dispatch(setProjectCenter(projectID, center))
  }, [dispatch, setResizing, qRect, projectID])

  // Group by quadrant
  const itemsByQuadrant = useMemo(() => {
    if (!items) {
      return null
    }

    return items.groupBy(itemHelper.getQuadrant).map(arr => arr.sort(sortFn))
  }, [items, sortFn])

  // Manage drop
  const handleDropInQuadrant = useCallback(
    q =>
      async ({ text, link, files, notes }) => {
        const fileName = files && _.head(files)?.name
        const name = text ? text : fileName
        if (!name) {
          return
        }

        const extension = fileName && getFilenameData(fileName)?.extension

        const response = await createNewItem({
          name: name,
          notes,
          link,
          quadrant: q,
          projectID,
          eventProps: {
            mode: EVENT_EXTRA.CREATE_ITEM.MODE.MATRIX_DRAG_DROP,
            quadrant: q,
            fileExtension: extension,
          },
        })
        const { error, payload } = response
        if (!error && !_.isEmpty(files)) {
          uploadFiles({ item: payload, files })
        }
      },
    [createNewItem, projectID, uploadFiles]
  )

  const mousePositionRef = useRef({ x: 0, y: 0 })
  useEffect(() => {
    return bindEvents(window, [
      {
        eventName: 'mousemove',
        fn: event => {
          mousePositionRef.current = {
            x: event.clientX,
            y: event.clientY,
          }
        },
        options: { passive: true },
      },
      {
        eventName: 'touchstart',
        fn: event => {
          mousePositionRef.current = {
            x: event.touches[0].clientX,
            y: event.touches[0].clientY,
          }
        },
      },
    ])
  }, [])

  const showToastNewItemHiddenByFilters = useCallback(() => {
    dispatchToast(<FluentToast>{t('quadrant.quick_item_hidden_by_filters')}</FluentToast>, {
      intent: 'info',
    })
    neededFiltersTip.next(true)
  }, [dispatchToast, t])

  const showToastMixingStarredAndNonStarredItems = () => {
    dispatchToast(<FluentToast>{t('quadrant.drag_not_allowed_starred_first')}</FluentToast>, {
      intent: 'info',
    })
    neededFiltersTip.next(true)
  }

  const itemFilter = useItemFilter(filtersType)
  const onQuickItemCreated = useCallback(
    item => {
      //check whether item is hidden by filters
      const filtered = fromJS([item]).filter(itemFilter)
      if (filtered.size === 0) {
        showToastNewItemHiddenByFilters()
      }
    },
    [itemFilter, showToastNewItemHiddenByFilters]
  )

  if (!project || !itemsByQuadrant) {
    return false
  }

  // useCallback
  const onDragEnd = ({ draggableId, destination, source, ...rest }) => {
    setDragging('none')
    vibrate(VibrateMode.ImpactMedium)
    if (!destination || _.isEqual(source, destination)) {
      return
    }
    const { index, droppableId } = destination
    const state = store.getState()
    const serverID = parseInt(draggableId)
    const item = stateHelper.getItem(state, serverID)
    if (droppableId === DroppableId.Complete) {
      setItemIdToMarkAsCompleted(serverID)
      return
    } else if (droppableId === DroppableId.Delete) {
      triggerItemDeletionModal({ item })
      return
    }
    const sortMode = getItemsSortType(state)
    const starredFirstEnabled = getItemsStarredFirst(state)
    const sameQuadrant = droppableId === source.droppableId
    if (sortMode !== ITEMS_SORT_TYPE.INDEX && sameQuadrant) {
      dispatchToast(<FluentToast>{t('quadrant.drag_not_allowed_sort_mode')}</FluentToast>, {
        intent: 'info',
      })
      neededFiltersTip.next(true)
      return
    }
    const quadrant = _.toInteger(droppableId[1]) // droppableId example "q0"
    const destItemList = itemsByQuadrant.get(quadrant)
    const needsToAdjustItemIndex = sameQuadrant && source.index < index
    const prevIndex = needsToAdjustItemIndex ? index : index - 1
    let prevItem = (prevIndex >= 0 && destItemList.get(prevIndex)) || undefined
    const nextIndex = prevIndex + 1
    let nextItem = (destItemList && destItemList.size > 0 && destItemList.get(nextIndex)) || undefined
    // In starredFirst mode, avoid puting a starred item between non-starred items
    if (starredFirstEnabled && itemHelper.isStarred(item) && prevItem && !itemHelper.isStarred(prevItem)) {
      showToastMixingStarredAndNonStarredItems()
      return
    }
    // In starredFirst mode, avoid puting a non-starred item between starred items
    if (starredFirstEnabled && !itemHelper.isStarred(item) && nextItem && itemHelper.isStarred(nextItem)) {
      showToastMixingStarredAndNonStarredItems()
      return
    }
    // Moving starred item to the end of the "Starred" section
    if (starredFirstEnabled && itemHelper.isStarred(item) && !itemHelper.isStarred(nextItem)) {
      nextItem = null
    }
    // Moving non-starred item to the start of the "Non-Starred" section
    if (starredFirstEnabled && itemHelper.isStarred(prevItem) && !itemHelper.isStarred(item)) {
      prevItem = null
    }
    const modifiedItem = item.withMutations(i_ => {
      i_ = itemHelper.setQuadrant(i_, quadrant)
      return itemHelper.setIndexBetweenItems(i_, prevItem, nextItem)
    })
    store.dispatch(sendItem(modifiedItem, true))
  }

  const firstColumnWidth = qRect.width
  const secondColumnWidth = mRect.width - qRect.width
  const quadrantListClassName = cn({ resizing })
  const Q = [0, 1, 2, 3].map((i, k) => {
    const name = projectHelper.getSafeQuadrantName(project, i)
    const colors = computeQuadrantColors({ project, quadrant: i, isDarkTheme })
    const width = i % 2 === 0 ? firstColumnWidth : secondColumnWidth
    const narrow =
      (isMobileVersion && projectHelper.getOwnersIDs(project).size === 1) || width <= minWidthForNarrowTextStyles

    return (
      <DragAndDropContainer placeholdersByType={placeholdersByType} onDrop={handleDropInQuadrant(i)} key={i}>
        <Quadrant
          items={itemsByQuadrant.get(i)}
          isFiltering={isFiltering}
          onClick={onClickItem}
          headerBackgroundColor={colors.header.background}
          headerTextColor={colors.header.text}
          bodyBackgroundColor={colors.main.background}
          cellTextColor={colors.main.text}
          name={name}
          selectedItemId={selectedItemId}
          key={i}
          listClassName={quadrantListClassName}
          idx={i}
          narrow={narrow}
          readOnly={readOnly}
          loading={loading}
          project={project}
          showOwner={showOwner}
          onQuickItemCreated={onQuickItemCreated}
        />
      </DragAndDropContainer>
    )
  })

  const onBeforeCapture = before => {
    CaptureDragSubject.next('onBeforeCapture')
    window.dispatchEvent(
      new CustomEvent('onBeforeCapture', {
        detail: { before, mousePosition: mousePositionRef.current },
      })
    )
  }

  const onDragStart = (...t) => {
    setDragging('dragging')
    vibrate(VibrateMode.ImpactMedium)
  }

  const onBeforeDragStart = (...t) => {
    setDragging('beforeStart')
  }

  return (
    <div className="relative flex h-full w-full flex-col bg-pm-neutral-light" ref={resizeDetectorRef}>
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeCapture={onBeforeCapture}
        onDragStart={onDragStart}
        onBeforeDragStart={onBeforeDragStart}
      >
        <Row style={getFirstRowStyle(qRect)}>
          <ResizableBox
            height={qRect.height}
            width={qRect.width}
            minConstraints={[0.2 * mRect.width, 0.2 * mRect.height]}
            maxConstraints={[0.8 * mRect.width, 0.8 * mRect.height]}
            onResize={onResizeQuadrant}
            onResizeStart={onResizeStartQuadrant}
            onResizeStop={onResizeStopQuadrant}
            id="main-resizable-quadrant"
          >
            <MainQuadrantContent style={getQ0Style(qRect)}>{Q[0]}</MainQuadrantContent>
          </ResizableBox>
          <QuadrantBox style={{ marginLeft: 2 * quadrantMargin }}>{Q[1]}</QuadrantBox>
        </Row>
        <Row flex>
          <QuadrantBox style={getQ2Style(qRect)}>{Q[2]}</QuadrantBox>
          <QuadrantBox>{Q[3]}</QuadrantBox>
        </Row>
        <FloatingContainer
          icon={<BinRecycleRegular className="absolute top-0 z-20 m-[25%] text-pm-negative" />}
          style={{ fontSize: `min(${mRect.width * 0.07}px, 50px)` }}
          status={dragging}
          className="bottom-2 left-2"
          droppableId={DroppableId.Delete}
        />
        <FloatingContainer
          icon={
            <CheckmarkFilled
              className="absolute top-0 z-20 m-[25%] text-pm-affirmative"
              style={{ fontSize: `min(${mRect.width * 0.07}px, 50px)` }}
            />
          }
          status={dragging}
          className="bottom-2 right-2"
          droppableId={DroppableId.Complete}
        />
      </DragDropContext>
      {children}
      <BottomCenterArchived />
    </div>
  )
}

const FloatingContainer = ({ className, status, droppableId, icon, style }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => {
        const { isDraggingOver } = snapshot
        const finalClassName = cn(
          'relative matrix-floating-droppable-glass absolute z-10 w-[16%] aspect-square bg-white rounded-full transition-all',
          className,
          {
            pm_hidden: status === 'none',
            'opacity-100': status === 'dragging',
            '!w-0': status === 'beforeStart',
            'w-[15%]': status === 'dragging',
            '!w-[16%]': isDraggingOver,
            '!max-w-[100px]': status === 'dragging',
            '!max-w-[105px]': isDraggingOver,
            'transition-all': ['beforeStart', 'dragging'].includes(status),
          }
        )

        return (
          <DroppableFloatingContainer className={finalClassName} style={style}>
            <div className="relative h-full w-full" ref={provided.innerRef} {...provided.droppableProps}>
              {provided.placeholder}
            </div>
            {icon}
          </DroppableFloatingContainer>
        )
      }}
    </Droppable>
  )
}

const DroppableFloatingContainer = ({ className, children, style }) => {
  const containerRef = useRef(null)
  useEffect(() => {
    // We need to set the size of the element before starting the grag.
    // That's why we listen beforeCapture
    const subscription = CaptureDragSubject.subscribe(() => {
      if (containerRef?.current) {
        containerRef.current.classList.remove('pm_hidden')
      }
    })
    return () => subscription.unsubscribe()
  }, [containerRef])
  return (
    <div style={style} className={className} ref={containerRef}>
      {children}
    </div>
  )
}

const CaptureDragSubject = new Subject()

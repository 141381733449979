import React, { useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { FlexContainer } from '../components/layout/FlexContainer'
import { PMLogo } from '../components/Logo'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { useDispatch, useSelector } from 'react-redux'
import { EVENT_EXTRA } from '../common/src/eventTracking/amplitudeEvents'
import { SERVER_URLS } from '../common/src/constants'
import { useMeEmail } from '../common/src/hooks/usersHooks'
import { getPaywallNext } from '../helpers/queryParamsHelper'
import { getPathnameKeepingQuerySearch } from '../helpers/routeHelper'
import { useExtendedHistory } from '../hooks/useExtendedHistory'
import { TeamsHeader } from '../components/layout/TeamsHeader'
import { MainLayout } from '../components/layout/MainLayout'
import { authSelectors } from '../common/src/selectors'
import { useTranslation } from 'react-i18next'
import { openPurchaseExperienceAction } from '../utils/teams'
import { useConfig } from '../queries/config'
import { useShouldDisplayUpgrade } from '@/hooks/userHooks'

const Logo = styled(PMLogo)`
  height: 128px;
  width: 128px;
`

const Title = styled.h2`
  color: ${p => p.theme.palette.neutralPrimary};
`

const Info = styled.span`
  color: ${p => p.theme.palette.neutralPrimary};
  max-width: 640px;
`

const Extra = styled(Info)`
  margin-top: 20px;
  & a {
    color: ${p => p.theme.palette.themePrimary};
  }
`

const useNavigation = () => {
  const licensed = useSelector(authSelectors.hasLicense)
  const { history } = useExtendedHistory()
  useEffect(() => {
    if (licensed) {
      let next = getPaywallNext()
      if (!next || _.startsWith(next, '/paywall')) {
        next = '/'
      }
      const to = getPathnameKeepingQuerySearch(next)
      history.replace(to)
    }
  }, [licensed, history])
}

const getInfoStrings = ({ showAdminMessages, isPrivateCloud, freemiumAllowed }) => {
  if (isPrivateCloud) {
    return ['paywall_strings.info_private_cloud']
  }
  const strings = ['paywall_strings.info_expired']
  if (!freemiumAllowed) {
    return strings
  }
  strings.push(showAdminMessages ? 'paywall_strings.info_freemium_admin' : 'paywall_strings.info_freemium_non_admin')
  return strings
}

const Component_ = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const email = useMeEmail()
  const admin = useSelector(authSelectors.admin)
  const { data: config } = useConfig()
  const isPrivateCloud = config?.public === false
  const freemiumAllowed = config?.free_tier
  const shouldDisplayUpgrade = useShouldDisplayUpgrade()
  const isAdmin = admin === email
  useNavigation()
  const handleClickUpgrade = async () => {
    dispatch(openPurchaseExperienceAction(EVENT_EXTRA.TRIAL_UPGRADE_PRESSED.MODE.PAYWALL))
  }
  const mailToAdmin = `mailto:${admin}?subject=Subscribing to Priority Matrix&body=See https://appfluence.com/pricing/ `
  const needsExtra = !!admin && !isAdmin
  const showAdminMessages = isAdmin || !admin
  const infoStrings = getInfoStrings({ showAdminMessages, isPrivateCloud, freemiumAllowed })
  const showUpgrade = !isPrivateCloud && shouldDisplayUpgrade
  const actions = showAdminMessages ? (
    <DefaultButton
      text={t('paywall_strings.extend_trial')}
      href={`${SERVER_URLS.AUTO_EXTEND_TRIAL7}?email=${email}`}
      target="_blank"
    />
  ) : (
    <>
      <DefaultButton text={t('paywall_strings.account_details')} href={SERVER_URLS.MANAGE_ACCOUNT} target="_blank" />
      <DefaultButton text={t('paywall_strings.contact_admin')} href={mailToAdmin} target="_blank" />
    </>
  )
  const main = (
    <FlexContainer>
      <Logo />
      <Title>{t('paywall_strings.title')}</Title>
      <Info>{infoStrings.map(s => t(s, { email, admin })).join(' ')}</Info>
      <div className="mt-5 flex gap-2">
        {showUpgrade && <PrimaryButton text={t('general.upgrade')} onClick={handleClickUpgrade} />}
        {!isPrivateCloud && actions}
      </div>
      {needsExtra && (
        <Extra>
          {t('paywall_strings.account_admin')}: <a href={mailToAdmin}>{admin}</a>.
        </Extra>
      )}
    </FlexContainer>
  )
  const header = <TeamsHeader />
  return <MainLayout header={header} main={main} />
}

export const PaywallView = React.memo(Component_)

import { Suspense, useEffect } from 'react'
import { getSignOut } from '../common/src/actions/authAPI'
import { useDispatch } from 'react-redux'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { useExtendedHistory } from '../hooks/useExtendedHistory'
import { getRelativeURLKeepingQuerySearch } from '../helpers/routeHelper'
import { useTranslation } from 'react-i18next'
import { LazyIViewPlaceholder } from '../components/placeholder/LazyIViewPlaceholder'
import { IVIEW_PLACEHOLDER_TYPES } from '../components/placeholder/IViewPlaceholderTypes'
import { Loading } from '../components/basic/Loading'

export const InactivitySignedOut = () => {
  const dispatch = useDispatch()
  const { history } = useExtendedHistory()
  const { t } = useTranslation()
  useEffect(() => {
    dispatch(getSignOut())
  }, [dispatch])
  const onClick = () => {
    const url = getRelativeURLKeepingQuerySearch.homeRedirect()
    history.push(url)
  }
  const placeholder = {
    title: t('feature.placeholder.innactivity.title'),
    message: t('feature.placeholder.innactivity.subtitle'),
    type: IVIEW_PLACEHOLDER_TYPES.INACTIVITY,
  }
  return (
    <Suspense fallback={<Loading />}>
      <LazyIViewPlaceholder {...placeholder}>
        <PrimaryButton onClick={onClick}>{t('general.open')}</PrimaryButton>
      </LazyIViewPlaceholder>
    </Suspense>
  )
}

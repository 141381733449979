import { CreateItemModal } from './CreateItemModal'
import { SURFACE_TYPE } from '../CreationModalCommon'
import { NewItemModalContext } from '../../../contexts'
import { useMergeState } from '../../../common/src/hooks/enhancedHooks'

const DEFAULT_STATE = {
  isDisplayed: false,
  quadrant: 0,
  name: '',
  notes: '',
  isInbox: false,
  open: true,
  mode: '',
  completion: null,
  surface: SURFACE_TYPE.DIALOG,
  copiedFromID: null,
  showLinkedProjectSelector: false,
}

export const CreateItemModalSingleton = ({ children }) => {
  const [newItemModalState, setNewItemModalState] = useMergeState(DEFAULT_STATE)
  const newItemModalContextValue = { newItemModalState, setNewItemModalState }
  return (
    <NewItemModalContext.Provider value={newItemModalContextValue}>
      <CreateItemModal
        isBlocking={false}
        hidden={!newItemModalState.isDisplayed}
        onDismiss={() => setNewItemModalState({ isDisplayed: false })}
        isInbox={newItemModalState.isInbox}
        quadrant={newItemModalState.quadrant}
        mode={newItemModalState.mode}
        completion={newItemModalState.completion}
        surface={newItemModalState.surface}
        open={newItemModalState.open}
        dueDate={newItemModalState.dueDate}
        startDate={newItemModalState.startDate}
        allDay={newItemModalState.allDay}
        initialName={newItemModalState.name}
        notes={newItemModalState.notes}
        initialProject={newItemModalState.initialProject}
        initialOwnerEmail={newItemModalState.ownerEmail}
        displayProjectSelector={newItemModalState.displayProjectSelector}
        linkedItem={newItemModalState.linkedItem}
        copiedFromID={newItemModalState.copiedFromID}
        showLinkedProjectSelector={newItemModalState.showLinkedProjectSelector}
      />
      {children}
    </NewItemModalContext.Provider>
  )
}

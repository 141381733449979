import { useMemo, useRef } from 'react'
import { useTheme } from 'styled-components'
import _ from 'lodash'
import { ContextualMenu, ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu'
import { tagHelper } from '../../common/src/helpers'
import { TagContainer } from './TagContainer'
import { TagName } from './TagName'
import { useBoolean } from '../../common/src/hooks/useBoolean'
import { useTranslation } from 'react-i18next'

export const TagMenuKeys = {
  ADD_NEW: 'addNew',
  REMOVE: 'remove',
  RENAME_FOR_ALL: 'rename_for_all',
  FILTER_BY: 'filterBy',
}

export const Tag = ({
  tag,
  onClickMenuOption,
  readOnly = false,
  primaryColor,
  secondaryColor,
  isProject = false,
  className,
  ...rest
}) => {
  const theme = useTheme()
  const tagNameRef = useRef(null)
  const [isHiddenContextualMenu, { setTrue: hideContextMenu, setFalse: showContextMenu }] = useBoolean(true)
  const { t } = useTranslation()

  const name = tagHelper.getName(tag)
  const specialTags = isProject ? tagHelper.SPECIAL_PROJECTS_TAGS : tagHelper.SPECIAL_ITEMS_TAGS
  const isSpecial = _.some(specialTags, t => t === name)

  const menuItems = useMemo(() => {
    let items = [
      {
        key: TagMenuKeys.ADD_NEW,
        text: t('item.tag.add_new'),
        onClick: () => onClickMenuOption(TagMenuKeys.ADD_NEW, tag),
        isSpecial: true,
      },
      {
        key: TagMenuKeys.REMOVE,
        text: t('item.tag.remove'),
        onClick: () => onClickMenuOption(TagMenuKeys.REMOVE, tag),
        isSpecial: true,
      },
      {
        key: TagMenuKeys.RENAME_FOR_ALL,
        text: t('item.tag.rename_for_all'),
        onClick: () => onClickMenuOption(TagMenuKeys.RENAME_FOR_ALL, tag),
      },
      {
        key: 'divider_0',
        itemType: ContextualMenuItemType.Divider,
        isSpecial: true,
      },
      {
        key: TagMenuKeys.FILTER_BY,
        text: t('item.tag.filter_by'),
        onClick: () => onClickMenuOption(TagMenuKeys.FILTER_BY, tag),
        readOnly: true,
        isSpecial: true,
      },
    ]
    items = readOnly ? _.filter(items, item => item.readOnly) : items
    return isSpecial ? _.filter(items, item => item.isSpecial) : items
  }, [t, readOnly, isSpecial, onClickMenuOption, tag])

  return (
    <TagContainer
      primaryColor={primaryColor || theme.palette.themePrimary}
      secondaryColor={secondaryColor || theme.palette.white}
      onClick={showContextMenu}
      className={className}
      {...rest}
    >
      <TagName ref={tagNameRef}>{name}</TagName>
      <ContextualMenu
        items={menuItems}
        hidden={isHiddenContextualMenu}
        target={tagNameRef}
        onItemClick={hideContextMenu}
        onDismiss={hideContextMenu}
      />
    </TagContainer>
  )
}

import { useTranslation } from 'react-i18next'

export const UnreadMention = () => {
  const { t } = useTranslation()
  return (
    <div
      className="mr-1 inline-flex h-5 w-5 items-center justify-center rounded-full bg-pm-theme-primary"
      aria-label={t('alerts.unread_mention')}
    >
      <span className="text-l mt-[-2px] text-pm-white">@</span>
    </div>
  )
}

export const AssignedToYou = () => {
  const { t } = useTranslation()
  return (
    <div className="mr-1 inline-flex h-5 items-center justify-center rounded-full bg-pm-theme-primary px-1.5">
      <span className="mt-[-2px] text-xs text-pm-white">{t('alerts.assigned_to_you')}</span>
    </div>
  )
}

export const Invited = () => {
  const { t } = useTranslation()
  return (
    <div className="mr-1 inline-flex h-5 items-center justify-center rounded-full bg-pm-theme-primary px-1.5">
      <span className="mt-[-2px] text-xs text-pm-white">{t('alerts.invited')}</span>
    </div>
  )
}

export const Overdue = () => {
  const { t } = useTranslation()
  return (
    <div className="mr-1 inline-flex h-5 items-center justify-center rounded-full border border-solid border-pm-negative px-1.5">
      <span className="mt-[-2px] text-xs text-pm-black">{t('alerts.overdue')}</span>
    </div>
  )
}

export const Reminder = () => {
  const { t } = useTranslation()
  return (
    <div className="mr-1 inline-flex h-5 items-center justify-center rounded-full border border-solid border-orange-500 px-1.5">
      <span className="mt-[-2px] text-xs text-pm-black">{t('alerts.reminder')}</span>
    </div>
  )
}

import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useId } from 'react'
import { deleteFile, postFile } from '../common/src/actions/filesAPI'
import { fileHelper, itemHelper, stateHelper } from '../common/src/helpers'
import { safeNameFromFile } from '../common/src/helpers/fileHelper'
import { fileUploadSubject } from '../reactions'
import { useTranslation } from 'react-i18next'
import { getMe } from '../common/src/actions/usersAPI'
import { FluentToast } from '../components/toast/FluentToast'
import { Button, ToastTrigger, useToastController } from '@fluentui/react-components'
import { FolderOpen, Open } from '../components/BundledIcons'
import { timeout } from '../utils'
import { electronFileDownloadedSubject } from '../reactions'
import { SERVER_URLS } from '../common/src/constants.js'

const useFileResponsesDefaultToast = () => {
  const { dispatchToast, updateToast } = useToastController()
  const me = useSelector(stateHelper.getMe)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  return useCallback(
    ({ toastId, responses, textKey, fileSizes }) => {
      const successfulResponses = responses.filter(r => !r.error)
      const failedResponses = responses.filter(r => r.error)
      const totalFileSize = _.reduce(fileSizes, (sum, size) => sum + size, 0)
      const up = me.get('user_profile')

      const maxUploadSize = up.get('max_upload_size')
      const usedUploadSize = up.get('used_upload_size')
      const totalUploadSize = up.get('total_upload_size')

      const exceedsMaxUploadSize = _.some(fileSizes, size => size > maxUploadSize)
      const exceedsQuota = totalFileSize + usedUploadSize > totalUploadSize

      const someDidSuccess = successfulResponses.length > 0
      const someDidFail = failedResponses.length > 0
      let errorMessage = ''
      let unauthorized = false
      if (someDidFail) {
        const firstFailedResponse = _.first(failedResponses)
        const status = _.get(firstFailedResponse, 'payload.status')
        if (exceedsMaxUploadSize) {
          errorMessage = t('item.file.upload_failure_payload_to_large')
        } else if (exceedsQuota) {
          errorMessage = t('item.file.upload_failure_quota_exceeded')
        } else if (status === 413) {
          errorMessage = t('item.file.upload_failure_payload_to_large')
        } else if (status === 403) {
          unauthorized = true
          errorMessage = t('item.file.upload_failure_unauthorized')
        } else {
          errorMessage = t(`item.file.${textKey}_failure`, { count: failedResponses.length })
        }
      }

      const message = (
        <div className="flex flex-col gap-1">
          {someDidSuccess && (
            <p className="m-0">{t(`item.file.${textKey}_success`, { count: successfulResponses.length })}</p>
          )}
          {someDidFail && <p className="m-0">{errorMessage}</p>}
          {unauthorized && (
            <Button as="a" target="_blank" href={SERVER_URLS.STORAGE} appearance="primary">
              {t('item.file.upload_failure_unauthorized_button')}
            </Button>
          )}
        </div>
      )

      const options = {
        intent: someDidFail ? 'error' : 'success',
        timeout: (someDidFail ? 60 : 5) * 1000,
      }
      if (toastId) {
        updateToast({
          toastId,
          content: <FluentToast>{message}</FluentToast>,
          ...options,
        })
      } else {
        dispatchToast(<FluentToast>{message}</FluentToast>, options)
      }
      setTimeout(
        () => {
          dispatch(getMe()) // Update me so that used upload size is updated
        },
        _.random(200, 1000)
      )
    },
    [me, t, updateToast, dispatchToast, dispatch]
  )
}

export const useUploadFiles = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { dispatchToast } = useToastController()
  const showDefaultToast = useFileResponsesDefaultToast()
  const toastId = useId()

  return useCallback(
    async ({ item, files, overwritingItemURL = false, showToast = true }) => {
      const itemFiles = itemHelper.getFiles(item)
      const fileSizes = _.map(files, file => file.size)
      const filePromises = _.map(files, file => {
        const name = safeNameFromFile(file.name)
        const itemFile = itemFiles.find(itemFile => fileHelper.getFilename(itemFile) === name)
        return dispatch(postFile({ item, itemFile, file, overwritingItemURL }))
      })
      if (showToast) {
        dispatchToast(<FluentToast dismissable={false}>{t('item.file.uploading_files')}</FluentToast>, {
          timeout: -1,
          toastId,
        })
      }
      const responses = await Promise.all(filePromises)
      fileUploadSubject.next(true)
      if (showToast) {
        showDefaultToast({
          toastId,
          responses,
          textKey: 'upload',
          fileSizes,
        })
      }
      return responses
    },
    [dispatch, dispatchToast, showDefaultToast, t, toastId]
  )
}

export const useDeleteFiles = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { dispatchToast } = useToastController()
  const showDefaultToast = useFileResponsesDefaultToast()
  const toastId = useId()

  return useCallback(
    async ({ itemFiles, showToast = true }) => {
      const filePromises = _.map(itemFiles, file => dispatch(deleteFile(file)))
      if (showToast) {
        dispatchToast(<FluentToast dismissable={false}>{t('item.file.deleting_files')}</FluentToast>, {
          timeout: -1,
          toastId,
        })
      }
      const responses = await Promise.all(filePromises)
      if (showToast) {
        showDefaultToast({
          toastId,
          responses,
          textKey: 'delete',
        })
      }
      return responses
    },
    [dispatch, dispatchToast, showDefaultToast, t, toastId]
  )
}

export const useDownloadFiles = () => {
  const { t } = useTranslation()
  const { dispatchToast, updateToast } = useToastController()
  const downloadToastId = useId()
  const setupElectronListener = useCallback(
    async files => {
      await new Promise(resolve => {
        const subscription = electronFileDownloadedSubject.subscribe(file => {
          if (files.some(f => file.filename === f.filename)) {
            subscription.unsubscribe()
            resolve()
          }
        })
      })
      const onShow = () => {
        window.electronAPI?.showItemInFolder?.(files[0].filename)
      }
      const onOpen = () => {
        window.electronAPI?.openFile?.(files[0].filename)
      }
      const openClassName = files.length > 1 ? '!hidden' : ''
      const footer = (
        <>
          <ToastTrigger>
            <Button appearance="transparent" onClick={onShow} icon={<FolderOpen />}>
              {t('general.show')}
            </Button>
          </ToastTrigger>
          <ToastTrigger>
            <Button appearance="transparent" onClick={onOpen} icon={<Open />} className={openClassName}>
              {t('general.open')}
            </Button>
          </ToastTrigger>
        </>
      )
      updateToast({
        content: <FluentToast footer={footer}>{t(`item.file.download_success`)}</FluentToast>,
        toastId: downloadToastId,
        timeout: -1,
        intent: 'success',
      })
    },
    [downloadToastId, t, updateToast]
  )

  const commonDownload = useCallback(
    async files => {
      const useElectronHandling = window.electronAPI?.registerOnFileDownloaded
      dispatchToast(<FluentToast dismissable={false}>{t('item.file.download_started')}</FluentToast>, {
        toastId: downloadToastId,
        intent: 'info',
        timeout: useElectronHandling ? 10000 : 3000,
        pauseOnWindowBlur: false,
      })

      if (useElectronHandling) {
        setupElectronListener(files)
        files.forEach(f => window.electronAPI.triggerDownload(f))
        return
      }

      for (const file of files) {
        const { url } = file
        const urlObj = new URL(url)
        if (urlObj.hostname === 'files.appfluence.com') {
          urlObj.searchParams.set('attachment', '1')
          const iframe = document.createElement('iframe')
          iframe.src = urlObj.toString()
          iframe.style.display = 'none'
          document.body.appendChild(iframe)
          setTimeout(() => {
            iframe.remove()
          }, 5000)
        } else {
          window.open(urlObj, '_blank')
          setTimeout(window.focus, 0)
        }
        await timeout(700)
      }
    },
    [setupElectronListener, dispatchToast, downloadToastId, t]
  )
  const downloadFiles = useCallback(
    files =>
      commonDownload(
        files.map(f => ({
          url: f.get('url'),
          filename: f.get('filename'),
        }))
      ),
    [commonDownload]
  )
  return { downloadFiles, commonDownload }
}

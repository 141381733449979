import { SERVER_URLS } from '../common/src/constants'
import { useIsBusinessUser } from '../common/src/hooks/usersHooks'

export const withBusiness = ChildComponent => props => {
  const isBusinessUser = useIsBusinessUser()
  if (isBusinessUser) {
    return <ChildComponent {...props} />
  }
  const url = new URL(SERVER_URLS.BUSINESS_REQUIRED)
  url.searchParams.append('paywall_next', window.location.href)
  return <iframe title="business required" className="h-full w-full" src={url}></iframe>
}

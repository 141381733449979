import { memo, useMemo } from 'react'
import _ from 'lodash'
import { Shimmer, ShimmerElementType } from '@fluentui/react/lib/Shimmer'
import { cn } from '@appfluence/classnames'

const squareShimmerElements = [
  {
    type: ShimmerElementType.line,
    width: 40,
    height: 40,
  },
]
const Square = ({ shimmerColors }) => (
  <Shimmer className="mr-3 p-2" shimmerColors={shimmerColors} shimmerElements={squareShimmerElements} />
)

const lineShimmerElements = [
  {
    type: ShimmerElementType.line,
    height: 12,
  },
]

const Line = ({ shimmerColors, width }) => (
  <Shimmer className="flex-1" shimmerColors={shimmerColors} shimmerElements={lineShimmerElements} width={width} />
)

export const GhostProjectTemplateCell = memo(({ shimmerColors, className, ...rest }) => {
  const width = useMemo(() => _.random(25, 90) + '%', [])
  return (
    <div {...rest} className={cn('h-[46px]', className)}>
      <div className="flex items-center p-2">
        <Square shimmerColors={shimmerColors} />
        <Line shimmerColors={shimmerColors} width={width} />
      </div>
    </div>
  )
})

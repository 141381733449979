export interface Notification {
  title: string
  body: string
  icon: string
}

export interface StoreProduct {
  productIdentifier: string
  localizedDescription: string
  localizedTitle: string
  contentVersion: string
  contentLengths: number[]
  price: number
  formattedPrice: string
  currencyCode: string
  discounts: any[]
  subscriptionGroupIdentifier: string
  subscriptionPeriod: {
    numberOfUnits: number
    unit: string
  }
  isDownloadable: boolean
  downloadContentVersion: string
  downloadContentLengths: number[]
}

interface PlatformInfo {
  standalone: boolean
  version: string
  platform: string
}

export enum AppEventType {
  SignIn = 'SignIn',
  SignOut = 'SignOut',
}

interface File {
  fileName: string
  filename: string
  path: string
  fileSize: number
  mimeType: string
  url: string
}

export const ProductIds = {
  YearlySubscriptionTeam: 'appfluence.iphone.prioritymatrixcloudsync.team.yearly3',
  MonthlySubscriptionTeam: 'appfluence.iphone.prioritymatrixcloudsync.team.monthly3',
  YearlySubscriptionBusiness: 'appfluence.iphone.prioritymatrixcloudsync.business.yearly3',
  MonthlySubscriptionBusiness: 'appfluence.iphone.prioritymatrixcloudsync.business.monthly3',
}

export const AllProducts = Object.values(ProductIds)

declare global {
  interface Window {
    electronAPI?: {
      registerDeepLinkHandler: (handler: (value: string) => void) => Promise<void>
      registerOnFileDownloaded: (handler: (value: File) => void) => void
      getProducts: (products: string[]) => Promise<StoreProduct[]>
      purchaseProduct: (productId: string) => Promise<void>
      notifyEvent?: (event: AppEventType, data: any) => void
      getPlatformInfo?: () => Promise<PlatformInfo>
      showItemInFolder?: (filename: string) => void
      openFile?: (filename: string) => void
      getAppleMailContent?: (link: string) => Promise<string>
      triggerDownload?: (file: Pick<File, 'url' | 'filename'>) => void
    }
  }
}

export const isElectronApp = () => !!window.electronAPI

import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { setDefaultFilterItems } from '../../common/src/actions/filtersActions'
import { useTranslation } from 'react-i18next'
import { isMobile as isMobilePlatform } from '../../common/src/helpers/platformHelper'
import { cn } from '@/modules/classnames'

export const QuadrantPlaceholder = memo(({ count, isFiltering = false, readOnly = false }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isMobile = isMobilePlatform()
  const isFilterPlaceholder = count === 0 && isFiltering

  const getTextPlaceholder = (isFilterPlaceholder, isMobile) => {
    if (isMobile) {
      return isFilterPlaceholder ? t('quadrant.filter_placeholder_mobile') : t('quadrant.add_item_placeholder_mobile')
    } else {
      return isFilterPlaceholder ? t('quadrant.filter_placeholder') : t('quadrant.add_item_placeholder')
    }
  }

  const text = getTextPlaceholder(isFilterPlaceholder, isMobile)

  const showPlaceholder = isFilterPlaceholder || !readOnly

  const onClickFilterPlaceholder = event => {
    dispatch(setDefaultFilterItems())

    event.preventDefault()
    event.stopPropagation()
  }

  const onClick = isFilterPlaceholder ? onClickFilterPlaceholder : null
  if (!showPlaceholder) {
    return null
  }
  const Component = onClick ? 'button' : 'div'
  return (
    <Component
      className={cn(
        'absolute left-1/2 top-1/2 box-border w-full -translate-x-1/2 -translate-y-1/2 text-balance px-2 text-center text-pm-neutral-secondary',
        'z-10 border-none bg-transparent'
      )}
      onClick={onClick}
    >
      {text}
    </Component>
  )
})

import React, { memo, useCallback, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  FullScreenModalContainer,
  FullScreenModalSection,
  FullScreenModalSectionBody,
  FullScreenModalSectionHeader,
} from '../modal/FullScreenModalContainer'
import { Spinner } from '../../components/basic/Loading'
import { useCreateCustomProjectTemplateFromProject } from '@/queries/projectTemplates'
import { getMatchParameterInteger } from '../../common/src/utils'
import { getProjectIfNotExists } from '../../common/src/actions/projectsAPI'
import { projectHelper, stateHelper } from '../../common/src/helpers'
import { useFetch } from '../../common/src/hooks/networkingHooks'
import { useTranslation } from 'react-i18next'
import { FontIcon, PrimaryButton, Text, TextField, Toggle } from '@fluentui/react'
import { useToastController } from '@fluentui/react-components'
import { FluentToast } from '@/components/toast/FluentToast'

const displayName = 'NewCustomProjectTemplateModal'

const identifiers = {
  nameTextField: `${displayName}-nameTextField`,
  notesTextField: `${displayName}-notesTextField`,
}

const ACTION_TYPE = {
  INIT: 'INIT',
  SET_NAME: 'SET_NAME',
  SET_NOTES: 'SET_NOTES',
  SET_RESET_ITEMS: 'SET_RESET_ITEMS',
  SET_SKIP_COMPLETED: 'SET_SKIP_COMPLETED',
  SET_INCLUDE_MEMBERS: 'SET_INCLUDE_MEMBERS',
}

const initialState = {
  name: '',
  notes: '',
  resetItems: false,
  skipCompleted: false,
  includeMembers: true,
  isValid: false,
}

export const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.INIT:
      {
        const { name, notes } = payload
        if (!_.isEmpty(name) && _.isEmpty(state.name)) {
          state.name = name
        }
        if (!_.isEmpty(notes) && _.isEmpty(state.notes)) {
          state.notes = notes
        }
        state.isValid = !_.isEmpty(state.name)
      }
      break
    case ACTION_TYPE.SET_NAME:
      {
        const name = action.payload
        state.name = name
        state.isValid = !_.isEmpty(name)
      }
      break
    case ACTION_TYPE.SET_NOTES:
      state.notes = action.payload
      break
    case ACTION_TYPE.SET_RESET_ITEMS:
      state.resetItems = action.payload
      break
    case ACTION_TYPE.SET_SKIP_COMPLETED:
      state.skipCompleted = action.payload
      break
    case ACTION_TYPE.SET_INCLUDE_MEMBERS:
      state.includeMembers = action.payload
      break
    default:
      break
  }
  return { ...state }
}

export const NewCustomProjectTemplateModal = memo(({ history, location, match, ...rest }) => {
  const dispatch = useDispatch()
  const [state, localDispatch] = useReducer(reducer, { ...initialState })
  const { name, notes, resetItems, skipCompleted, includeMembers, isValid } = state
  const { t } = useTranslation()
  const { dispatchToast } = useToastController()

  const projectID = getMatchParameterInteger(match, 'pid')
  const project = useSelector(state => stateHelper.getProject(state, projectID))
  const pName = projectHelper.getName(project)
  const pNotes = projectHelper.getNotes(project)

  const fetchProject = useCallback(() => {
    if (!projectID) {
      return Promise.reject()
    }
    return dispatch(getProjectIfNotExists(projectID, true))
  }, [dispatch, projectID])

  const { loading: fetching } = useFetch(fetchProject)

  const { mutate: createTemplate, isPending, isError } = useCreateCustomProjectTemplateFromProject()

  useEffect(() => {
    if (pName || pNotes) {
      localDispatch({ type: ACTION_TYPE.INIT, payload: { name: pName, notes: pNotes } })
    }
  }, [pName, pNotes])

  const onChangeName = useCallback((ev, value) => {
    localDispatch({ type: ACTION_TYPE.SET_NAME, payload: value })
  }, [])

  const onChangeNotes = useCallback((ev, value) => {
    localDispatch({ type: ACTION_TYPE.SET_NOTES, payload: value })
  }, [])

  const onChangeResetItems = useCallback((ev, checked) => {
    localDispatch({ type: ACTION_TYPE.SET_RESET_ITEMS, payload: checked })
  }, [])

  const onChangeSkipCompleted = useCallback((ev, checked) => {
    localDispatch({ type: ACTION_TYPE.SET_SKIP_COMPLETED, payload: !checked })
  }, [])

  const onChangeIncludeMembers = useCallback((ev, checked) => {
    localDispatch({ type: ACTION_TYPE.SET_INCLUDE_MEMBERS, payload: checked })
  }, [])

  const create = useCallback(async () => {
    createTemplate(
      {
        projectId: projectID,
        name,
        notes,
        reset_items: resetItems,
        skip_completed: skipCompleted,
        include_members: includeMembers,
      },
      {
        onSuccess: data => {
          dispatchToast(<FluentToast>{t('project_template.created_successfully')}</FluentToast>, {
            intent: 'success',
            timeout: 5000,
          })
          history.goBack()
        },
      }
    )
  }, [createTemplate, projectID, name, notes, resetItems, skipCompleted, includeMembers, dispatchToast, t, history])

  return (
    <FullScreenModalContainer bodyClassName={`!max-w-md ${fetching ? 'justify-center' : ''}`} {...rest}>
      {fetching && <Spinner size="large" />}
      {!fetching && (
        <FullScreenModalSection>
          <FullScreenModalSectionHeader>
            <div>{t('project_template.add_template_details')}</div>
          </FullScreenModalSectionHeader>
          <FullScreenModalSectionBody>
            <TextField
              id={identifiers.nameTextField}
              label={t('project_template.name_title')}
              value={name}
              placeholder={t('project_template.name_placeholder')}
              onChange={onChangeName}
              disabled={isPending}
              className="mt-3"
            />
            <TextField
              id={identifiers.notesTextField}
              multiline
              resizable={false}
              label={t('project_template.notes_title')}
              value={notes}
              placeholder={t('project_template.notes_placeholder')}
              onChange={onChangeNotes}
              disabled={isPending}
              className="mt-3"
            />
            <Toggle
              label={t('project_template.clear_items_dates')}
              checked={resetItems}
              onChange={onChangeResetItems}
              className="mt-3"
            />
            <Toggle
              label={t('project_template.mark_items_undone')}
              checked={!skipCompleted}
              onChange={onChangeSkipCompleted}
              className="mt-3"
            />
            <Toggle
              label={t('project_template.include_members')}
              checked={includeMembers}
              onChange={onChangeIncludeMembers}
              className="mt-3"
            />
            <div className="mt-5 flex items-center gap-2">
              <FontIcon iconName="Info" />
              <Text>{t('project_template.access_explanation')}</Text>
            </div>
            <div className="mt-5 h-8">
              {isPending ? (
                <Spinner size="medium" />
              ) : (
                <PrimaryButton onClick={create} className="w-full" disabled={!isValid}>
                  {t('project_template.create_template')}
                </PrimaryButton>
              )}
            </div>
            {isError && <div className="mt-2 text-pm-negative">{t('project_template.created_unsuccessfully')}</div>}
          </FullScreenModalSectionBody>
        </FullScreenModalSection>
      )}
    </FullScreenModalContainer>
  )
})

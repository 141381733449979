import { useState, useCallback } from 'react'

export function useBoolean(initialState) {
  const [value, setValue] = useState(initialState)
  const setTrue = useCallback(() => {
    setValue(true)
  }, [])
  const setFalse = useCallback(() => {
    setValue(false)
  }, [])
  const toggle = useCallback(() => setValue(v => !v), [])
  return [value, { setTrue, setFalse, toggle, setValue }]
}
